import React, { useEffect, useState } from "react";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import { Grid, Paper, Typography, useForkRef } from "@mui/material";
import { useFormik } from "formik";
import FormInput from "../../forms/FormInput";
import { LoadingButton } from "@mui/lab";
import { get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import FormSelect from "../../forms/FormSelect";
import CustomTable from "../../components/Tables/CustomTable";
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";
import { createUserTableKeys } from "../../data/TableData/createUserData";
import { toast } from "react-toastify";

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);

  const getUsers = async () => {
    try {
      const { data } = await get(URLS.employee.listUsers);
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleCreateAndUpdate = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
      };

      if (dataToEdit) {
        const { data } = await put(
          URLS.auth.updateSuper + "/" + dataToEdit?._id,
          payload
        );
      } else {
        const { data } = await post(URLS.auth.create, payload);
      }

      handleModalClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setDataToEdit(null);
    formik.resetForm();
    getUsers();
  };

  const handleEditUser = (users) => {
    setDataToEdit(users);
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      userName: dataToEdit?.userName || "",
      email: dataToEdit?.email || "",
      contactNumber: dataToEdit?.contactNumber || "",
      role: "super",
      secretCode: dataToEdit?.secretCode || "",
      password: dataToEdit?.password || "",
    },
    onSubmit: handleCreateAndUpdate,
    enableReinitialize: true,
  });

  return (
    <>
      <PageHeader
        collection="Create User"
        onAddClick={openDataAddOrUpdateModal}
        title="Create User"
        modelName="user"
        // onFieldAdd={handleOnFieldAdd}
        module="User"
        showAddField={false}
      />
      <CustomTable
        actions={["edit", "delete"]}
        bodyDataModal="create user"
        bodyData={data}
        tableKeys={createUserTableKeys}
        // onDeleteClick={handleDelete}
        onEditClick={handleEditUser}
        isDataReady={isReady}
        module="Create User"
      />

      <FormDialog
        // readOnly={readOnly}
        open={open}
        adding={loading}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update User" : "Add User"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              label="User Name"
              formik={formik}
              name="userName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              label="Contact Number"
              formik={formik}
              name="contactNumber"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="email"
              type="email"
              formik={formik}
              label="Email"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              required={dataToEdit ? false : true}
              label="Password"
              formik={formik}
              name="password"
              type="password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              label="Secret Code"
              formik={formik}
              name="secretCode"
              type="password"
            />
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
};

export default CreateUser;
