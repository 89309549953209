export const createUserTableKeys = [
  {
    name: "Name",
    key: "userName",
  },
  {
    name: "Contact Number",
    key: "contactNumber",
  },
  {
    name: "Email",
    key: "email",
  },
];
