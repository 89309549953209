import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function FormDialog({
  formTitle = "Add Item",
  readOnly,
  children,
  formik,
  open = false,
  onClose = () => {},
  submitButtonTitle = "Add Item",
  styles = {},
  adding = false,
  maxWidth = 650,
}) {
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: maxWidth,
          ...styles,
        },
        component: "form",
        onSubmit: formik.handleSubmit,
      }}
    >
      <DialogTitle>{formTitle}</DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          padding: "10px",
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={readOnly}
          size="small"
          loading={adding}
          variant="contained"
          type="submit"
        >
          {submitButtonTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
