import React, { useContext, useEffect, useState } from "react";
import NewStock from "../components/Inventory/NewStock";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import UsedStock from "../components/Inventory/UsedStock";
import FaultyStock from "../components/Inventory/FaultyStock";
import SavedStock from "../components/Inventory/SavedStock";
import BundledStock from "../components/Inventory/BundledStock";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

// services
import URLS from "../services/urlConstants";
import { get } from "../services/apiMethods";
import { downloadExcel } from "../utils/utilities";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../context/WarehouseContext";
import ShippedStock from "../components/Inventory/ShippedStock";
import { PuffLoader } from "react-spinners";

export default function Inventory() {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [newStock, setNewStock] = useState([]);
  const [usedStock, setUsedStock] = useState([]);
  const [faultyStock, setFaultyStock] = useState([]);
  const [savedStock, setSavedStock] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [subFamily, setSubFamily] = useState([]);
  const [shippedStock, setShippedStock] = useState([]);
  const [bundleTypes, setBundleTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadingTotalStock, setDownloadingTotalStock] = useState(false);

  const getAllBundleTypes = async () => {
    try {
      const { data } = await get(URLS.bundleType.list);
      setBundleTypes(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBundleTypes();
  }, []);

  const getSubFamily = async () => {
    try {
      const { data } = await get(URLS.subFamily.list);
      setSubFamily(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const [itemData, faultyData, savedData, bundleData, shippedData] =
        await Promise.all([
          get(URLS.items.getItemViewReportsNewAndUsedFaulty, {
            params: {
              search: { inStock: true, inBundle: false },
              warehouses: selectedWarehouse,
              ownerCodes: selectedOwnerCode,
              onwerGroups: selectedOwnerGroup,
            },
          }),
          get(URLS.items.getItemViewReportsFaulty, {
            params: {
              search: { inStock: false, inBundle: false, isSaved: false },
              warehouses: selectedWarehouse,
              ownerCodes: selectedOwnerCode,
              onwerGroups: selectedOwnerGroup,
            },
          }),
          get(URLS.items.listSavedStock, {
            params: {
              warehouses: selectedWarehouse,
              ownerCodes: selectedOwnerCode,
              onwerGroups: selectedOwnerGroup,
            },
          }),
          get(URLS.items.getBundledStock, {
            params: {
              warehouses: selectedWarehouse,
              ownerCodes: selectedOwnerCode,
              onwerGroups: selectedOwnerGroup,
            },
          }),

          get(URLS.items.getShippedItems, {
            params: {
              warehouses: selectedWarehouse,
              ownerCodes: selectedOwnerCode,
              onwerGroups: selectedOwnerGroup,
            },
          }),
        ]);

      setNewStock(
        itemData.data.result.find((d) => d.itemCategory === "new")?.data || []
      );
      setUsedStock(
        itemData.data.result.find((d) => d.itemCategory === "used")?.data || []
      );
      setFaultyStock(
        faultyData.data.result.find((d) => d.itemCategory === "faulty")?.data ||
          []
      );
      setSavedStock(savedData.data.result);
      setBundles(bundleData.data.result);
      setShippedStock(shippedData.data.result);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, [selectedWarehouse, selectedOwnerCode, selectedOwnerGroup]);

  useEffect(() => {
    getSubFamily();
  }, [selectedWarehouse, selectedOwnerCode]);

  const downloadAllItems = async () => {
    try {
      setDownloadingTotalStock(true);
      const { data } = await get(URLS.items.downloadTotalStock, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Total_Inventory");
      setDownloadingTotalStock(false);
    } catch (error) {
      setDownloadingTotalStock(false);
      console.log(error);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Reports" />
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "25%",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid lightgray",
              borderRadius: "5px",
              margin: "20px 0",
              padding: "5px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Total Stock</Typography>

            <IconButton size="small" onClick={downloadAllItems}>
              {downloadingTotalStock ? (
                <CircularProgress size={20} />
              ) : (
                <DownloadRoundedIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
          <Grid sx={{ zoom: 0.9 }} spacing={2} container>
            <Grid item xs={12} sm={12}>
              <NewStock data={newStock} warehouseId={selectedWarehouse} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UsedStock data={usedStock} warehouseId={selectedWarehouse} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FaultyStock data={faultyStock} warehouseId={selectedWarehouse} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SavedStock data={savedStock} warehouseId={selectedWarehouse} />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BundledStock data={bundles} warehouseId={selectedWarehouse} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ShippedStock
                data={shippedStock}
                warehouseId={selectedWarehouse}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
