import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const client = Axios.create({
  baseURL: API_PREFIX,
});

const setAuthToken = (config) => {
  const access_token = window.localStorage.getItem("access_token");
  if (access_token) {
    config.headers["x-auth-token"] = `bearer ${access_token}`;
  }
  return config;
};

// Add request interceptor to include Authorization token
client.interceptors.request.use(setAuthToken, (error) => Promise.reject(error));

// Add response interceptor for handling global responses
client.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    if (data.message && ["post", "put", "delete"].includes(config.method)) {
      toast.success(data.message.split("_").join(" "));
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      // Handle unauthorized error by clearing tokens and redirecting to login
      window.localStorage.removeItem("access_token");
      window.localStorage.removeItem("current_inventory_user");
      window.location.href = "/";
    }

    if (response && response.data && response.data.message) {
      toast.error(response.data.message);
    } else if (response.status === 500) {
      toast.error("INTERNAL_SERVER_ERROR");
    } else {
      toast.error("NETWORK ERROR");
    }
    return Promise.reject(error);
  }
);

// Utility Function for API Requests
function createRequestManager() {
  let activeRequest = null; // Tracks the current active request
  let activeRequestId = 0; // Tracks the unique ID for requests

  return async function requestHandler(
    url,
    options = {},
    onResponse = () => {},
    onError = () => {}
  ) {
    activeRequestId++;
    const requestId = activeRequestId; // Assign unique ID to the current request

    // Cancel any ongoing request
    if (activeRequest?.cancel) {
      activeRequest.cancel("Request canceled due to new request.");
    }

    // Create a new CancelToken
    activeRequest = Axios.CancelToken.source();

    try {
      // Attach the CancelToken to Axios options
      const response = await client({
        url,
        ...options,
        cancelToken: activeRequest.token,
      });

      // Process response only if it matches the latest request ID
      if (requestId === activeRequestId) {
        onResponse(response.data); // Execute the callback for handling response
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        onError(error); // Execute error callback
        console.error("Request failed", error);
      }
    }
  };
}

// Export the request manager
export const requestManager = createRequestManager();

// Export the Axios client
export default client;
