import React, { useContext, useEffect, useState, useRef } from "react";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import URLS from "../../services/urlConstants";
import WarehouseContext from "../../context/WarehouseContext";
import { del, get, post, put } from "../../services/apiMethods";
import PageHeader from "../../components/PageHeader";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormInput from "../../forms/FormInput";
import FormDialog from "../../forms/FormDialog";
import FormSelect from "../../forms/FormSelect";
import CustomTable from "../../components/Tables/CustomTable";
import { Close } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import logo from "../../assets/images/companylogo.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import FormDatePicker from "../../forms/FormDatePicker";
import CustomSearch from "../../components/Tables/CustomSearch";
import { getDateRange, hasDuplicateStrings } from "../../utils/utilities";
import dayjs from "dayjs";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import { reverseDcTableKeys } from "../../data/reverseDc";
import { Add } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const SectionBox = styled(Box)({
  "@media print": {
    margin: "0px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    pageBreakInside: "avoid",
    breakInside: "avoid",
    zoom: 0.85,
  },
});

const SubBox = styled(Box)({
  margin: "15px",
  border: "2px solid black",
  boxSizing: "border-box",
});

const SectionTitle = styled(Typography)({
  fontWeight: "bold",
  borderBottom: "2px solid #000",
  // borderRight: "2px solid #000",
  padding: "8px",
});

const TableCellStyled = styled(TableCell)({
  fontWeight: "bold",
  border: "2px solid black",
});

const StyledTableCell = styled(TableCell)(() => ({
  // maxWidth: "180px",
  // width: "100%",
}));
const TotalRow = styled(TableRow)({
  textAlign: "right",
  fontWeight: "bold",
  border: "2px solid black",
});

const SignatureBox = styled(Box)({
  paddingBottom: "40px",
});

const CUSTOM_WIDTH = 1200;

export default function ReverseDc() {
  const {
    allWarehouses,
    selectedOwnerCode,
    selectedWarehouse,
    selectedOwnerGroup,
  } = useContext(WarehouseContext);
  const componentRef = useRef();
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [ownerGroups, setOwnerGroups] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [openViewModal, setViewOpenModal] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [search, setSearch] = useState("");
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const openModal = () => setOpen(true);

  // directories

  const [directories, setDirectories] = useState([]);
  const [pinCodes, setPinCodes] = useState([]);
  const [names, setNames] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [contactNumbers, setContactNumbers] = useState([]);
  const [itemDescription, setItemDescription] = useState([]);

  const getItemDescription = async () => {
    try {
      const { data } = await get(URLS.itemDescription.list, {
        params: { warehouse: formik.values.warehouse },
      });
      setItemDescription(
        data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setWarehouses(
      allWarehouses?.map((s) => ({
        ...s,
        label: s.code,
        value: s._id,
      })) || []
    );
    if (allWarehouses?.length === 1) {
      formik.setFieldValue("warehouse", allWarehouses[0]?._id);
    }
  }, [allWarehouses]);

  const getOwnerGroup = async () => {
    try {
      let filter = {};
      if (formik.values.warehouse) {
        filter = { warehouse: formik.values.warehouse };
      }
      const { data } = await get(URLS.ownerGroup.list, {
        params: filter,
      });
      let newGroups = data.result.map((r) => ({
        ...r,
        label: r.name,
        value: r._id,
      }));
      setOwnerGroups(newGroups);
      formik.setFieldValue("ownerGroup", "");
      if (newGroups.length === 1) {
        formik.setFieldValue("ownerGroup", newGroups[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      let filter = {};
      if (formik.values.ownerGroup) {
        filter = { ownerGroup: formik.values.ownerGroup };
      }
      const { data } = await get(URLS.ownerCode.list, {
        params: filter,
      });
      let newCodes = data.result.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      }));
      setOwnerCodes(newCodes);
      formik.setFieldValue("ownerCode", "");

      if (newCodes?.length === 1) {
        formik.setFieldValue("ownerCode", newCodes[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataToEdit(null);
    formik.resetForm();
  };

  const handleCloseViewModal = () => {
    setViewOpenModal(false);
  };

  const handleCreateAndUpdate = async (values) => {
    if (
      !values.name ||
      !values.address ||
      !values.contactNumber ||
      !values.pinCode
    )
      return toast.error("Please fill all fields!");
    setLoading(true);

    try {
      const transformedItemDetails = [];

      for (let item of values.itemDetails) {
        transformedItemDetails.push({
          description: item.description,
          currentItemCategory: item.currentItemCategory,
          quantity: item.quantity,
          unitRate: item.unitRate,
          amount: item.amount,
          hsn: item.hsn,
          uom: "",
        });
      }

      const payload = {
        itemDetails: transformedItemDetails,
        shipTo: values.warehouse,
        dispatchFrom: {
          name: values.name,
          address: values.address,
          contactNumber: values.contactNumber,
          pinCode: values.pinCode,
        },
        msns: values.msns?.toUpperCase()?.split(","),
      };

      if (hasDuplicateStrings(payload.msns)) {
        toast.error("Duplicate MSNs found!");
        return setLoading(false);
      }

      let totalQuantity = transformedItemDetails?.reduce(
        (t, c) => t + Number(c.quantity),
        0
      );

      if (totalQuantity !== payload.msns?.length) {
        toast.error("Total quantity is not matching total msns!");
        return setLoading(false);
      }
      const { data } = await post(URLS.reverseDc.create, payload);
      getData();

      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      warehouse: "",
      ownerGroup: "",
      ownerCode: "",
      name: "",
      address: "",
      pinCode: "",
      contactNumber: "",
      msns: "",
      itemDetails: [
        {
          id: 1,
          description: "",
          currentItemCategory: "",
          hsn: "",
          uom: "",
          quantity: 1,
          unitRate: 0,
          amount: 0,
        },
      ],
    },
    onSubmit: handleCreateAndUpdate,
    enableReinitialize: true,
  });

  const getDirectories = async () => {
    try {
      const { data } = await get(URLS.directory.list, {
        params: {
          ownerCode: formik.values.ownerCode,
        },
      });
      let dirs = data.result?.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      }));
      setDirectories(dirs);
      setPinCodes(dirs.flatMap((d) => d?.addresses.map((add) => add.pinCode)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formik.setFieldValue("name", "");
    setNames(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.pinCode === formik.values.pinCode)
          ?.map((add) => add.name)
      )
    );
  }, [formik.values.pinCode, directories]);

  useEffect(() => {
    formik.setFieldValue("address", "");
    setAddresses(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.name === formik.values.name)
          ?.map((add) => add.address)
      )
    );
  }, [formik.values.name, directories]);

  useEffect(() => {
    formik.setFieldValue("contactNumber", "");

    setContactNumbers(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.address === formik.values.address)
          ?.map((add) => add.contactNumber)
      )
    );
  }, [formik.values.address, directories]);

  useEffect(() => {
    if (formik.values.ownerCode) {
      getDirectories();
    } else {
      setDirectories([]);
      setPinCodes([]);
    }
  }, [formik.values.ownerCode]);

  React.useEffect(() => {
    if (formik.values.ownerGroup) {
      getOwnerCodes();
    } else {
      formik.setFieldValue("ownerCode", "");
      setOwnerCodes([]);
    }
  }, [formik.values.ownerGroup]);

  useEffect(() => {
    if (formik.values.ownerCode) {
      getDirectories();
    } else {
      setDirectories([]);
      setPinCodes([]);
    }
  }, [formik.values.ownerCode]);

  React.useEffect(() => {
    if (formik.values.warehouse) {
      getOwnerGroup();
    } else {
      formik.setFieldValue("ownerGroup", "");
      setOwnerGroups([]);
    }
  }, [formik.values.warehouse]);

  useEffect(() => {
    if (formik.values.warehouse) {
      getItemDescription();
    }
  }, [formik.values.warehouse]);

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.reverseDc.delete + "/" + id);
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowData = (data) => {
    setDataToEdit(data);
    setViewOpenModal(true);
  };

  const handleCustomInputChange = (event, index) => {
    const { name, value } = event.target;
    let itemDetails = formik.values.itemDetails[index];

    if (name === "msns") {
      return formik.setFieldValue("msns", value);
    }

    if (name === "currentItemCategory" && itemDetails?.description) {
      if (
        formik.values.itemDetails?.find(
          (d) =>
            d.description === itemDetails.description &&
            d.currentItemCategory === value
        )
      ) {
        return toast.error(
          "Duplicate item description found for the same item category!"
        );
      }
    }

    if (name === "description" && itemDetails.currentItemCategory) {
      if (
        formik.values.itemDetails?.find(
          (d) =>
            d.currentItemCategory === itemDetails.currentItemCategory &&
            d.description === value
        )
      ) {
        return toast.error(
          "Duplicate item category found for the same item description!"
        );
      }
    }
    if (name === "description") {
      let updatedDescription = itemDescription.find(
        (d) => d.description === value
      );
      let item = {
        ...itemDetails,
        description: updatedDescription?.description,
        hsn: updatedDescription?.hsn,
        unitRate:
          itemDetails?.currentItemCategory === "new"
            ? updatedDescription?.priceNew
            : updatedDescription?.priceUsed,
      };

      item.amount = item.quantity * item.unitRate;

      return formik.setFieldValue(
        "itemDetails",
        formik.values.itemDetails.map((b, i) => (i === index ? item : b))
      );
    }

    if (name === "quantity") {
      let item = { ...itemDetails };
      item.amount = value * item.unitRate;
      item.quantity = value;
      return formik.setFieldValue(
        "itemDetails",
        formik.values.itemDetails.map((b, i) => (i === index ? item : b))
      );
    }

    if (name === "unitRate") {
      let item = { ...itemDetails };
      item.amount = value * item.quantity;
      item.unitRate = value;
      return formik.setFieldValue(
        "itemDetails",
        formik.values.itemDetails.map((b, i) => (i === index ? item : b))
      );
    }
    formik.setFieldValue(
      "itemDetails",
      formik.values.itemDetails.map((b, i) =>
        i === index ? { ...b, [event.target.name]: event.target.value } : b
      )
    );
  };

  const removeData = (index) => {
    let initialData = [...formik.values.itemDetails];
    initialData.splice(index, 1);
    formik.setFieldValue("itemDetails", initialData);
  };

  const handleData = () => {
    let initialeData = [...formik.values.itemDetails];
    formik.setFieldValue("itemDetails", [
      ...formik.values.itemDetails,
      {
        id: initialeData.length + 1,
        description: "",
        currentItemCategory: "",
        hsn: "",
        uom: "",
        quantity: 1,
        unitRate: 0,
        amount: 0,
      },
    ]);
  };
  useEffect(() => {
    if (selectedWarehouse?.length) {
      getData();
    } else {
      toast.info("Please select a warehouse to see reverse dc list!");
    }
  }, [selectedWarehouse]);

  const getData = async () => {
    setFetchingData(true);
    try {
      const { data } = await get(URLS.reverseDc.list, {
        params: {
          warehouse: { $in: selectedWarehouse },
        },
      });

      let res = data.result
        .map((d) => ({
          ...d,
          createDate: d.createdAt,
        }))
        .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

      setData(res);
      setFilteredData(res);

      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      console.error("Error fetching data:", error);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      dcNumber: "",
      fromDate: null,
      toDate: null,
    },
  });

  const handleFind = () => {
    const { endOfDay, startOfDay } = getDateRange(
      entryFormik.values.fromDate,
      entryFormik.values.toDate
    );

    let newList = data.filter(
      (p) =>
        dayjs(new Date(p.createDate)).diff(new Date(startOfDay), "s") > 0 &&
        dayjs(new Date(p.createDate)).diff(new Date(endOfDay), "s") < 0
    );
    setFilteredData(newList);
  };

  const resetFilter = () => {
    setSearch("");
    setFilteredData(data);
    entryFormik.resetForm();
  };

  useEffect(() => {
    if (search?.trim()?.length) {
      setFilteredData(
        data.filter((d) =>
          d.dcNo?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [search]);

  return (
    <>
      <DashBoardPageHeaderPage title="Reverse Dc(Delivery Challan)" />

      <PageHeader
        onAddClick={openModal}
        title=""
        modelName="Reverse Dc"
        module="Reverse-Dc"
        showAddField={false}
      />

      {fetchingData ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <Grid rowSpacing={1} columnSpacing={2} container mb={2}>
            <Grid xs={12} sm={6} md={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="From Date"
                name="fromDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="To Date"
                name="toDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Button
                size="small"
                disabled={
                  !entryFormik.values.fromDate || !entryFormik.values.toDate
                }
                onClick={handleFind}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Find
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={resetFilter}
                sx={{ mt: 2, ml: 1 }}
              >
                Clear Filter
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mb={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xs={12} md={6} lg={3}>
              <CustomInput
                label="Search Reverese DC"
                name="search-reverse-dc"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>

          <CustomTable
            actions={["delete", "view"]}
            bodyDataModal="reverse-dc"
            bodyData={filteredData}
            tableKeys={reverseDcTableKeys}
            onDeleteClick={handleDelete}
            onViewClick={handleShowData}
            module="Reverse-Dc"
          />
        </>
      )}

      <CustomDialog
        fullScreen={fullScreen}
        open={openViewModal}
        onClose={handleCloseViewModal}
      >
        <DialogTitle
          sx={{
            fontSize: "15px",
            ml: "-10px",

            color: (theme) =>
              theme.palette.mode === "dark" ? "#fff" : "#33669B",
          }}
        >
          View Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseViewModal}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <Divider />
        <SectionBox sx={{ zoom: 0.8 }} ref={componentRef}>
          <SubBox>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderBottom: "2px solid #000",
                  backgroundColor: "#33669B",
                }}
              ></Grid>
              <Grid item xs={5}>
                <Typography
                  fontWeight="bold"
                  py={1}
                  sx={{
                    fontSize: "17px",
                    backgroundColor: "#33669B",
                    borderBottom: "2px solid #000",
                    textAlign: "center",
                    color: "#fff",
                    "@media print": {
                      color: "#fff",
                    },
                  }}
                >
                  Original For Recipient
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ borderRight: "2px solid #000" }}>
                <Typography
                  variant="body1"
                  p={0.5}
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  <b style={{ fontSize: "16px" }}>
                    {" "}
                    Computacenter India Private Limited
                  </b>
                  <br />
                  CoWrks India Private Limited
                  <br />
                  CoWrks Residency Road No: 135/1
                  <br />
                  Purva Premiere, Ward No: 76
                  <br />
                  Bangalore, Karnataka
                  <br />
                  560025
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  p={0.5}
                  mt={2}
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  CIN: U74900KA2013FTC069471
                  <br />
                  GSTIN: 29AAFCC2892L1Z4
                  <br />
                  PAN: AAFCC2892L
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{
                      width: "150px",
                      objectFit: "contain",
                      padding: "10px 0px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 2,
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",
                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                        variant="h6"
                        fontWeight="bold"
                        fontSize={"18px"}
                      >
                        Delivery Challan
                      </Typography>

                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",

                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                      >
                        DC No: {dataToEdit?.dcNo}
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",
                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                      >
                        Date:{" "}
                        {new Date(dataToEdit?.createdAt).toLocaleString(
                          "en-IN",
                          { timeZone: "Asia/Kolkata" }
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Box sx={{ borderBottom: "2px solid black" }} />
              <Typography
                p={0.5}
                sx={{
                  borderBottom: "2px solid #000",
                  borderRight: "2px solid #000",
                  maxWidth: "200px",
                  fontWeight: "bold",
                  backgroundColor: "#33669B",
                  color: "white",
                }}
              >
                Dispatch From:
              </Typography>
              <Typography
                p={0.5}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                <b> {dataToEdit?.dispatchFrom?.name}</b>
                <br />
                {dataToEdit?.dispatchFrom?.address}, Pincode:
                {dataToEdit?.dispatchFrom?.pinCode}
                <br />
                {dataToEdit?.dispatchFrom?.contactNumber},
                <br />
                {/* <b>GSTIN:{dataToEdit?.dispatchFrom?.gstIn || "NA"}</b> */}
              </Typography>
            </Box>

            <Box
              sx={{
                borderBottom: "2px solid #000",
              }}
            >
              <Box sx={{ borderBottom: "2px solid black", marginTop: 2 }} />
              <Typography
                p={0.5}
                sx={{
                  borderBottom: "2px solid #000",
                  borderRight: "2px solid #000",
                  maxWidth: "200px",
                  fontWeight: "bold",
                  backgroundColor: "#33669B",
                  color: "white",
                  "@media print": {
                    color: "#fff",
                  },
                }}
              >
                Ship To:
              </Typography>
              <Typography
                p={0.5}
                mb={2}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                <b> {dataToEdit?.dispatchFrom?.name}</b>
                <br />
                {dataToEdit?.dispatchFrom?.address}

                <br />
                {/* <b>GSTIN:{dataToEdit?.shipTo?.gstIn || "NA"}</b> */}
              </Typography>
            </Box>

            <TableContainer sx={{ px: 0.2, mt: 0.2 }}>
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead
                  sx={{
                    backgroundColor: "#33669B",
                    color: "black",
                  }}
                >
                  <TableRow>
                    <TableCellStyled align="center">
                      Description
                    </TableCellStyled>
                    <TableCellStyled align="center">HSN</TableCellStyled>
                    <TableCellStyled align="center">UOM</TableCellStyled>
                    <TableCellStyled align="center">Qty.</TableCellStyled>
                    <TableCellStyled align="center">
                      Unit Rate (INR)
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      Amount (INR)
                    </TableCellStyled>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToEdit?.itemDetails?.map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.description || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.hsn || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.uom || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.quantity || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.unitRate || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.amount || "N/A"}
                      </TableCellStyled>
                    </TableRow>
                  ))}
                </TableBody>
                <TotalRow>
                  <TableCellStyled
                    colSpan={5}
                    sx={{
                      "@media print": {
                        color: "#000",
                      },
                    }}
                  >
                    Total (INR):
                  </TableCellStyled>
                  <TableCellStyled
                    align="center"
                    sx={{
                      backgroundColor: "#33669B",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    ₹ {new Intl.NumberFormat("en-IN").format(dataToEdit?.total)}
                  </TableCellStyled>
                </TotalRow>
              </Table>
            </TableContainer>

            <Grid container>
              <Grid item xs={12}>
                <SectionTitle
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  Total In Words: {dataToEdit?.totalInWords}
                </SectionTitle>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  p={0.5}
                  sx={{
                    borderBottom: "2px solid #000",
                    borderRight: "2px solid #000",
                    fontWeight: "bold",
                    backgroundColor: "#33669B",
                    color: "white",
                  }}
                >
                  Serial Number(s):
                </Typography>
              </Grid>

              <Grid
                sx={{
                  wordWrap: "wrap",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                item
                xs={12}
                p={0.5}
              >
                {dataToEdit?.msns?.map((d) => (
                  <Typography
                    key={d}
                    sx={{
                      "@media print": {
                        color: "#000",
                      },
                    }}
                  >
                    {d},{" "}
                  </Typography>
                ))}
              </Grid>
            </Grid>

            <Typography
              sx={{
                borderBottom: "2px solid #000",
                padding: "8px",
                "@media print": {
                  color: "#000",
                },
              }}
            ></Typography>

            <SignatureBox>
              <Typography
                p={0.5}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                Authorised Signatory
              </Typography>
              <Typography></Typography>
            </SignatureBox>
          </SubBox>
        </SectionBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1, mb: 1 }}>
          {" "}
          <Button
            variant="contained"
            size="small"
            sx={{ maxWidth: "200px" }}
            onClick={handlePrint}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Box>
      </CustomDialog>

      <FormDialog
        open={open}
        adding={loading}
        onClose={handleClose}
        submitButtonTitle={"Add"}
        formik={formik}
        formTitle={"Add DC"}
        maxWidth={CUSTOM_WIDTH}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <FormSelect
              required={true}
              label="Warehouse"
              formik={formik}
              name="warehouse"
              options={warehouses}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormSelect
              required={true}
              name="ownerGroup"
              formik={formik}
              label="Owner Group"
              options={ownerGroups.filter(
                (o) => o.warehouse?._id === formik.values.warehouse
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormSelect
              required={true}
              name="ownerCode"
              formik={formik}
              label="Owner Code"
              options={ownerCodes.filter(
                (o) => o.ownerGroup?._id === formik.values.ownerGroup
              )}
            />
          </Grid>

          {!formik.values.warehouse ? null : (
            <>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: { CUSTOM_WIDTH },
                  pl: 2,
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "auto",
                }}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light,
                      }}
                    >
                      <TableRow>
                        <StyledTableCell sx={{ width: 50 }} align="center">
                          Item Category
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: 300, minWidth: 300 }}
                          align="center"
                        >
                          Description
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: 200, minWidth: 200 }}
                          align="center"
                        >
                          HSN
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: 80, minWidth: 80 }}
                          align="center"
                        >
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Unit Rate
                        </StyledTableCell>
                        <StyledTableCell align="center">Amount</StyledTableCell>
                        <TableCell align="center">Remove</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {formik.values.itemDetails.map((item, index) => (
                        <TableRow key={item.id}>
                          <StyledTableCell sx={{ width: 50 }} align="center">
                            <CustomSelect
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="currentItemCategory"
                              label="Current Item Category"
                              value={item.currentItemCategory || ""}
                              required={true}
                              options={[
                                { label: "New", value: "new" },
                                { label: "Used", value: "used" },
                              ]}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{ maxWidth: 300, minWidth: 300 }}
                            align="center"
                          >
                            <CustomSelect
                              disabled={!item.currentItemCategory}
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="description"
                              label="Description"
                              value={item.description || ""}
                              required={true}
                              options={itemDescription}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{ maxWidth: 200, minWidth: 200 }}
                            align="center"
                          >
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="hsn"
                              label="HSN"
                              value={item.hsn || ""}
                              required={true}
                              disabled={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ maxWidth: 80, minWidth: 80 }}
                            align="center"
                          >
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="quantity"
                              label="Quantity"
                              value={item.quantity || 0}
                              required={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="unitRate"
                              label="Unit Rate"
                              value={item.unitRate || 0}
                              required={true}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChange(e, index)
                              }
                              name="amount"
                              label="Amount"
                              value={item.amount || 0}
                              required={true}
                              disabled={true}
                            />
                          </StyledTableCell>

                          <TableCell align="center">
                            <DeleteIcon
                              fontSize="small"
                              sx={{ cursor: "pointer" }}
                              onClick={() => removeData(index)}
                              color="error"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: { CUSTOM_WIDTH },
                  pl: 2,
                }}
              >
                <Button
                  size="small"
                  sx={{ mt: 1, mb: 1 }}
                  variant="contained"
                  onClick={handleData}
                  startIcon={<Add />}
                >
                  Add
                </Button>
                <CustomInput
                  onChange={(e) =>
                    formik.setFieldValue(
                      "msns",
                      e.target.value?.trim()?.toUpperCase()
                    )
                  }
                  name="msns"
                  label="Enter all msn comma separated"
                  value={formik.values.msns?.toUpperCase() || ""}
                  required={true}
                />
              </Box>
            </>
          )}

          <Grid item xs={12}>
            <Typography mt={0.5} textAlign={"left"} fontWeight={"500"}>
              Dispatch From:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid lightgray",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-pinCode"
                    size="small"
                    options={pinCodes}
                    value={formik.values.pinCode}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("pinCode", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("pinCode", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Pincode"
                        name="pinCode"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-name"
                    size="small"
                    options={names}
                    value={formik.values.name}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("name", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("name", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Dispatch From  Name"
                        name="name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-address"
                    size="small"
                    options={addresses}
                    value={formik.values.address}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("address", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("address", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select   Dispatch From Address"
                        name="address"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-contactnumber"
                    size="small"
                    options={contactNumbers}
                    value={formik.values.contactNumber}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("contactNumber", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue(
                          "contactNumber",
                          newInputValue || ""
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Contact Number"
                        name="contactNumber"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
}
