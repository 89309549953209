import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import ViewOrUpdateItemFiles from "../../components/ViewOrUpdateItemFiles";
import { hasAllValues, ROWS_PER_PAGE } from "../../utils/utilities";

// custom components
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";

import { LoadingButton } from "@mui/lab";
import { Search } from "@mui/icons-material";
import ConfirmationModal from "../../forms/ConfirmationModal";
import WarehouseContext from "../../context/WarehouseContext";
import { toast } from "react-toastify";
import ShowComponent from "../ShowComponent";
import { PuffLoader } from "react-spinners";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function UpdateSavedStock() {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [addingSave, setAddingSave] = useState(false);
  const [addingUpdate, setAddingUpdate] = useState(false);
  const [stockTypes, setStockTypes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [gradingScale, setGradingScale] = useState([]);
  const [damageCodeData, setDamageCodeData] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [itemFormFields, setItemFormFields] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [itemDescription, setItemDescription] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const savedStockData = location.state && location.state.itemData;

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.list, {
        params: {
          collectionName: "item",
          warehouse: { $in: selectedWarehouse },
        },
      });
      setItemFormFields(data.result ? data.result : []);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });
      const itemDescriptionPromise = get(
        URLS.itemDescription.getItemDescription,
        {
          params: { search: { warehouse: { $in: selectedWarehouse } } },
        }
      );
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const currentConditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });

      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });

      const gradingScallePromise = get(URLS.gradingScale.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const packagingPromise = get(URLS.packaging.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });

      const [
        stockTypeData,
        subFamilyData,
        itemDescriptionData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        gradingScaleData,
        packagingData,
        damageCodeData,
        brandData,
        activityCodeData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        itemDescriptionPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        gradingScallePromise,
        packagingPromise,
        damageCodePromise,
        brandDataPromise,
        activityCodePromise,
        orderTypeDataPromise,
      ]);

      setActivityCodes(
        activityCodeData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setItemDescription(
        itemDescriptionData.data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setGradingScale(
        gradingScaleData?.data?.result?.map((s) => ({
          ...s,
          label: `${s.name}-${s.description}`,
          value: s._id,
        }))
      );
      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );

      setDamageCodeData(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getItemFormDetails();
  }, [selectedWarehouse]);

  const handleChange = (e, index, repeat = false) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "expiryDate";
      value = e;
    }

    const faultyId = "faulty";
    const usedId = "used";

    if (name === "gradingScale") {
      const isDamaged = gradingScale.find((g) => g._id === value)?.isDamaged;
      if (isDamaged) {
        setSearchFilter(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                  itemCategory: faultyId,
                }
              : fd
          )
        );
        setFormData(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                  itemCategory: faultyId,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );

          setSearchFilter(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );

          setSearchFilter(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    activityCode: "",
                    damageCode: "",
                  }
                : fd
            )
          );
        }
      }
    } else {
      const selectedIsDamaged = gradingScale.find(
        (g) => g._id === formData[index].gradingScale
      )?.isDamaged;

      if (selectedIsDamaged) {
        setFormData(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                }
              : fd
          )
        );

        setSearchFilter(
          formData.map((fd, i) =>
            i === index
              ? {
                  ...fd,
                  [name]: value,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );

          setSearchFilter(
            formData.map((fd, i) =>
              i >= index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );

          setSearchFilter(
            formData.map((fd, i) =>
              i === index
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        }
      }
    }
  };

  // const getSavedStock = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await get(URLS.items.listSavedStock, {
  //       params: {
  //         warehouseId: selectedWarehouse,
  //         ownerCode: selectedOwnerCode,
  //       },
  //     });
  //     let items = data.result;

  //     items = items.filter(
  //       (item) => item.subFamily._id == savedStockData.subFamilyId
  //     );

  //     const newFormData = items.map((item) => ({
  //       _id: item._id,
  //       itemCategory: item.itemCategory._id,
  //       stockType: item.stockType._id,
  //       msn: item.msn,
  //       subFamily: item.subFamily._id,
  //       itemDescription: item.itemDescription,
  //       itemCode: item.itemCode,
  //       manufacturerPartNumber: item.manufacturerPartNumber,
  //       brandName: item.brandName._id,
  //       storageCode: item.storageCode._id,
  //       palletCode: item.palletCode._id,
  //       conditionCode: item.conditionCode?._id,
  //       gradingScale: item.gradingScale ? item.gradingScale._id : null,
  //       damageCode: item.damageCode ? item.damageCode._id : null,
  //       // isn: item.isn,
  //       packaging: item.packaging ? item.packaging._id : null,
  //       activityCode: item.activityCode ? item.activityCode._id : null,
  //       configurationOs: item.configurationOs,
  //       // family: item.family._id,
  //       remarks: item.remarks,
  //       images: item.images,
  //       inStock: item.inStock,
  //       meta: itemFormFields?.map((f) => ({
  //         ...f,
  //         value:
  //           item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value ||
  //           "",
  //       })),
  //     }));
  //     setLoading(false);
  //     setFormData(newFormData);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (savedStockData) {
  //     getSavedStock();
  //   }
  // }, [itemFormFields]);

  const getItems = async () => {
    try {
      setLoading(true);

      let filter = {
        inStock: false,
        isSaved: true,
        inBundle: false,
        subFamily: savedStockData.subFamilyId,
      };

      if (search?.trim()?.length) {
        filter["msn"] = search;
      }
      const { data } = await get(URLS.items.listToUpdate, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          page: page + 1,
          limit: rowsPerPage,
          search: filter,
        },
      });

      if (data.result) {
        setTotalItems(data.result.totalItems);
        const newFormData = data.result.items?.map((item) => ({
          _id: item._id,
          itemCategory: item.itemCategory,
          warehouse: item.warehouse?._id,
          expiryDate: item.expiryDate,
          stockType: item.stockType._id,
          orderType: item.orderType?._id,
          msn: item.msn,
          subFamily: item.subFamily._id,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName._id,
          storageCode: item.storageCode._id,
          palletCode: item.palletCode._id,
          conditionCode: item.conditionCode._id,
          gradingScale: item.gradingScale ? item.gradingScale._id : null,
          damageCode: item.damageCode ? item.damageCode._id : null,
          packaging: item.packaging ? item.packaging._id : null,
          activityCode: item.activityCode ? item.activityCode._id : null,
          configurationOs: item.configurationOs,
          remarks: item.remarks,
          images: item.images,
          meta: itemFormFields
            ?.find((ff) => ff.warehouse?._id === item.warehouse?._id)
            ?.fields?.map((f) => ({
              ...f,
              value:
                item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                  ?.value || "",
            })),
        }));
        setFormData(newFormData);
        setSearchFilter(newFormData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (savedStockData) {
      getItems();
    }
  }, [itemFormFields, page, rowsPerPage, search]);

  const handleUpdate = async (id, fd) => {
    try {
      for (let item of [fd]) {
        if (item.meta) {
          if (
            gradingScale.find((g) => g._id === item.gradingScale)?.isDamaged
          ) {
            if (!hasAllValues(item, ["images", "remarks", "configurationOs"]))
              return;
          } else {
            if (
              !hasAllValues(item, [
                "images",
                "remarks",
                "damageCode",
                "activityCode",
                "configurationOs",
              ])
            )
              return;
          }
        } else {
          if (
            gradingScale.find((g) => g._id === item.gradingScale)?.isDamaged
          ) {
            if (
              !hasAllValues(item, [
                "images",
                "remarks",
                "configurationOs",
                "meta",
              ])
            )
              return;
          } else {
            if (
              !hasAllValues(item, [
                "images",
                "remarks",
                "damageCode",
                "activityCode",
                "gradingScale",
                "packaging",
                "configurationOs",
                "meta",
              ])
            )
              return;
          }
        }
      }

      [fd].forEach((f) => {
        if (!gradingScale.find((g) => g._id === f.gradingScale)?.isDamaged) {
          delete f.activityCode;
          delete f.damageCode;
        }
      });

      if ([fd].some((fd) => fd.meta?.some((f) => f.isRequired && !f.value)))
        return toast.error("Please fill required fields of this item");

      setAddingSave((prv) => ({
        ...prv,
        [id]: true,
      }));
      const payload = {
        ...fd,
      };
      const { data } = await put(URLS.items.update + "/" + id, payload);
      getItems();
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [id]: false,
    }));
  };

  const onUpdateItem = (item) => {
    // getSavedStock();
    getItems();
    setFormData(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: item.itemCategory,
              warehouse: item.warehouse?._id,
              stockType: item.stockType._id,
              orderType: item.orderType?._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,
              gradingScale: item.gradingScale ? item.gradingScale._id : null,
              damageCode: item.damageCode ? item.damageCode._id : null,
              packaging: item.packaging ? item.packaging._id : null,
              activityCode: item.activityCode ? item.activityCode._id : null,
              configurationOs: item.configurationOs,
              remarks: item.remarks,
              images: item.images,
              inStock: item.inStock,
              meta: itemFormFields
                ?.find((ff) => ff.warehouse?._id === item.warehouse?._id)
                ?.fields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
            }
          : d
      )
    );
    setSearchFilter(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: item.itemCategory,
              warehouse: item.warehouse?._id,
              stockType: item.stockType._id,
              orderType: item.orderType?._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,
              gradingScale: item.gradingScale ? item.gradingScale._id : null,
              damageCode: item.damageCode ? item.damageCode._id : null,
              packaging: item.packaging ? item.packaging._id : null,
              activityCode: item.activityCode ? item.activityCode._id : null,
              configurationOs: item.configurationOs,
              remarks: item.remarks,
              images: item.images,
              inStock: item.inStock,
              meta: itemFormFields
                ?.find((ff) => ff.warehouse?._id === item.warehouse?._id)
                ?.fields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
            }
          : d
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
    // if (value.trim() !== "") {
    //   formData.length > 0 &&
    //     setSearchFilter(
    //       formData.filter((ele) =>
    //         ele.msn.toLowerCase().includes(value.toLowerCase().trim())
    //       )
    //     );
    // } else {
    //   setSearchFilter(formData);
    // }
  };

  const [stockItem, setStockItem] = useState({});

  const submitStock = (item) => {
    setOpen(true);
    setStockItem(item);
  };

  const handleOnAddToStock = () => {
    // getSavedStock();
    setOpen(false);
    setStockItem(null);
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );

    setSearchFilter(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={12} md={9} mb={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} mb={2}>
          <TextField
            size="small"
            value={search}
            fullWidth
            onChange={handleSearch}
            label="Search by MSN"
            placeholder="Enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <PuffLoader size={30} color="#14532d" />
        </Box>
      ) : null}
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }} align="center">
                S.No
              </TableCell>
              <StyledTableCell align="center">Item Category</StyledTableCell>
              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">Order Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>

              <StyledTableCell align="center">Grading Scale</StyledTableCell>
              <StyledTableCell align="center">Activity Code</StyledTableCell>
              <StyledTableCell align="center">Damage Code</StyledTableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>

              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              {itemFormFields
                ?.flatMap((f) => f.fields)
                ?.map((i, index) => (
                  <StyledTableCell align="center" key={index}>
                    {i.inputLabel}
                  </StyledTableCell>
                ))}
              <StyledTableCell align="center"> Inbound Remarks</StyledTableCell>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                Image
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchFilter.map((fd, i) => (
              <TableRow key={i}>
                <TableCell sx={{ minWidth: "80px" }} align="center">
                  {page * rowsPerPage + i + 1}
                </TableCell>

                <StyledTableCell align="center">
                  <Typography>{fd.itemCategory?.toUpperCase()}</Typography>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <CustomSelect
                    options={stockTypes}
                    name="stockType"
                    label="Stock Type"
                    value={fd.stockType}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={orderTypeData}
                    name="orderType"
                    label="Order Type"
                    value={fd.orderType}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="msn"
                    value={fd.msn?.toUpperCase()}
                    label="MSN"
                    required={true}
                    disabled={fd.msn}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    onChange={(e) => handleChange(e, i)}
                    options={subFamilies}
                    name="subFamily"
                    label="Sub-Family Name"
                    value={fd.subFamily}
                    required={true}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    name="itemDescription"
                    value={fd.itemDescription}
                    options={itemDescription.filter(
                      (s) => s.warehouse === fd.warehouse
                    )}
                    label="Item Description"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="itemCode"
                    value={fd.itemCode}
                    label="Item Code"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="manufacturerPartNumber"
                    value={fd.manufacturerPartNumber}
                    label="Manufacturer Part Number"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={brandData}
                    name="brandName"
                    label="Brand Name"
                    value={fd.brandName}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={storageCodes}
                    name="storageCode"
                    label="Storage Code"
                    value={fd.storageCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={palletCodes}
                    name="palletCode"
                    label="Pallet Code"
                    value={fd.palletCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={currentConditionCodes}
                    name="conditionCode"
                    label="Current Condition Code"
                    value={fd.conditionCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>

                <StyledTableCell align="center">
                  {fd.itemCategory !== "new" ? (
                    <CustomSelect
                      options={gradingScale}
                      name="gradingScale"
                      label="Grading Scale"
                      value={fd.gradingScale}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  ) : null}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {fd.itemCategory !== "new" ? (
                    <CustomSelect
                      disabled={
                        !gradingScale.find((g) => g._id === fd.gradingScale)
                          ?.isDamaged
                      }
                      options={damageCodeData}
                      name="damageCode"
                      label="Damage Code"
                      value={fd.damageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  ) : null}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {fd.itemCategory !== "new" ? (
                    <CustomSelect
                      disabled={
                        !gradingScale.find((g) => g._id === fd.gradingScale)
                          ?.isDamaged
                      }
                      options={activityCodes}
                      name="activityCode"
                      label="Activity Code"
                      value={fd.activityCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  ) : null}
                </StyledTableCell>

                <StyledTableCell align="center">
                  {fd.itemCategory !== "new" ? (
                    <CustomSelect
                      options={packaging}
                      name="packaging"
                      label="Packaging"
                      value={fd.packaging}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  ) : null}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <CustomInput
                    name="configurationOs"
                    value={fd.configurationOs}
                    label="Configuration OS"
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>

                {itemFormFields.flatMap((f, index) =>
                  f.warehouse?._id === fd.warehouse ? (
                    fd.meta?.map((f, index) => (
                      <StyledTableCell align="center" key={index}>
                        <CustomInput
                          onChange={(e) => handleCustomInputChangeItem(e, i)}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </StyledTableCell>
                    ))
                  ) : (
                    <StyledTableCell align="center">NA</StyledTableCell>
                  )
                )}
                <StyledTableCell align="center">
                  <CustomInput
                    name="remarks"
                    value={fd.remarks}
                    label="Inbound Remarks"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <TableCell align="center" sx={{ minWidth: "80px" }}>
                  <ShowComponent module={"Inventory"} action={"update"}>
                    <ViewOrUpdateItemFiles
                      module={"Inventory"}
                      item={fd}
                      onUpdate={onUpdateItem}
                    />
                  </ShowComponent>
                </TableCell>
                <TableCell align="center">
                  <ShowComponent module={"Inventory"} action={"update"}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LoadingButton
                        loading={addingSave[fd._id]}
                        size="small"
                        variant="contained"
                        onClick={() => handleUpdate(fd._id, fd)}
                        sx={{ marginRight: "10px" }}
                      >
                        Save
                      </LoadingButton>
                      {!fd.inStock ? (
                        <Button
                          size="small"
                          variant="contained"
                          fullWidth
                          sx={{ width: "120px" }}
                          onClick={() => submitStock(fd)}
                        >
                          Add To Stock
                        </Button>
                      ) : null}
                    </Box>
                  </ShowComponent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!formData.length && !search.trim() && !loading && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )}
        {search.trim() && !searchFilter.length && !loading && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )}
      </TableContainer>

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="add this item to stock"
        description=""
        item={stockItem}
        onAddToStock={handleOnAddToStock}
      />
    </>
  );
}
