import { useEffect, useRef, useState } from "react";

const useAutoLogout = (onLogout, timeout = 15 * 60 * 1000) => {
  const timeoutRef = useRef(null); // Reference for the auto-logout timeout
  const intervalRef = useRef(null); // Reference for the countdown interval
  const [timeLeft, setTimeLeft] = useState(timeout); // Remaining time for auto-logout

  const clearAllTimers = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const startCountdown = () => {
    const endTime = Date.now() + timeLeft; // Calculate when the timer should end

    // Update `timeLeft` every second
    intervalRef.current = setInterval(() => {
      const remainingTime = Math.max(0, endTime - Date.now());
      setTimeLeft(remainingTime);

      if (remainingTime <= 0) {
        clearInterval(intervalRef.current);
      }
    }, 1000);

    // Trigger logout when timeout completes
    timeoutRef.current = setTimeout(() => {
      onLogout();
      clearAllTimers();
    }, timeLeft);
  };

  const resetTimeout = () => {
    clearAllTimers(); // Clear any existing timers
    setTimeLeft(timeout); // Reset `timeLeft` to the full timeout
    startCountdown(); // Restart the countdown
  };

  useEffect(() => {
    const handleActivity = () => {
      resetTimeout(); // Reset timers on user activity
    };

    // Add event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);

    // Start the countdown on component mount
    startCountdown();

    return () => {
      // Cleanup event listeners and timers on unmount
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
      clearAllTimers();
    };
  }, [onLogout, timeout]);

  return { timeLeft: (timeLeft / 60000).toFixed(2) }; // Return the remaining time
};

export default useAutoLogout;
