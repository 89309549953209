import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

// custom imports
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FileSelect from "../../forms/FileSelect";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";

// services
import { get, post } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import { ROWS_PER_PAGE } from "../../utils/utilities";

// utilities
import { generateRandomNumber, hasAllValues } from "../../utils/utilities";
import WarehouseContext from "../../context/WarehouseContext";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "150px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),

  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,

  padding: theme.spacing(2),
  zIndex: 1000,
}));

export default function BundleNewUsedAndCombination({
  bundles,
  bundleType,
  onBundleCreate = () => {},
  warehouseId,
  stockType,
  ownerGroupId,
  ownerCodeId,
}) {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);

  const [addingBundle, setAddingBundle] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async () => {
    try {
      const storageCodePromise = get(URLS.storageCode.list, {
        params: {
          search: {
            warehouse: warehouseId,
          },
        },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: {
          search: {
            warehouse: warehouseId,
          },
        },
      });
      const [storageCodeData, palletCodeData] = await Promise.all([
        storageCodePromise,
        palletCodeCodePromise,
      ]);

      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let firstItem = bundles[0];
  let HEADER_KEYS = [];
  if (firstItem) {
    for (let item of firstItem) {
      let newItem = [
        "Stock Type",
        item.subFamily.name,
        "Present Storage Code",
        "Present Pallet Code",
      ];

      HEADER_KEYS = [...HEADER_KEYS, ...newItem];
    }
  }

  useEffect(() => {
    let newForm = [];
    for (let bundle of bundles) {
      let form = {};
      let bundleReferenceNumber = `${generateRandomNumber(5)}`;
      form["bundleReferenceNumber"] = bundleReferenceNumber;
      form["items"] = [];
      form["itemIds"] = [];
      for (let item of bundle) {
        form.itemIds.push(item._id);
        let VALUES = [
          item.stockType.name,
          item.msn,
          item.storageCode.code,
          item.palletCode.code,
        ];
        form.items = [...form.items, ...VALUES];
      }
      form["newStorageCode"] = "";
      form["newPalletCode"] = "";
      form["newStockType"] = "";
      form["remarks"] = "";
      form["images"] = [];

      newForm.push(form);
    }

    setFormData(newForm);
  }, [bundles]);

  // added new function
  const handleRemoveFile = (fileName, index) => {
    let files = formData[index].images.filter((f) => f.name !== fileName);
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, images: files } : fd))
    );
  };

  const handleChangeFiles = (e, index) => {
    const { files } = e.target;

    let fileList = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        fileList.push(file);
      }

      setFormData(
        formData.map((fd, i) =>
          i === index ? { ...fd, images: fileList } : fd
        )
      );
    } else {
      console.log("No files selected");
    }
  };

  const handleChange = (e, index, repeat) => {
    const { name, value } = e.target;
    if (repeat) {
      setFormData(
        formData.map((fd, i) => (i >= index ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
      );
    }
  };

  const handleRemoveForm = (bundleReferenceNumber) =>
    setFormData(
      formData.filter((f) => f.bundleReferenceNumber !== bundleReferenceNumber)
    );

  const handleSubmitForms = async (e) => {
    e.preventDefault();
    for (let item of formData) {
      if (!hasAllValues(item, ["images", "remarks"])) return;
    }

    setAddingBundle(true);

    let newFormData = new FormData();

    newFormData.append("bundleFields", JSON.stringify(formData));
    newFormData.append("bundleTypeId", bundleType);
    newFormData.append("warehouseId", warehouseId);
    newFormData.append("ownerGroupId", ownerGroupId);
    newFormData.append("ownerCodeId", ownerCodeId);

    formData.forEach((item) => {
      item.images.forEach((file, index) => {
        newFormData.append(`${item.bundleReferenceNumber}`, file);
      });
    });

    try {
      const { data } = await post(URLS.bundle.create, newFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setAddingBundle(false);

      onBundleCreate();
    } catch (error) {
      console.log(error);
      setAddingBundle(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
          >
            <TableRow>
              <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                S. No.
              </StyledTableCell>

              {HEADER_KEYS.map((item, i) => (
                <StyledTableCell align="center" key={i}>
                  {item}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">
                Bundle Reference Number
              </StyledTableCell>
              <StyledTableCell align="center">New Storage code</StyledTableCell>
              <StyledTableCell align="center">New pallet code</StyledTableCell>
              <StyledTableCell align="center">
                New stock type for bundle
              </StyledTableCell>
              <StyledTableCell align="center">Remarks</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "200px" }} align="center">
                Image
              </StyledTableCell>
              <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                Remove
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, index) => (
                <TableRow>
                  <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                    {page * rowsPerPage + index + 1}
                  </StyledTableCell>
                  {fd.items.map((v, i) => (
                    <StyledTableCell align="center" key={i}>
                      {v}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    {fd.bundleReferenceNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      label="New Storage code"
                      required={true}
                      name="newStorageCode"
                      options={storageCodes}
                      value={fd.newStorageCode || ""}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      label="New Pallet code"
                      required={true}
                      name="newPalletCode"
                      options={palletCodes}
                      value={fd.newPalletCode || ""}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      label="New Stock type"
                      required={true}
                      name="newStockType"
                      options={stockType}
                      value={fd.newStockType || ""}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomInput
                      name="remarks"
                      value={fd.remarks}
                      label="Remarks"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <FileSelect
                      name={`images${index}`}
                      onChange={(e) => handleChangeFiles(e, index)}
                      customOnChange={true}
                      selectedFiles={fd.images}
                      onRemove={(fileName) => handleRemoveFile(fileName, index)}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={(e) =>
                        handleRemoveForm(fd.bundleReferenceNumber)
                      }
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={formData ? (formData.length ? formData.length : 0) : 0}
        page={page}
        rowsPerPageOptions={ROWS_PER_PAGE}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ marginBottom: "50px" }}
      />

      {bundles.length ? (
        <Grid container>
          <Grid item xs={12} md={12}>
            <StyledBox
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(32,33,32,1)"
                    : theme.palette.grey[100],
              }}
            >
              <LoadingButton
                onClick={handleSubmitForms}
                variant="contained"
                size="small"
                loading={addingBundle}
              >
                Save
              </LoadingButton>
            </StyledBox>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
