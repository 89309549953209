import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Stack,
  Button,
  TablePagination,
} from "@mui/material";
import PageHeader from "../../components/PageHeader";
import { hasAllValues, ROWS_PER_PAGE } from "../../utils/utilities";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import URLS from "../../services/urlConstants";
import { get, post, put } from "../../services/apiMethods";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import FormSelect from "../../forms/FormSelect";
import { toast } from "react-toastify";
import TabList from "../../components/Tabs/TabList";
import TabPanel from "../../components/Tabs/TabPanel";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import WarehouseContext from "../../context/WarehouseContext";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import { debounce } from "lodash";
import FormSelectVirtualized from "../../forms/FormSelectVirtualized";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function splitByLastDash(value) {
  if (typeof value !== "string") return { before: null, after: null };

  // Find the index of the last '-'
  const lastDashIndex = value.lastIndexOf("-");

  if (lastDashIndex === -1) {
    // If no dash is found, return the whole string as 'before' and 'after' as null
    return { before: value, after: null };
  }

  // Split the string into two parts
  const before = value.substring(0, lastDashIndex).trim();
  const after = value.substring(lastDashIndex + 1).trim();

  return { before, after };
}

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "170px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),

  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,

  padding: theme.spacing(2),
  zIndex: 1000,
}));

export default function Outbound() {
  const [formData, setFormData] = useState([]);
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);

  const [stockTypes, setStockTypes] = useState([]);
  const [itemDescriptions, setItemDescriptions] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [packagingData, setPackagingData] = useState([]);
  const [courierData, setCourierData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [courierPartner, setCourierPartner] = useState([]);
  const [pickLists, setPickLists] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [inStockItems, setInStockItems] = useState([]);
  const [entryToEdit, setEntryToEdit] = useState("");
  const [outbounds, setOutbounds] = useState([]);
  const [selectedPickLists, setSelectedPickLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [ownerData, setOwnerData] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [showingData, setShowingData] = useState([]);
  const [updateStatus, setUpdateStatus] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const [value, setSelectValue] = useState(0);
  const handleTabChange = (e, newValue) => setSelectValue(newValue);

  // custom fields
  const [formFields, setFormFields] = useState(null);

  const formik = useFormik({
    initialValues: {
      warehouse: "",
      ownerCode: "",
      ownerGroup: "",
      gmsPickListcode: [],
      manualBundleReferenceNumber: [],
      manualMsn: "",
    },
    onSubmit: console.log,
    validateOnBlur: false,
  });

  const getItemDescriptions = async () => {
    try {
      const { data } = await get(URLS.itemDescription.list, {
        params: { warehouse: formik.values.warehouse },
      });
      setItemDescriptions(
        data?.result.map((item) => ({
          ...item,
          label: item.description,
          value: item._id,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (formik.values.warehouse) {
      getItemDescriptions();
    } else {
      setItemDescriptions([]);
    }
  }, [formik.values.warehouse]);

  //warehouseData
  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          // label: `${w.name} - ${w.code}`,
          label: w.code,
          value: w._id,
        }))
      );
      if (data.result?.length === 1) {
        formik.setFieldValue("warehouse", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  //customform
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "outbound",
          warehouse: formik.values.warehouse,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerGroups = async () => {
    try {
      const { data } = await get(URLS.ownerGroup.list, {
        params: { warehouse: formik.values.warehouse },
      });

      setOwnerData(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );
      formik.setFieldValue("ownerGroup", "");
      if (data.result?.length === 1) {
        formik.setFieldValue("ownerGroup", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: formik.values.ownerGroup },
      });

      setOwnerCodes(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      formik.setFieldValue("ownerCode", "");

      if (data.result?.length === 1) {
        formik.setFieldValue("ownerCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.ownerGroup) {
      formik.setFieldValue("ownerCode", "");
      getOwnerCodes();
    } else {
      setOwnerCodes([]);
      formik.setFieldValue("ownerCode", "");
    }
  }, [formik.values.ownerGroup]);

  useEffect(() => {
    if (formik.values.warehouse) {
      getFormDetails();
      getOwnerGroups();
    }
  }, [formik.values.warehouse]);

  useEffect(() => {
    setFormData(formData.map((fd) => ({ ...fd, meta: formFields || [] })));
  }, [formFields]);

  const getItems = async () => {
    try {
      setShowingData((prev) => [...prev, "items"]);
      const { data } = await get(URLS.items.list, {
        params: {
          search: {
            inStock: true,
            inBundle: false,
            isSaved: false,
            warehouse: formik.values.warehouse,
            ownerCode: formik.values.ownerCode,
            ownerGroup: formik.values.ownerGroup,
          },
        },
      });
      setInStockItems(data.result.map((i) => ({ label: i.msn, value: i.msn })));
      setShowingData((prev) => prev.filter((f) => f !== "items"));
    } catch (error) {
      setShowingData((prev) => prev.filter((f) => f !== "items"));
      console.log(error);
    }
  };

  const getAllPickList = async () => {
    try {
      setShowingData((prev) => [...prev, "gmsPickListCodes"]);
      const { data } = await get(URLS.pickList.list, {
        params: {
          search: {
            used: false,
            warehouseCode: formik.values.warehouse,
            ownerCode: formik.values.ownerCode,
            ownerGroup: formik.values.ownerGroup,
          },
        },
      });

      setPickLists(
        data.result.map((p) => ({
          ...p,
          label: p.gmsPickListCode,
          value: p.gmsPickListCode,
        }))
      );
      setShowingData((prev) => prev.filter((f) => f !== "gmsPickListCodes"));
    } catch (error) {
      setShowingData((prev) => prev.filter((f) => f !== "gmsPickListCodes"));
      console.log(error);
    }
  };

  const getBundles = async () => {
    try {
      setShowingData((prev) => [...prev, "bundles"]);
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: {
            inStock: true,
            warehouse: formik.values.warehouse,
            ownerCode: formik.values.ownerCode,
            ownerGroup: formik.values.ownerGroup,
          },
        },
      });
      setBundles(
        data.result?.map((d) => ({
          label: d.bundleReferenceNumber,
          value: d.bundleReferenceNumber,
        }))
      );
      setShowingData((prev) => prev.filter((f) => f !== "bundles"));
    } catch (error) {
      setShowingData((prev) => prev.filter((f) => f !== "bundles"));
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      formik.values.ownerCode &&
      formik.values.ownerGroup &&
      formik.values.warehouse
    ) {
      getAllPickList();
      getBundles();
      getItems();
      setFormData([]);
      setSelectedPickLists([]);
    } else {
      // toast.error("Invalid Selection!");
    }
  }, [
    formik.values.ownerCode,
    formik.values.ownerGroup,
    formik.values.warehouse,
  ]);

  const refreshList = () => {
    if (
      formik.values.ownerCode &&
      formik.values.ownerGroup &&
      formik.values.warehouse
    ) {
      getAllPickList();
      getBundles();
      getItems();
      setFormData([]);
      formik.setFieldValue("gmsPickListcode", []);
      formik.setFieldValue("manualBundleReferenceNumber", []);
      formik.setFieldValue("manualMsn", "");

      setSelectedPickLists([]);
    } else {
      setFormData([]);
      setSelectedPickLists([]);

      // toast.error("Invalid Selection!");
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { warehouse: formik.values.warehouse },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: formik.values.warehouse },
      });
      const PackagingPromise = get(URLS.packaging.list, {
        params: { warehouse: formik.values.warehouse },
      });
      const courierModePromise = get(URLS.courierMode.list, {
        params: { warehouse: formik.values.warehouse },
      });
      const brandPromise = get(URLS.brand.list, {
        params: { warehouse: formik.values.warehouse },
      });
      const courierPartnerPromise = get(URLS.courierPartner.list, {
        params: { warehouse: formik.values.warehouse },
      });

      const updateStatusPromise = get(URLS.updateStatus.list, {
        params: { warehouse: formik.values.warehouse },
      });

      const [
        stockTypeData,
        subFamilyData,
        updateStatusData,
        packagingData,
        courierData,
        brandData,
        courierPartnerData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        updateStatusPromise,
        PackagingPromise,
        courierModePromise,
        brandPromise,
        courierPartnerPromise,
      ]);
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setUpdateStatus(
        updateStatusData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setPackagingData(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );

      setCourierData(
        courierData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setBrandData(
        brandData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setCourierPartner(
        courierPartnerData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.warehouse) {
      getData();
    }
  }, [formik.values.warehouse]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    let dataToModify = formData[index];

    if (
      ["length", "width", "height"].includes(name) &&
      !dataToModify.courierMode
    ) {
      return toast.info("Please select Courier mode!");
    }
    if (
      ["length", "width", "height"].includes(name) &&
      dataToModify.courierMode
    ) {
      let divisionFactor = courierData.find(
        (c) => c._id == dataToModify.courierMode
      )?.divisionFactor;
      let newVolumetric = {
        ...dataToModify.volumetric,
        [name]: value,
      };
      dataToModify = {
        ...dataToModify,
        volumetric: {
          ...dataToModify.volumetric,
          [name]: value,
        },
        volumetricWeight: (
          (newVolumetric.length * newVolumetric.width * newVolumetric.height) /
          divisionFactor
        )?.toFixed(2),
      };

      return setFormData(
        formData.map((fd, i) => (i == index ? dataToModify : fd))
      );
    } else if (
      ["courierMode"].includes(name) &&
      dataToModify?.volumetric?.length &&
      dataToModify?.volumetric?.width &&
      dataToModify?.volumetric.height
    ) {
      let divisionFactor = courierData.find(
        (c) => c._id == value
      )?.divisionFactor;
      dataToModify = {
        ...dataToModify,
        [name]: value,
        volumetricWeight: (
          (dataToModify.volumetric.length *
            dataToModify.volumetric.width *
            dataToModify.volumetric.height) /
          divisionFactor
        )?.toFixed(2),
      };
      return setFormData(
        formData.map((fd, i) =>
          i >= index
            ? {
                ...fd,
                [name]: value,
                volumetricWeight: (
                  (fd.volumetric.length *
                    fd.volumetric.width *
                    fd.volumetric.height) /
                  divisionFactor
                )?.toFixed(2),
              }
            : fd
        )
      );
    } else if (["courierMode"].includes(name) && dataToModify.courierMode) {
      let divisionFactor = courierData.find(
        (c) => c._id == value
      )?.divisionFactor;
      dataToModify = {
        ...dataToModify,
        [name]: value,
        volumetricWeight: (
          (dataToModify.volumetric.length *
            dataToModify.volumetric.width *
            dataToModify.volumetric.height) /
          divisionFactor
        )?.toFixed(2),
      };
      return setFormData(
        formData.map((fd, i) =>
          i >= index
            ? {
                ...fd,
                [name]: value,
                volumetricWeight: (
                  (fd.volumetric.length *
                    fd.volumetric.width *
                    fd.volumetric.height) /
                  divisionFactor
                )?.toFixed(2),
              }
            : fd
        )
      );
    }
    setFormData(
      formData.map((fd, i) => (i >= index ? { ...fd, [name]: value } : fd))
    );
  };

  const getPickList = async (gmsPickListcode) => {
    try {
      const { data } = await get(URLS.pickList.list, {
        params: {
          search: {
            gmsPickListCode: {
              $in: gmsPickListcode?.filter(
                (p) => !selectedPickLists.includes(p)
              ),
            },
          },
        },
      });

      if (!data.result?.length) return;

      let newFormData = [];
      const promises = data.result.map(async (pick) => {
        try {
          // Fetch DC data
          const { data: dcData } = await get(URLS.dc.findOne, {
            params: { pickList: pick._id },
          });
          const dcExists = dcData.result || null;

          // Process individual items
          const processItems = (items, bundleReferenceNumber = null) =>
            items.map((item) => {
              const itemDescription = dcExists
                ? itemDescriptions?.find((d) =>
                    dcExists?.itemDetails.find(
                      (id) =>
                        splitByLastDash(id.description)?.before ===
                        d.description
                    )
                  )
                : itemDescriptions.find(
                    (d) => d.description === item.itemDescription
                  );

              return {
                _id: item._id,
                bundleReferenceNumber,
                dcExists: !!dcExists,
                category: item.itemCategory,
                msn: item.msn,
                stockType: stockTypes.find((s) => s._id == item.stockType)
                  ?.name,
                packaging: "",
                courierMode: "",
                actualWeight: itemDescription?.weight || "",
                volumetric: {
                  height: itemDescription?.dimensions?.height || 0,
                  width: itemDescription?.dimensions?.width || 0,
                  length: itemDescription?.dimensions?.length || 0,
                },
                volumetricWeight: "",
                value: dcExists
                  ? dcExists?.itemDetails?.find((d) =>
                      splitByLastDash(d.description).after?.includes(
                        item.itemCategory
                      )
                    )?.unitRate
                  : item.itemCategory === "new"
                  ? itemDescription?.priceNew
                  : itemDescription?.priceUsed,
                dcNo: dcExists?.dcNo || "",
                dcDate: dcExists?.createdAt || null,
                courierPartnerName: "",
                awbNo: "",
                ewayBill: "",
                name: dcExists?.shipTo?.name || "",
                address: dcExists?.shipTo?.address || "",
                contactNumber: dcExists?.shipTo?.contactNumber || "",
                pincode: dcExists?.shipTo?.pinCode || "",
                updateStatus: updateStatus.find((s) => s.isDefault)?._id || "",
                subFamilyName: subFamilies.find((s) => s._id == item.subFamily)
                  ?.name,
                itemDescription: item.itemDescription,
                itemCode: item.itemCode,
                manufacturerPartNumber: item.manufacturerPartNumber,
                brandName: brandData.find((b) => b._id == item.brandName)?.name,
                meta: formFields || [],
              };
            });

          newFormData.push(
            ...processItems(pick.individualItems),
            ...pick.bundledItems.flatMap((bundle) =>
              processItems(bundle.items, bundle.bundleReferenceNumber)
            )
          );
        } catch (error) {
          console.error("Error fetching DC data:", error);
        }
      });

      await Promise.all(promises);

      // Update state after processing all data

      let pickListIds = [];
      for (let pick of gmsPickListcode) {
        pickListIds.push(
          pickLists.find((p) => p.gmsPickListCode === pick)?._id
        );
      }

      setSelectedPickLists(pickListIds);
      setFormData((prev) => [...prev, ...newFormData]);
    } catch (error) {
      console.error("Error fetching pick list:", error);
    }
  };

  const getItem = async (msn) => {
    try {
      const { data } = await get(URLS.items.list, {
        params: { search: { msn, inStock: true, inBundle: false } },
      });
      let item = data.result[0];
      if (item) {
        setInStockItems(inStockItems.filter((i) => i.value !== item.msn));
        if (formData.filter((f) => f._id === item._id).length)
          return toast.info("This item has already been added");

        let itemDescription = itemDescriptions?.find(
          (d) => item.itemDescription == d.description
        );

        let newFormData = {
          _id: item._id,
          bundleReferenceNumber: null,
          dcExists: false,
          category: item.itemCategory,
          msn: item.msn,
          bundleReferenceNumber: null,
          stockType: stockTypes.find((s) => s._id == item.stockType?._id)?.name,
          packaging: "",
          courierMode: "",
          actualWeight: itemDescription?.weight || "",
          volumetric: {
            height: itemDescription?.dimensions?.height || 0,
            width: itemDescription?.dimensions?.width || 0,
            length: itemDescription?.dimensions?.length || 0,
          },
          volumetricWeight: "",
          value:
            item.itemCategory === "new"
              ? itemDescription.priceNew
              : itemDescription.priceUsed,
          dcNo: "",
          dcDate: null,
          courierPartnerName: "",
          awbNo: "",
          ewayBill: "",
          name: "",
          address: "",
          contactNumber: "",
          pincode: "",
          updateStatus: updateStatus.find((s) => s.isDefault)?._id || "",
          subFamilyName: subFamilies.find((s) => s._id == item.subFamily?._id)
            ?.name,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: brandData.find((b) => b._id == item.brandName?._id)?.name,
          meta: formFields || [],
        };

        setFormData((prev) => [...prev, newFormData]);

        formik.setFieldValue("manualMsn", "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBundle = async (bundleReferenceNumber) => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: {
            bundleReferenceNumber: bundleReferenceNumber,
            inStock: true,
          },
        },
      });
      let bundle = data.result[0];

      if (!bundle) return;

      setBundles(
        bundles.filter((b) => b.value !== bundle.bundleReferenceNumber)
      );
      if (formData.filter((f) => f._id === bundle._id).length)
        return toast.info("This bundle has already been added");

      let newFormData = [];

      for (let it of bundle.items) {
        let itemDescription = itemDescriptions?.find(
          (d) => it.itemDescription === d.description
        );
        let newData = {
          _id: it._id,
          bundleReferenceNumber: bundle.bundleReferenceNumber,
          dcExists: false,
          category: it.itemCategory,
          msn: it.msn,
          stockType: stockTypes.find((s) => s._id == it.stockType?._id)?.name,
          packaging: "",
          courierMode: "",
          actualWeight: itemDescription?.weight || "",
          volumetric: {
            height: itemDescription?.dimensions?.height || 0,
            width: itemDescription?.dimensions?.width || 0,
            length: itemDescription?.dimensions?.length || 0,
          },
          volumetricWeight: "",
          value:
            it.itemCategory === "new"
              ? itemDescription.priceNew
              : itemDescription.priceUsed,
          dcNo: "",
          dcDate: null,
          courierPartnerName: "",
          awbNo: "",
          ewayBill: "",
          name: "",
          address: "",
          contactNumber: "",
          pincode: "",
          updateStatus: updateStatus.find((s) => s.isDefault)?._id || "",
          subFamilyName: subFamilies.find((s) => s._id == it.subFamily?._id)
            ?.name,
          itemDescription: it.itemDescription,
          itemCode: it.itemCode,
          manufacturerPartNumber: it.manufacturerPartNumber,
          brandName: brandData.find((b) => b._id == it.brandName?._id)?.name,
          meta: formFields || [],
        };

        newFormData.push(newData);
      }

      setFormData((prev) => [...prev, ...newFormData]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.gmsPickListcode?.length) {
      getPickList(formik.values.gmsPickListcode);
    }
  }, [formik.values.gmsPickListcode]);

  useEffect(() => {
    if (formik.values.manualBundleReferenceNumber?.length) {
      getBundle(formik.values.manualBundleReferenceNumber);
    }
  }, [formik.values.manualBundleReferenceNumber]);

  useEffect(() => {
    if (formik.values.manualMsn) {
      getItem(formik.values.manualMsn);
    }
  }, [formik.values.manualMsn]);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    for (let fd of formData) {
      if (
        !hasAllValues(fd, [
          "meta",
          "volumetric",
          "volumetricWeight",
          "dcExists",
          "bundleReferenceNumber",
        ])
      )
        return;
    }

    if (formData.some((fd) => fd.meta?.some((f) => f.isRequired && !f.value)))
      return toast.error("Please fill required fields of this item");

    let outBounds = [];

    setIsLoading(true);
    for (let fd of formData) {
      if (outBounds.filter((o) => o.awbNo === fd.awbNo).length) {
        let itemToAdd = {
          bundleReferenceNumber: fd.bundleReferenceNumber,
          packaging: fd.packaging,
          courierMode: fd.courierMode,
          actualWeight: fd.actualWeight,
          volumetricSize: fd.volumetric,
          volumetricWeight: fd.volumetricWeight,
          value: fd.value,
          dcNo: fd.dcNo,
          dcDate: dayjs(fd.dcDate).format("YYYY-MM-DD HH:mm:ss"),
          courierPartner: fd.courierPartnerName,
          updateStatus: fd.updateStatus,
          awbNo: fd.awbNo,
          ewayBillNumber: fd.ewayBill,
          name: fd.name,
          address: fd.address,
          contactNumber: fd.contactNumber,
          pincode: fd.pincode,
          meta: fd.meta,
        };

        let weightToAdd =
          Number(fd.volumetricWeight) > Number(fd.actualWeight)
            ? Number(fd.volumetricWeight)
            : Number(fd.actualWeight);

        if (fd.category === "Bundled") {
          itemToAdd["bundledItem"] = fd._id;
        } else {
          itemToAdd["individualItem"] = fd._id;
        }

        outBounds = outBounds.map((o) =>
          o.awbNo === fd.awbNo
            ? {
                ...o,
                totalWeight: o.totalWeight + weightToAdd,
                items: [...o.items, itemToAdd],
              }
            : o
        );
      } else {
        let itemToAdd = {
          bundleReferenceNumber: fd.bundleReferenceNumber,
          packaging: fd.packaging,
          courierMode: fd.courierMode,
          actualWeight: fd.actualWeight,
          volumetricSize: fd.volumetric,
          volumetricWeight: fd.volumetricWeight,
          value: fd.value,
          dcNo: fd.dcNo,
          dcDate: dayjs(fd.dcDate).format("YYYY-MM-DD HH:mm:ss"),
          courierPartner: fd.courierPartnerName,
          updateStatus: fd.updateStatus,
          awbNo: fd.awbNo,
          ewayBillNumber: fd.ewayBill,
          name: fd.name,
          address: fd.address,
          contactNumber: fd.contactNumber,
          pincode: fd.pincode,
          meta: fd.meta,
        };
        if (fd.category === "Bundled") {
          itemToAdd["bundledItem"] = fd._id;
        } else {
          itemToAdd["individualItem"] = fd._id;
        }
        let newOutbound = {
          awbNo: fd.awbNo,
          warehouse: formik.values.warehouse,
          ownerCode: formik.values.ownerCode,
          ownerGroup: formik.values.ownerGroup,
          totalWeight:
            Number(fd.volumetricWeight) > Number(fd.actualWeight)
              ? Number(fd.volumetricWeight)
              : Number(fd.actualWeight),
          items: [itemToAdd],
        };

        outBounds.push(newOutbound);
      }
    }

    try {
      const { data } = await post(URLS.outbound.create, {
        outBounds,
      });
      setIsLoading(false);

      if (selectedPickLists.length) {
        await put(URLS.pickList.updateMultiple, { selectedPickLists });
      }
      setFormData([]);
      getAllPickList();
      getBundles();
      getItems();
      setSelectedPickLists([]);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const debounceSubmit = debounce(handleSubmit, 1000);

  // edit data actions
  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        index >= i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: null,
      toDate: null,
    },
    onSubmit: () => {},
  });

  const handleFind = async () => {
    try {
      setFetchingData(true);
      const values = entryFormik.values;
      let searchFilter = {};
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
      }

      const { data } = await get(URLS.outbound.showList, {
        params: {
          search: searchFilter,
          warehouses: selectedWarehouse,
          ownerGroups: selectedOwnerGroup,
          ownerCode: selectedOwnerCode,
        },
      });

      let entries = data.result.allOutbounds;

      let totalItems = 0;

      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          totalItems += entry.items?.length;
          for (let item of entry.items) {
            if (item.individualItem) {
              let newForm = {
                _id: item.individualItem._id,
                category: item.individualItem.itemCategory,
                msn: item.individualItem.msn,
                bundleReferenceNumber: item.bundleReferenceNumber || "NA",
                stockType: item.individualItem.stockType.name,
                packaging: item.packaging?.title,
                courierMode: item.courierMode?.name,
                actualWeight: item.actualWeight,
                volumetric: { ...item.volumetricSize },
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                dcDate: item.dcDate,
                courierPartnerName: item.courierPartner?.name,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.individualItem.subFamily.name,
                itemDescription: item.individualItem.itemDescription,
                itemCode: item.individualItem.itemCode,
                manufacturerPartNumber:
                  item.individualItem.manufacturerPartNumber,
                brandName: item.individualItem.brandName.name,
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };
              forms.push(newForm);
            } else if (item.bundledItem) {
              let newForm = {
                category: "Bundled",
                _id: item.bundledItem._id,
                items: item.bundledItem.items,
                msn: item.bundledItem.items.map((i) => i.msn).join(","),
                bundleReferenceNumber: item.bundledItem.bundleReferenceNumber,
                stockType: item.bundledItem?.items
                  ?.map((i) => i.stockType.name)
                  ?.join(", "),
                packaging: item.packaging?.title,
                courierMode: item.courierMode?.name,
                actualWeight: item.actualWeight,
                volumetric: { ...item.volumetricSize },
                volumetricWeight: item.volumetricWeight,
                value: item.value,
                dcNo: item.dcNo,
                dcDate: item.dcDate,
                courierPartnerName: item.courierPartner?.name,
                awbNo: item.awbNo,
                ewayBill: item.ewayBillNumber,
                pincode: item.pincode,
                subFamilyName: item.bundledItem?.items
                  ?.map((i) => i.subFamily.name)
                  ?.join(", "),
                itemDescription: item.bundledItem.items
                  .map((i) => i.itemDescription)
                  .join(","),
                itemCode: item.bundledItem.items
                  .map((i) => i.itemCode)
                  .join(","),
                manufacturerPartNumber: item.bundledItem.items
                  .map((i) => i.manufacturerPartNumber)
                  .join(","),
                brandName: item.bundledItem?.items
                  ?.map((i) => i.brandName?.name)
                  ?.join(", "),
                meta: formFields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
              };

              forms.push(newForm);
            }
          }
        }
        setOutbounds(forms);
      } else {
        toast.info("No outbound data found!");
        setOutbounds([]);
        setPage(0);
        setRowsPerPage(10);
      }
      setTotalItems(totalItems);
      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    entryFormik.resetForm();
  };

  useEffect(() => {
    setOutbounds([]);
    setPage(0);
    setRowsPerPage(10);
  }, [
    entryFormik.values,
    selectedOwnerCode,
    selectedWarehouse,
    selectedOwnerGroup,
  ]);

  useEffect(() => {
    if (value == 1) {
      handleFind();
    }
  }, [rowsPerPage, page]);

  return (
    <>
      <DashBoardPageHeaderPage title="Outbound" />
      <PageHeader
        module="Outbound"
        showAdd={false}
        collection="Outbound"
        showAddField={true}
        modelName="outbound"
        onFieldAdd={getFormDetails}
      />

      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "View"]
            : ["Add", "Entries"]
        }
      />

      <TabPanel index={0} value={value}>
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelect
              formik={formik}
              name="warehouse"
              label="Warehouse"
              disabled={warehouseData?.length === 1}
              options={warehouseData}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelect
              formik={formik}
              name="ownerGroup"
              label="Owner Group"
              disabled={ownerData?.length === 1}
              options={ownerData}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormSelect
              type="text"
              formik={formik}
              name="ownerCode"
              label="Owner Code"
              disabled={ownerCodes?.length === 1}
              options={ownerCodes}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <Stack direction={"column"}>
              <FormSelect
                type="text"
                formik={formik}
                name="gmsPickListcode"
                label="GMS Picklist Code"
                required={true}
                options={pickLists}
                multiple={true}
                disableUnselect={true}
                disabled={showingData.includes("gmsPickListCodes")}
              />

              {showingData.includes("gmsPickListCodes") ? (
                <Typography sx={{ mb: 1 }}>
                  Fetching GMS Picklist Code.....
                </Typography>
              ) : null}
            </Stack>
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <Stack direction={"column"}>
              <FormSelect
                formik={formik}
                label="Bundle Reference Number"
                name="manualBundleReferenceNumber"
                options={bundles}
                disabled={showingData.includes("bundles")}
                disableUnselect={true}
                multiple={true}
              />

              {showingData.includes("bundles") ? (
                <Typography sx={{ mb: 1 }}>Fetching Bundles.....</Typography>
              ) : null}
            </Stack>
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <Stack direction={"column"}>
              <FormSelectVirtualized
                options={inStockItems}
                formik={formik}
                label="Item MSN"
                name="manualMsn"
                disabled={showingData.includes("items")}
              />
              {showingData.includes("items") ? (
                <Typography sx={{ mb: 1 }}>Fetching Items.....</Typography>
              ) : null}
            </Stack>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
            item
          >
            <Button
              sx={{}}
              variant="contained"
              size="small"
              onClick={refreshList}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ maxWidth: "80px", minWidth: "80px" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Bundle Reference No.
                </StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">Courier Mode</StyledTableCell>
                <StyledTableCell align="center">Actual Weight</StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric( LXBXH)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric Weight
                </StyledTableCell>
                <StyledTableCell align="center">Value</StyledTableCell>
                <StyledTableCell align="center">Dc No.</StyledTableCell>
                <StyledTableCell align="center">Dc Date</StyledTableCell>
                <StyledTableCell align="center">
                  Courier Partner Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Courier AWB No.
                </StyledTableCell>
                <StyledTableCell align="center">E Way Bill</StyledTableCell>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Address</StyledTableCell>
                <StyledTableCell align="center">Pincode</StyledTableCell>
                <StyledTableCell align="center">Contact Number</StyledTableCell>
                <StyledTableCell align="center">Update Status</StyledTableCell>
                {formFields?.map((d, i) => (
                  <StyledTableCell key={i} align="center">
                    {d?.inputLabel}
                  </StyledTableCell>
                ))}

                <StyledTableCell align="center">
                  Sub Family Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item Description
                </StyledTableCell>
                <StyledTableCell align="center">Item Code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer Part No.
                </StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>

                {/* <StyledTableCell
                  sx={{ maxWidth: "100px", minWidth: "100px" }}
                  align="center"
                >
                  Remove
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {formData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fd, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ maxWidth: "80px", minWidth: "80px" }}
                      align="center"
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.category?.toUpperCase()}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.msn?.toUpperCase()}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.bundleReferenceNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.stockType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="packaging"
                        value={fd.packaging}
                        label="Packaging"
                        options={packagingData}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="courierMode"
                        options={courierData}
                        value={fd.courierMode}
                        label="Courier Mode"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="actualWeight"
                        value={fd.actualWeight}
                        label="Actual Weight"
                        disabled={fd.dcExists}
                        required={true}
                        type="number"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Stack columnGap={1} direction="row">
                        <CustomInput
                          disabled={fd.dcExists && fd.category !== "Bundled"}
                          name="length"
                          value={fd.volumetric?.length}
                          label="L"
                          onChange={(e) => handleChange(e, index)}
                        />
                        <CustomInput
                          disabled={fd.dcExists && fd.category !== "Bundled"}
                          name="width"
                          value={fd.volumetric?.width}
                          label="B"
                          onChange={(e) => handleChange(e, index)}
                        />
                        <CustomInput
                          disabled={fd.dcExists && fd.category !== "Bundled"}
                          name="height"
                          value={fd.volumetric?.height}
                          label="H"
                          onChange={(e) => handleChange(e, index)}
                        />
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="volumetricWeight"
                        value={fd.volumetricWeight}
                        label="Volumetric Weight"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="value"
                        value={fd.value}
                        label="Value"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="dcNo"
                        value={fd.dcNo}
                        label="Dc No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={fd.dcExists}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "8px",
                            },
                            marginTop: "16px",
                            width: "100%",
                          }}
                          name="dcDate"
                          variant="outlined"
                          size="small"
                          label="DC Date"
                          slotProps={{
                            textField: {
                              InputLabelProps: { fontSize: "90px" },
                            },
                          }}
                          views={["year", "month", "day"]}
                          format="DD-MM-YYYY"
                          value={fd.dcDate ? dayjs(fd.dcDate) : null}
                          onChange={(e) => {
                            const updatedValue = e
                              ? e
                                  .hour(dayjs().hour())
                                  .minute(dayjs().minute())
                                  .second(dayjs().second())
                              : null;

                            handleChange(
                              {
                                target: { name: "dcDate", value: updatedValue },
                              },
                              index
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="courierPartnerName"
                        options={courierPartner}
                        value={fd.courierPartnerName}
                        label="Courier Partner Name"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="awbNo"
                        value={fd.awbNo}
                        label="Courier AWB No."
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="ewayBill"
                        value={fd.ewayBill}
                        label="E Way Bill"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="name"
                        value={fd.name}
                        label="Name"
                        required={true}
                        type="text"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="address"
                        value={fd.address}
                        label="Address"
                        required={true}
                        type="text"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="pincode"
                        value={fd.pincode}
                        label="Pincode"
                        required={true}
                        type="text"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        disabled={fd.dcExists}
                        name="contactNumber"
                        value={fd.contactNumber}
                        label="Contact Number"
                        required={true}
                        type="text"
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="updateStatus"
                        options={updateStatus}
                        value={fd.updateStatus}
                        label="Update Status"
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                        disabled={true}
                      />
                    </StyledTableCell>
                    {formFields
                      ? fd?.meta?.map((f, i) => (
                          <StyledTableCell align="center" key={i}>
                            <CustomInput
                              onChange={(e) =>
                                handleCustomInputChangeItem(e, index)
                              }
                              name={f.inputLabel}
                              value={f.value}
                              required={f.isRequired}
                              label={f.inputLabel}
                              type={f.inputType}
                            />
                          </StyledTableCell>
                        ))
                      : null}

                    <StyledTableCell align="center">
                      <Typography>{fd.subFamilyName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemDescription}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemCode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.manufacturerPartNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.brandName}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={formData ? (formData.length ? formData.length : 0) : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginBottom: "40px" }}
        />

        {formData.length ? (
          <Grid container>
            <Grid item xs={12} md={12}>
              <StyledBox
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "rgba(32,33,32,1)"
                      : theme.palette.grey[100],
                }}
              >
                <LoadingButton
                  loading={isLoading}
                  onClick={debounceSubmit}
                  size="small"
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </StyledBox>
            </Grid>
          </Grid>
        ) : null}
      </TabPanel>

      <TabPanel index={1} value={value}>
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={3} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="name"
              label="AWB No"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={3} item>
            <FormDatePicker
              formik={entryFormik}
              label="From Date"
              name="fromDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <FormDatePicker
              formik={entryFormik}
              label="To Date"
              name="toDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3} item>
            <Stack columnGap={1} direction="row">
              <LoadingButton
                size="small"
                onClick={handleFind}
                variant="contained"
                sx={{ mt: 2 }}
                loading={fetchingData}
              >
                Find
              </LoadingButton>
              {entryFormik.values.name ? (
                <Button
                  size="small"
                  onClick={handleClearFilter}
                  variant="outlined"
                  sx={{ mt: 2 }}
                >
                  Clear Filter
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "20px", opacity: fetchingData ? 0.5 : 1 }}
        >
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ maxWidth: "80px", minWidth: "80px" }}
                  align="center"
                >
                  S.No
                </TableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Bundle Reference No.
                </StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">Courier Mode</StyledTableCell>
                <StyledTableCell align="center">Actual Weight</StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric( LXBXH)
                </StyledTableCell>
                <StyledTableCell align="center">
                  Volumetric Weight
                </StyledTableCell>
                <StyledTableCell align="center">Value</StyledTableCell>
                <StyledTableCell align="center">Dc No.</StyledTableCell>
                <StyledTableCell align="center">Dc Date</StyledTableCell>

                <StyledTableCell align="center">
                  Courier Partner Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Courier AWB No.
                </StyledTableCell>
                <StyledTableCell align="center">E Way Bill</StyledTableCell>
                <StyledTableCell align="center">Pincode</StyledTableCell>

                {formFields?.map((d, i) => (
                  <StyledTableCell key={i} align="center">
                    {d?.inputLabel}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">
                  Sub Family Name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item Description
                </StyledTableCell>
                <StyledTableCell align="center">Item Code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer Part No.
                </StyledTableCell>
                <StyledTableCell align="center">Brand Name</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outbounds
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fd, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ maxWidth: "80px", minWidth: "80px" }}
                      align="center"
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.msn}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.bundleReferenceNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.stockType}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.packaging}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.courierMode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.actualWeight}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        {fd.volumetric.length *
                          fd.volumetric.width *
                          fd.volumetric.height}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.volumetricWeight}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.value}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.dcNo}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        {fd.dcDate
                          ? new Date(fd.dcDate).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            })
                          : ""}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.courierPartnerName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.awbNo}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.ewayBill}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.pincode}</Typography>
                    </StyledTableCell>

                    {formFields
                      ? fd?.meta?.map((f, i) => (
                          <StyledTableCell align="center" key={i}>
                            <Typography>{f.value}</Typography>
                          </StyledTableCell>
                        ))
                      : null}
                    <StyledTableCell align="center">
                      <Typography>{fd.category}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.subFamilyName}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemDescription}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.itemCode}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.manufacturerPartNumber}</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>{fd.brandName}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TabPanel>
    </>
  );
}
