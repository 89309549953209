import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  downloadExcel,
  hasAllValues,
  ROWS_PER_PAGE,
} from "../../utils/utilities";

import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import dayjs from "dayjs";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomInput from "../../components/SecondaryForms/CustomInput";
import { del, get, post, put } from "../../services/apiMethods";

import URLS from "../../services/urlConstants";
import UploadFiles from "../../forms/UploadFiles";
import { LoadingButton } from "@mui/lab";
import { PuffLoader } from "react-spinners";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function UpdatePickList({ dataToEdit, setSelectValue }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [ownerData, setOwnerData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = useState(false);
  const handleModalClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsPickListCode: dataToEdit.gmsPickListCode || "",
      pickListDate: dataToEdit
        ? dayjs(dataToEdit.pickListDate)
        : dayjs(new Date()),
      warehouseCode: dataToEdit ? dataToEdit.warehouseCode?._id : "",
      warehouseName: dataToEdit ? dataToEdit.warehouseCode?.name : "",
      bundle: dataToEdit?.bundle || "",
      sku: dataToEdit?.sku || "",
      ownerGroup: dataToEdit ? dataToEdit.ownerGroup._id : "",
      ownerCode: dataToEdit ? dataToEdit.ownerCode._id : "",
      ownerCategory: dataToEdit ? dataToEdit.ownerCategory : "",
      remarks: dataToEdit ? dataToEdit.remarks : "NA",
    },
    // onSubmit: handleSubmit,
    enableReinitialize: true,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (dataToEdit && dataToEdit.meta.length) {
      setFormFields(
        dataToEdit.meta.map((m) => ({
          inputLabel: m.inputLabel,
          value: m.value,
        }))
      );
    }
  }, [dataToEdit, formFields]);

  useEffect(() => {
    if (dataToEdit) {
      let newFormData = [];
      if (dataToEdit?.individualItems?.length) {
        for (let item of dataToEdit.individualItems) {
          let formData = {
            _id: item._id,
            category: item.itemCategory,
            used: dataToEdit.used,
            stockType: stockTypes.find((s) => s._id == item.stockType)?.name,
            msn: item.msn,
            bundleReferenceNumber: "",
            subFamilyName: subFamilies.find((s) => s._id == item.subFamily)
              ?.name,
            itemDescription: item.itemDescription,
            itemCode: item.itemCode,
            manufacturerPartNumber: item.manufacturerPartNumber,
            brandName: brandData.find((s) => s._id == item.brandName)?.name,
            storageCode: storageCodes.find((s) => s._id == item.storageCode)
              ?.code,
            palletCode: palletCodes.find((s) => s._id == item.palletCode)?.code,
          };
          newFormData.push(formData);
        }
      }

      if (dataToEdit?.bundledItems?.length) {
        for (let item of dataToEdit.bundledItems) {
          let formData = {
            used: dataToEdit.used,
            category: "Bundled",
            _id: item._id,
            stockType: item.items
              .map((i) => stockTypes.find((s) => s._id == i.stockType)?.name)
              .join(","),
            msn: item.items.map((i) => i.msn).join(","),
            bundleReferenceNumber: item.bundleReferenceNumber,
            subFamilyName: item.items
              .map((i) => subFamilies.find((s) => s._id == i.subFamily)?.name)
              .join(","),
            itemDescription: item.items.map((i) => i.itemDescription).join(","),
            itemCode: item.items.map((i) => i.itemCode).join(","),
            manufacturerPartNumber: item.items.map(
              (i) => i.manufacturerPartNumber
            ),
            brandName: item.items
              .map((i) => brandData.find((s) => s._id == i.brandName)?.name)
              .join(","),
            storageCode: item.items
              .map(
                (i) => storageCodes.find((s) => s._id == i.storageCode)?.code
              )
              .join(","),
            palletCode: item.items
              .map((i) => palletCodes.find((s) => s._id == i.palletCode)?.code)
              .join(","),
          };
          newFormData.push(formData);
        }
      }

      setFormData(newFormData);
    }
  }, [
    dataToEdit,
    stockTypes,
    subFamilies,
    brandData,
    storageCodes,
    palletCodes,
  ]);

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "pickList",
          warehouse: entryFormik.values.warehouseCode,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // ownerData
  //warehouseData
  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });

      const ownerDataPromise = get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const [
        stockTypeData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        brandData,
        ownerData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        brandDataPromise,
        ownerDataPromise,
      ]);

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          ...l,
          label: l.name,
          value: l._id,
        }))
      );
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setBrandData(
        brandData?.data?.result?.map((b) => ({
          ...b,
          label: b.name,
          value: b._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });

      setOwnerCodes(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getData();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      getOwnerCodes();
    } else {
    }
  }, [entryFormik.values.ownerGroup]);

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getFormDetails();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (dataToEdit) {
      getWarehouseDetails();
      getData();
    }
  }, [dataToEdit]);

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, i) => (i == index ? { ...f, [name]: value } : f))
    );

    if (name === "msn") {
      try {
        const { data } = await get(URLS.items.list, {
          params: {
            search: {
              inStock: true,
              inBundle: false,
              msn: value,
            },
          },
        });
        // console.log(data.result);
        if (data.result.length) {
          let item = data.result[0];
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: item._id,
                    category: item.itemCategory.name,
                    stockType: item.stockType.name,
                    msn: item.msn,
                    bundleReferenceNumber: "",
                    subFamilyName: item.subFamily.name,
                    itemDescription: item.itemDescription,
                    itemCode: item.itemCode,
                    manufacturerPartNumber: item.manufacturerPartNumber,
                    brandName: item.brandName.name,
                    storageCode: item.storageCode.code,
                    palletCode: item.palletCode.code,
                  }
                : f
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    } else if (name === "bundleReferenceNumber" && value) {
      try {
        const { data } = await get(URLS.bundle.list, {
          params: { search: { bundleReferenceNumber: value } },
        });
        if (data.result.length) {
          let bundle = data.result[0];
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: bundle._id,
                    stockType: bundle.stockType.name,
                    msn: bundle.items.map((i) => i.msn).join(","),
                    bundleReferenceNumber: bundle.bundleReferenceNumber,
                    subFamilyName: bundle.items
                      .map((i) => i.subFamily.name)
                      .join(","),
                    itemDescription: bundle.items
                      .map((i) => i.itemDescription)
                      .join(","),
                    itemCode: bundle.items.map((i) => i.itemCode).join(","),
                    manufacturerPartNumber: bundle.items
                      .map((i) => i.manufacturerPartNumber)
                      .join(","),
                    brandName: bundle.items
                      .map((i) => i.brandName.name)
                      .join(","),
                    storageCode: bundle.storageCode.code,
                    palletCode: bundle.palletCode.code,
                  }
                : f
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const downloadPickList = async () => {
    setDownloading(true);
    try {
      const { data } = await get(
        URLS.pickList.donwloadPickList + "/" + dataToEdit._id,
        { responseType: "blob" }
      );

      downloadExcel(data, `PickList-${dataToEdit.gmsPickListCode}`);
      setDownloading(false);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = {};

    if (data.category !== "Bundled") {
      payload["individualItem"] = data._id;
    } else {
      payload["bundledItem"] = data._id;
    }

    try {
      const res = await put(
        URLS.pickList.removeItem + "/" + dataToEdit._id,
        payload
      );
      const filtered = formData.filter((item) => item._id !== data._id);
      if (!filtered.length) {
        setSelectValue(1);
      }
      setFormData(filtered);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <Box component="form">
            <Grid rowSpacing={1} columnSpacing={2} container>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="gmsPickListCode"
                  label="Gms Picklist code"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormDatePicker
                  formik={entryFormik}
                  label="Pick List Date"
                  name="pickListDate"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  formik={entryFormik}
                  sx={{ "& .MuiSelect-icon": { display: "none" } }}
                  disabled={true}
                  label="Warehouse code"
                  name="warehouseCode"
                  options={warehouseData.map((warehouse) => ({
                    label: warehouse.code,
                    value: warehouse._id,
                  }))}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedWarehouse = warehouseData.find(
                      (warehouse) => warehouse._id === selectedValue
                    );
                    if (selectedWarehouse) {
                      entryFormik.setFieldValue(
                        "warehouseCode",
                        selectedWarehouse._id
                      );
                      entryFormik.setFieldValue(
                        "warehouseName",
                        selectedWarehouse.name
                      );
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="warehouseName"
                  label="Warehouse name"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="bundle"
                  label="Bundle"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="sku"
                  label="SKU"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  formik={entryFormik}
                  name="ownerGroup"
                  disabled={true}
                  sx={{ "& .MuiSelect-icon": { display: "none" } }}
                  label="Owner Group"
                  options={ownerData}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  type="text"
                  sx={{ "& .MuiSelect-icon": { display: "none" } }}
                  formik={entryFormik}
                  name="ownerCode"
                  disabled={true}
                  label="Owner code"
                  options={ownerCodes}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="ownerCategory"
                  label="Owner category"
                  disabled={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="remarks"
                  label="Pick List Remarks"
                  disabled={true}
                />
              </Grid>

              {formFields
                ? formFields?.map((f, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4}>
                      <CustomInput
                        disabled={true}
                        onChange={handleCustomInputChange}
                        name={f.inputLabel}
                        value={f.value}
                        required={f.isRequired}
                        label={f.inputLabel}
                        type={f.inputType}
                      />
                    </Grid>
                  ))
                : null}
              <Grid xs={12} sm={6} md={4} item>
                <Button
                  sx={{ mt: 2.5 }}
                  size="small"
                  variant="contained"
                  onClick={() => setOpen(true)}
                  startIcon={<RemoveRedEyeRoundedIcon />}
                >
                  Images
                </Button>
              </Grid>
            </Grid>
          </Box>
          <UploadFiles
            actions={[]}
            onClose={handleModalClose}
            open={open}
            filesList={dataToEdit.images || []}
          />

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              mt: 2,
            }}
          >
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setSelectValue(1)}
                >
                  Back
                </Button>
                {formData.length ? (
                  <TablePagination
                    fullWidth
                    component="div"
                    count={
                      formData ? (formData.length ? formData.length : 0) : 0
                    }
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    S.No
                  </TableCell>
                  <StyledTableCell align="center">Category</StyledTableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>

                  <StyledTableCell align="center">
                    Bundle code refrence no
                  </StyledTableCell>
                  <StyledTableCell align="center">Stock Type</StyledTableCell>

                  <StyledTableCell align="center">
                    Sub-family name
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Item description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item code</StyledTableCell>
                  <StyledTableCell align="center">
                    Manufacturer part number
                  </StyledTableCell>
                  <StyledTableCell align="center">Brand name</StyledTableCell>
                  <StyledTableCell align="center">Storage code</StyledTableCell>
                  <StyledTableCell align="center">Pallet code</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((v, i) => (
                    <TableRow key={i}>
                      <TableCell align="center" sx={{ minWidth: "80px" }}>
                        {page * rowsPerPage + 1 + i}
                      </TableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.category?.toUpperCase()} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CustomInput
                          // disabled={v.category && v.category === "Bundled"}
                          disabled={true}
                          name="msn"
                          value={v.msn}
                          label="MSN"
                          onChange={(e) => handleChange(e, i)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <CustomInput
                          // disabled={v.category !== "Bundled"}
                          disabled={true}
                          name="bundleReferenceNumber"
                          value={v.bundleReferenceNumber}
                          label="Bundle code refrence no"
                          onChange={(e) => handleChange(e, i)}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.stockType} </Typography>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Typography>{v.subFamilyName} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.itemDescription} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.itemCode} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.manufacturerPartNumber} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.brandName} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.storageCode} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography>{v.palletCode} </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          disabled={v.used}
                          onClick={() => handleUpdate(v._id, v)}
                        >
                          <DeleteIcon
                            color={v.used ? "disabled" : "error"}
                            fontSize="small"
                          />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
