// import React from "react";
// import TextField from "@mui/material/TextField";
// import dayjs from "dayjs";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// function FormDatePicker({ name, label, formik, disabled = false }) {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <DatePicker
//         sx={{
//           "& .MuiInputBase-input": {
//             // Target the input element
//             height: "8px", // Adjust the height as needed
//           },
//           marginTop: "16px",
//           width: "100%",
//         }}
//         // disablePast={true}
//         name={name}
//         variant="outlined"
//         size="small"
//         label={label}
//         disabled={disabled}
//         slotProps={{ textField: { InputLabelProps: { fontSize: "90px" } } }}
//         openTo="day"
//         closeOnSelect
//         views={["year", "month", "day"]}
//         inputFormat="DD-MM-YYYY"
//         disableFuture
//         value={dayjs(formik.values[name])}
//         onChange={(value) => formik.setFieldValue(name, value)}
//       />
//     </LocalizationProvider>
//   );
// }

// export default FormDatePicker;

import React from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function FormDatePicker({ name, label, formik, disabled = false }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{
          "& .MuiInputBase-input": {
            height: "8px", // Adjust the height as needed
          },
          marginTop: "16px",
          width: "100%",
        }}
        name={name}
        variant="outlined"
        size="small"
        label={label}
        disabled={disabled}
        slotProps={{ textField: { InputLabelProps: { fontSize: "90px" } } }}
        openTo="day"
        closeOnSelect
        views={["year", "month", "day"]}
        inputFormat="DD-MM-YYYY"
        disableFuture
        value={dayjs(formik.values[name])}
        onChange={(value) => {
          // Add current time to the selected date
          const updatedValue = value
            ? value
                .hour(dayjs().hour())
                .minute(dayjs().minute())
                .second(dayjs().second())
            : null;

          formik.setFieldValue(name, updatedValue);
        }}
      />
    </LocalizationProvider>
  );
}

export default FormDatePicker;
