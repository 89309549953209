import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Divider,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import { downloadExcel } from "../utils/utilities";
import ShowComponent from "../components/ShowComponent";
import WarehouseContext from "../context/WarehouseContext";
import { Search } from "@mui/icons-material";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";

export default function StorageReport() {
  const [itemKeys, setItemKeys] = useState([]);
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [loading, setLoading] = useState(false);
  const [pufferLoading, setPufferLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [searchFilter, setSearchFilter] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const handleToggle = (key) => {
    const updatedItemKeys = itemKeys.map((i) =>
      i.key === key ? { ...i, checked: !i.checked } : i
    );
    setItemKeys(updatedItemKeys);
    if (searchFilter.length) {
      setSearchFilter(
        searchFilter.map((i) =>
          i.key === key ? { ...i, checked: !i.checked } : i
        )
      );
    }

    const allChecked = updatedItemKeys.every((i) => i.checked);
    setCheckAll(allChecked);
  };

  const getItemKeys = async () => {
    try {
      setPufferLoading(true);
      const { data } = await get(URLS.items.getKeys, {
        params: {
          warehouses: selectedWarehouse,
        },
      });
      const itemKeys = data.result.itemKeys;
      const inboundKeys = data.result.inboundKeys;

      setItemKeys([...itemKeys, ...inboundKeys]);
      setPufferLoading(false);
    } catch (error) {
      setPufferLoading(false);
    }
  };

  useEffect(() => {
    getItemKeys();
  }, [selectedWarehouse]);

  const handleDownloadReport = async () => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setLoading(true);
      const { data } = await get(URLS.items.downloadStorageReport, {
        params: {
          ...itemKeys,
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Storage_Report");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCheckChange = () => {
    setCheckAll((prev) => {
      const newCheckedState = !prev;
      setItemKeys(
        itemKeys.map((item) => ({
          ...item,
          checked: newCheckedState,
        }))
      );
      return newCheckedState;
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() !== "") {
      if (itemKeys.length > 0) {
        setSearchFilter(
          itemKeys.filter((ele) =>
            ele.label.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
      }
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <>
      {pufferLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <Box>
          {itemKeys.length ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={2}>
                {" "}
                <ShowComponent module={"Reports"} action={"view"}>
                  {" "}
                  <LoadingButton
                    onClick={handleDownloadReport}
                    loading={loading}
                    variant="contained"
                    size="small"
                  >
                    Download Excel
                  </LoadingButton>
                </ShowComponent>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ShowComponent module={"Reports"} action={"update"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAll}
                        onChange={handleCheckChange}
                        size="small"
                        sx={{ color: "000" }}
                      />
                    }
                    label={checkAll ? "Uncheck All" : "Check All"}
                  />
                </ShowComponent>
                <TextField
                  size="small"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search here..."
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" type="submit">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
          <Divider sx={{ my: 2 }} />
          {search && !searchFilter.length && (
            <Typography sx={{ mt: 2, textAlign: "center" }}>
              No results for "{search}"
            </Typography>
          )}
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Grid
              container
              sx={{
                overflowY: "auto",
                height: "65vh",
                scrollbarWidth: "none",
              }}
            >
              {(search ? searchFilter : itemKeys).map((value, index) => {
                const labelId = `checkbox-list-label-${value.key}`;

                return (
                  <Grid key={value.key + index.toString()} item xs={12} sm={4}>
                    <ListItem disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleToggle(value.key)}
                      >
                        <Checkbox
                          edge="start"
                          checked={value.checked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        <ListItemText id={labelId} primary={value.label} />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </List>
        </Box>
      )}
    </>
  );
}
