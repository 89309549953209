/** @format */

import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";

export default function CustomSearch({
  data = [],
  setSearch,
  setSearchFilter,
  search,
  keys = [],
  placeholder = "here",
}) {
  const theme = useTheme();
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);

    if (value.trim() !== "") {
      const searchTerm = value.toLowerCase();

      const filteredData = data.filter((item) =>
        keys.some((key) => {
          const keyValue = key
            .split(".")
            .reduce((acc, curr) => acc && acc[curr], item);
          return keyValue
            ? keyValue.toString().toLowerCase().includes(searchTerm)
            : false;
        })
      );

      setSearchFilter(filteredData);
    } else {
      setSearchFilter([]);
    }
  };

  return (
    <TextField
      size="small"
      fullWidth
      value={search}
      onChange={handleSearch}
      placeholder={`Search ${placeholder}`}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" type="submit">
              <Search />
            </IconButton>
          </InputAdornment>
        ),

        style: {
          borderWidth: 1,
          minHeight: "38px",
          borderRadius: theme.shape.borderRadius,
        },
      }}
    />
  );
}
