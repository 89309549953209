import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import FormInput from "../forms/FormInput";
import { useFormik } from "formik";
import {
  Done,
  Edit,
  Email,
  Person,
  Phone,
  Place,
  Save,
  Badge,
} from "@mui/icons-material";
import UserContext from "../context/UserContext";
import CustomInput from "../components/SecondaryForms/CustomInput";
import { get, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { jwtDecode } from "jwt-decode";

const MuiBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  alignSelf: "center",
});

export default function Profile() {
  const { user, getCurrentUser } = useContext(UserContext);

  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [editedEmail, setEditedEmail] = useState(user.email);
  const [editedPhone, setEditedPhone] = useState(user.contactNumber);
  const [userType, setUserType] = useState("employee");

  useEffect(() => {
    const decodedToken = jwtDecode(window.localStorage.getItem("access_token"));
    setUserType(decodedToken.data.userType);
  }, [user]);

  const handleEditEmail = () => {
    setIsEditingEmail(true);
  };

  const handleSaveEmail = async () => {
    try {
      const { data } = await put(URLS.auth.update, { email: editedEmail });
      getCurrentUser();
    } catch (error) {
      console.log(error);
    }
    setIsEditingEmail(false);
  };

  const handleEditPhone = () => {
    setIsEditingPhone(true);
  };

  const handleSavePhone = async () => {
    try {
      const { data } = await put(URLS.auth.update, {
        contactNumber: editedPhone,
      });
      getCurrentUser();
    } catch (error) {
      console.log(error);
    }
    setIsEditingPhone(false);
  };

  return (
    <>
      <MuiBox>
        <Paper
          component="form"
          sx={{
            width: "100%",
            p: 3,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ width: 100, height: 100 }}
              src="your-avatar-image-url.jpg"
            />
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: 600,
                mt: 2,
              }}
            >
              {user.username || user.userName}
            </Typography>
          </Box>
          <Stack spacing={2} direction="row" alignItems="center" my={3}>
            <Typography noWrap>Personal Details</Typography>
          </Stack>
          {user.role != "admin" && (
            <>
              <Stack spacing={2} direction="row" alignItems="center" my={3}>
                <Person fontSize="small" />
                <Typography noWrap>{user.name || "NA"}</Typography>
              </Stack>
            </>
          )}

          {/* <Stack spacing={2} direction="row" alignItems="center" my={3}>
            <Email />
            <Typography noWrap>{user.email}</Typography>
          </Stack> */}
          <Stack spacing={2} direction="row" alignItems="center" my={3}>
            <Email fontSize="small" />
            {isEditingEmail ? (
              <CustomInput
                type="text"
                name="email"
                label="Update email"
                value={editedEmail}
                onChange={(e) => setEditedEmail(e.target.value)}
              />
            ) : (
              <Typography noWrap>{user.email || "NA"}</Typography>
            )}
            <>
              {!isEditingEmail && (
                <Tooltip title="Update">
                  <IconButton
                    size="small"
                    onClick={handleEditEmail}
                    // style={{ marginLeft: "160px" }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {isEditingEmail && (
                <Tooltip title="Done">
                  <IconButton
                    size="small"
                    color="success"
                    onClick={handleSaveEmail}
                  >
                    <Done fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" my={3}>
            <Phone fontSize="small" />
            {isEditingPhone ? (
              <CustomInput
                type="text"
                name="contactNumber"
                label="Update contact no."
                value={editedPhone}
                onChange={(e) => setEditedPhone(e.target.value)}
              />
            ) : (
              <Typography noWrap>{user.contactNumber || "NA"}</Typography>
            )}
            <>
              {!isEditingPhone && (
                <Tooltip title="Update">
                  <IconButton
                    size="small"
                    onClick={handleEditPhone}
                    // style={{ marginLeft: "175px" }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {isEditingPhone && (
                <Tooltip title="Done">
                  <IconButton
                    size="small"
                    color="success"
                    onClick={handleSavePhone}
                  >
                    <Done fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" my={3}>
            <Badge fontSize="small" />
            <Typography noWrap>
              {typeof user.role === "object" ? user.role.name : user.role}
            </Typography>
          </Stack>
          {user.role != "admin" && (
            <>
              <Stack spacing={2} direction="row" alignItems="center" my={3}>
                <Place fontSize="small" />
                <Typography noWrap>{user.address || "NA"}</Typography>
              </Stack>
            </>
          )}
        </Paper>
      </MuiBox>
    </>
  );
}
