import React from "react";
import CustomSelect from "./CustomSelect";
import { Grid } from "@mui/material";
import URLS from "../../services/urlConstants";
import { get } from "../../services/apiMethods";
import CustomSearch from "../Tables/CustomSearch";

export default function CustomWarehouseFilter({
  selectedWarehouse,
  setSelectedWarehouse = () => {},
  data,
  setSearch = [],
  setSearchFilter,
  search = "",
  placeholder = "",
  keys = [],
  showSearch,
}) {
  const [warehouses, setWarehouses] = React.useState([]);

  const getWarehouseList = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);

      setWarehouses(
        data.result.map((r) => ({
          ...r,
          // label: `${r.name} - ${r.code}`,

          label: r.code,
          value: r._id,
        }))
      );
      setSelectedWarehouse(data.result[0]?._id || "");
    } catch (error) {}
  };

  React.useEffect(() => {
    getWarehouseList();
  }, []);

  const handleChangeWarehouse = (e) => {
    setSelectedWarehouse(e.target.value);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelect
            required={true}
            name="warehouse"
            onChange={handleChangeWarehouse}
            label="Select Warehouse"
            options={warehouses}
            value={selectedWarehouse}
          />
        </Grid>

        {showSearch && (
          <Grid item xs={12} md={6} lg={3} marginTop={2}>
            <CustomSearch
              data={data}
              setSearch={setSearch}
              setSearchFilter={setSearchFilter}
              search={search}
              placeholder={placeholder}
              keys={keys}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
