import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// custom imports
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FormInput from "../../forms/FormInput";
import FormSelect from "../../forms/FormSelect";
import CustomTable from "../../components/Tables/CustomTable";
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";

// services
import { get, put, post, del } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// data
import { storageCodeTableKeys } from "../../data/TableData/StorageCode";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import WarehouseContext from "../../context/WarehouseContext";
import CustomSearch from "../../components/Tables/CustomSearch";

export default function StorageCode() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");

  const [readOnly, setReadOnly] = useState(false);

  const { allWarehouses } = useContext(WarehouseContext);

  useEffect(() => {
    setWarehouses(
      allWarehouses?.map((s) => ({
        ...s,
        label: s.code,
        value: s._id,
      })) || []
    );
    setSelectedWarehouse(allWarehouses[0]?._id || "");
  }, [allWarehouses]);

  const formik = useFormik({
    initialValues: {
      code: dataToEdit?.code || "",
      description: dataToEdit?.description || "",
      warehouse: selectedWarehouse || "",
    },
    onSubmit: (values) => handleCreateOrUpdate(values),
    enableReinitialize: true,
  });

  const getData = async () => {
    setIsReady(false);
    try {
      const { data } = await get(URLS.storageCode.list, {
        params: { search: { warehouse: selectedWarehouse } },
      });

      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: "storageCode", warehouse: selectedWarehouse },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    if (selectedWarehouse) {
      getData();
      getFormDetails();
    } else {
      setIsReady(true);
    }
  }, [selectedWarehouse]);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  const openStorageCodeModal = () => {
    setOpen(true);
  };

  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };
      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.storageCode.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.storageCode.create, payload);
      }
      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedWarehouse) {
      getData();
    }
  }, [selectedWarehouse]);

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  const handleSetDataToEdit = (data) => {
    setReadOnly(false);
    setDataToEdit(data);

    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleShowData = (data) => {
    setReadOnly(true);
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await del(URLS.storageCode.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeWarehouse = (e) => {
    setSelectedWarehouse(e.target.value);
    formik.setFieldValue("warehouse", e.target.value);
  };

  return (
    <>
      <PageHeader
        collection="Storage Code"
        onAddClick={openStorageCodeModal}
        title="Storage Code"
        modelName="storageCode"
        onFieldAdd={handleOnFieldAdd}
        module="Storage code"
        addButtonDisabled={!selectedWarehouse}
      />
      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <CustomSelect
            required={true}
            name="warehouse"
            onChange={handleChangeWarehouse}
            label="Select Warehouse"
            options={warehouses}
            value={selectedWarehouse}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} marginTop={2}>
          <CustomSearch
            data={data}
            setSearch={setSearch}
            setSearchFilter={setSearchFilter}
            search={search}
            placeholder="Code"
            keys={["code"]}
          />
        </Grid>
      </Grid>
      <CustomTable
        actions={["edit", "delete", "view"]}
        bodyDataModal="storage code"
        isDataReady={isReady}
        bodyData={search ? searchFilter : data}
        tableKeys={storageCodeTableKeys}
        onDeleteClick={handleDelete}
        onEditClick={handleSetDataToEdit}
        onViewClick={handleShowData}
        module="Storage code"
        formFields={formFields}
      />

      <FormDialog
        readOnly={readOnly}
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        adding={loading}
        formTitle={dataToEdit ? "Update Storage Code" : "Add Storage Code"}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="code"
              type="text"
              formik={formik}
              label="Code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="warehouse"
              formik={formik}
              label="Select warehouse"
              options={warehouses}
            />
          </Grid> */}

          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
