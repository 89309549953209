import { jwtDecode } from "jwt-decode";
import React from "react";

export default function ShowComponent({
  module,
  action,
  CustomComponent,
  children,
}) {
  let decodedToken = jwtDecode(window.localStorage.getItem("access_token"));

  if (decodedToken.data && decodedToken.data.userType === "master")
    return children;

  let role = window.localStorage.getItem("role");

  if (!role) return null;

  if (role !== "undefined") {
    let extractedRole = JSON.parse(role);

    let permissions = extractedRole.permissions;

    if (!permissions.find((p) => p.module === module))
      return CustomComponent || null;

    let canShowComponent = permissions
      .find((p) => p.module?.toLowerCase() === module?.toLowerCase())
      .permissions.includes(action)
      ? true
      : false;

    if (!canShowComponent && !CustomComponent) return null;

    if (!canShowComponent && CustomComponent) return CustomComponent;
  }

  return <>{children}</>;
}
