import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

// custom imports
import FormInput from "../forms/FormInput";
import FormSelect from "../forms/FormSelect";
import FormDialog from "../forms/FormDialog";

// services
import { get, post, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import CustomInput from "./SecondaryForms/CustomInput";
import CustomSelect from "./SecondaryForms/CustomSelect";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import NoTableData from "./Tables/NoTableData";
import ShowComponent from "./ShowComponent";
import { hasAllValues } from "../utils/utilities";

// styled components
const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  padding: "10px",
  marginBottom: "20px",
}));

// default options
const INPUT_TYPE_OPTIONS = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
];

const IS_REQUIREDOPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export default function PageHeader({
  title = "",
  onAddClick = () => {},
  collection = "",
  showAddField = true,
  showAdd = true,
  modelName = "",
  onFieldAdd = () => {},
  module = "",
  addButtonDisabled = false,
  showCustomButtonTitle = false,
}) {
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let mdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [addingField, setAddingField] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  const [updatingFields, setUpdatingFields] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");

  const getCustomFields = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: { collectionName: modelName, warehouse: selectedWarehouse },
      });
      setFormFields(
        data?.result?.fields?.map((f) => ({
          ...f,
          isRequired: f.isRequired == true ? "true" : "false",
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modelName && selectedWarehouse) {
      getCustomFields();
    }
  }, [modelName, openFieldsModal, selectedWarehouse]);

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: `${w.name} (${w.code})`,
          value: w._id,
        }))
      );
      if (data.result.length > 0) {
        setSelectedWarehouse(data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);
  // adding new fields
  const handleAddField = async (values) => {
    let fieldValues = { ...values };
    delete fieldValues.warehouse;
    let payload = {
      collectionName: modelName,
      warehouse: values.warehouse,
      field: {
        ...fieldValues,
        isRequired: values.isRequired === "true" ? true : false,
        value: values.inputType === "string" ? "" : 0,
      },
    };
    setAddingField(true);
    try {
      const { data } = await post(URLS.forms.create, payload);
      onFieldAdd();
    } catch (error) {
      console.log(error);
    }

    setAddingField(false);
    handleClose();
  };
  const formik = useFormik({
    initialValues: {
      warehouse: "",
      inputType: "",
      isRequired: "",
      inputLabel: "",
    },
    onSubmit: (values) => handleAddField(values),
    enableReinitialize: true,
  });

  //add modal actions
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      formik.resetForm();
    }, 200);
  };

  // update fields modal
  const handleCloseFieldsModal = () => setOpenFieldsModal(false);
  const handleOpenFieldsModal = () => setOpenFieldsModal(true);

  const handleCustomInputChange = (e, index) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f, i) => (i === index ? { ...f, [name]: value } : f))
    );
  };

  const handleRemoveCustomField = (index) => {
    setFormFields(formFields.filter((f, i) => i !== index));
  };

  const handleUpdateCustomFields = async () => {
    for (let form of formFields) {
      if (!hasAllValues(form, "value")) return;
    }
    let payload = {
      collectionName: modelName,
      warehouse: selectedWarehouse,
      fields: formFields?.map((f) => ({
        ...f,
        isRequired: f.isRequired === "true" ? true : false,
      })),
    };
    setUpdatingFields(true);
    try {
      await put(URLS.forms.update, payload);
      getCustomFields();
      onFieldAdd();
      handleCloseFieldsModal();
    } catch (error) {
      console.log(error);
    }

    setUpdatingFields(false);
  };

  return (
    <>
      <Wrapper
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: mdScreen ? "column" : "row",
          borderRadius: 2,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.2)"
              : "whitesmoke",
        }}
      >
        <Typography
          component="h1"
          sx={{ fontWeight: "bold", my: mdScreen ? 2 : 0 }}
        >
          {title}
        </Typography>
        <Stack
          direction={mdScreen ? "column" : "row"}
          spacing={2}
          alignItems="flex-start"
        >
          {/* Add Button */}
          <ShowComponent module={module} action={"add"}>
            {showAdd ? (
              <Button
                size="small"
                variant="contained"
                disableElevation
                disabled={addButtonDisabled}
                onClick={onAddClick}
                fullWidth={mdScreen}
              >
                Add
              </Button>
            ) : null}
          </ShowComponent>

          {/* Add Custom Field Button */}
          <ShowComponent module="Custom Fields" action={"add"}>
            {showAddField ? (
              <Button
                onClick={handleOpen}
                size="small"
                variant="contained"
                disableElevation
                fullWidth={mdScreen} // Full-width on mobile
              >
                {showCustomButtonTitle
                  ? `Add Custom Field to ${modelName?.toUpperCase()}`
                  : "Add Custom Field"}
              </Button>
            ) : null}
          </ShowComponent>

          {/* Update Custom Fields Button */}
          <ShowComponent module="Custom Fields" action={"update"}>
            {showAddField ? (
              <Button
                onClick={handleOpenFieldsModal}
                size="small"
                variant="contained"
                fullWidth={mdScreen} // Full-width on mobile
                disableElevation
              >
                {showCustomButtonTitle
                  ? `Update Custom Fields of ${modelName?.toUpperCase()}`
                  : "Update Custom Fields"}
              </Button>
            ) : null}
          </ShowComponent>
        </Stack>
      </Wrapper>
      <FormDialog
        open={open}
        formik={formik}
        formTitle={`Add field to ${collection}`}
        onClose={handleClose}
        submitButtonTitle="Add"
        adding={addingField}
      >
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid item xs={12} sm={6}>
            <FormSelect
              options={warehouseData}
              formik={formik}
              name="warehouse"
              label="Warehouse"
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              name="inputLabel"
              type="text"
              required={true}
              formik={formik}
              label="Input label"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              options={INPUT_TYPE_OPTIONS}
              formik={formik}
              name="inputType"
              label="Input type"
              required={true}
              multiple={false}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelect
              options={IS_REQUIREDOPTIONS}
              formik={formik}
              name="isRequired"
              label="Required"
              required={true}
              multiple={false}
            />
          </Grid>
        </Grid>
      </FormDialog>

      <Dialog
        fullScreen={fullScreen}
        open={openFieldsModal}
        onClose={handleCloseFieldsModal}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
        }}
      >
        <DialogTitle>Update {collection} Custom Fields</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            padding: "10px",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
          >
            <Grid item xs={12} md={6}>
              <CustomSelect
                onChange={(e) => setSelectedWarehouse(e.target.value)}
                name="warehouse"
                value={selectedWarehouse}
                options={warehouseData}
                label="Warehouse"
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell align="center">S. No</TableCell>
                  <TableCell align="center">Input Label</TableCell>
                  <TableCell align="center">Input Type</TableCell>
                  <TableCell align="center">Required</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formFields?.length
                  ? formFields?.map((f, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          <CustomInput
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="inputLabel"
                            value={f.inputLabel}
                            label="Input Label"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="inputType"
                            value={f.inputType}
                            options={INPUT_TYPE_OPTIONS}
                            label="Input Type"
                          />
                        </TableCell>

                        <TableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleCustomInputChange(e, index)}
                            name="isRequired"
                            value={f.isRequired}
                            options={IS_REQUIREDOPTIONS}
                            label="Is Required"
                            required={true}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <Tooltip title="Delete">
                            {" "}
                            <IconButton
                              onClick={() => handleRemoveCustomField(index)}
                              size="small"
                            >
                              {" "}
                              <Delete color="error" fontSize="small" />{" "}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            <NoTableData
              dataPresent={formFields ? formFields.length : 0}
              title="custom fields"
            />
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={handleCloseFieldsModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={updatingFields}
            onClick={handleUpdateCustomFields}
            variant="contained"
            size="small"
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
