import React, { useContext, useEffect, useState } from "react";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import { Box, Grid, TablePagination, Typography } from "@mui/material";
import URLS from "../services/urlConstants";
import WarehouseContext from "../context/WarehouseContext";
import { LoadingButton } from "@mui/lab";
import { get, put } from "../services/apiMethods";
import {
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import CustomInput from "../components/SecondaryForms/CustomInput";
import ShowComponent from "../components/ShowComponent";
import { PuffLoader } from "react-spinners";
import { ROWS_PER_PAGE } from "../utils/utilities";
import TabList from "../components/Tabs/TabList";
import TabPanel from "../components/Tabs/TabPanel";

import { Search } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function FreezeItems() {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [loading, setLoading] = useState(false);
  const [loadingFreeze, setLoadingFreeze] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setSelectValue] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [unfreezeData, setUnfreezeData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTabChange = (e, newValue) => {
    setSelectValue(newValue);
    setSearch("");
    setPage(0);
  };

  const getListToUpdate = async () => {
    try {
      setLoading(true);
      const filter = {
        inStock: true,
        itemCategory: { $ne: "faulty" },
        inBundle: false,
      };

      // Apply search filter
      if (search.trim().length > 0) {
        filter["msn"] = search.trim();
      }

      // Apply freeze status filter based on tab selection
      filter["freezed"] = value === 0 ? { $ne: true } : true;

      // Fetch data from API with appropriate filters
      const { data } = await get(URLS.items.listToUpdate, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          page: page + 1,
          limit: rowsPerPage,
          search: filter,
        },
      });

      if (data.result) {
        setTotalItems(data.result.totalItems);

        const newFormData = data.result.items.map((item) => ({
          _id: item._id,
          msn: item.msn,
          itemDescription: item.itemDescription,
          freezeRemarks: item.freezeRemarks,
          remarks: "",
        }));

        setUnfreezeData(newFormData);
        setSearchFilter(newFormData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getListToUpdate();
  }, [selectedWarehouse, value, search, page, rowsPerPage]);

  const handleUpdate = async (id, row) => {
    try {
      setLoadingFreeze((prev) => ({
        ...prev,
        [id]: true,
      }));
      const payload = {
        freezeRemarks: row.remarks,
        freezed: value === 0 ? true : false,
      };

      await put(URLS.items.toggleFreezeStatus + "/" + id, payload);
      getListToUpdate();

      setLoadingFreeze((prev) => ({
        ...prev,
        [id]: false,
      }));
    } catch (error) {
      console.error("Error updating freeze status:", error);
      setLoadingFreeze((prev) => ({
        ...prev,
        [id]: false,
      }));
    }
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setUnfreezeData(
      unfreezeData.map((row, i) =>
        i === index ? { ...row, [name]: value } : row
      )
    );

    setSearchFilter(
      unfreezeData.map((row, i) =>
        i === index ? { ...row, remarks: value } : row
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
  };
  return (
    <>
      <DashBoardPageHeaderPage title="Hold/Release Items" />

      <>
        <TabList
          onChange={handleTabChange}
          value={value}
          labels={["Hold", "Release"]}
        />
        <Grid
          container
          spacing={2}
          my={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Grid item xs="auto">
            <TextField
              size="small"
              value={search}
              fullWidth
              onChange={handleSearch}
              label="Search by MSN"
              placeholder="Enter msn"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TabPanel index={0} value={value}>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <PuffLoader size={30} color="#14532d" />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell align="center" sx={{ minWidth: "80px" }}>
                      S.No
                    </TableCell>

                    <StyledTableCell align="center">MSN</StyledTableCell>
                    <StyledTableCell align="center">
                      Item Description
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Previous Remarks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Current Remarks
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {searchFilter.map((row, i) => (
                    <TableRow key={row._id}>
                      <TableCell align="center" sx={{ minWidth: "80px" }}>
                        {page * rowsPerPage + i + 1}
                      </TableCell>

                      <StyledTableCell align="center">
                        {row.msn}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.itemDescription}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.freezeRemarks}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <CustomInput
                          name="remarks"
                          value={row.remarks}
                          label="Remarks"
                          required={true}
                          onChange={(e) => handleChange(e, i)}
                        />
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <ShowComponent module={"Item"} action={"update"}>
                          <LoadingButton
                            loading={loadingFreeze[row._id]}
                            type="submit"
                            size="small"
                            variant="contained"
                            onClick={() => handleUpdate(row._id, row)}
                          >
                            Hold
                          </LoadingButton>
                        </ShowComponent>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!searchFilter.length && (
                <Typography variant="h6" my={2} textAlign={"center"}>
                  No data found
                </Typography>
              )}
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                rowsPerPageOptions={ROWS_PER_PAGE}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </TabPanel>

        <TabPanel index={1} value={value}>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              <PuffLoader size={30} color="#14532d" />
            </Box>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell align="center" sx={{ minWidth: "80px" }}>
                      S.No
                    </TableCell>

                    <StyledTableCell align="center">MSN</StyledTableCell>
                    <StyledTableCell align="center">
                      Item Description
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Previous Remarks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Current Remarks
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {searchFilter.map((row, i) => (
                    <TableRow key={row._id}>
                      <TableCell align="center" sx={{ minWidth: "80px" }}>
                        {page * rowsPerPage + i + 1}
                      </TableCell>

                      <StyledTableCell align="center">
                        {row.msn}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.itemDescription}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.freezeRemarks}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <CustomInput
                          name="remarks"
                          value={row.remarks}
                          required={true}
                          label="Remarks"
                          onChange={(e) => handleChange(e, i)}
                        />
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <ShowComponent module={"Item"} action={"update"}>
                          <LoadingButton
                            loading={loadingFreeze[row._id]}
                            type="submit"
                            size="small"
                            variant="contained"
                            onClick={() => handleUpdate(row._id, row)}
                          >
                            Release
                          </LoadingButton>
                        </ShowComponent>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!searchFilter.length && (
                <Typography variant="h6" my={2} textAlign={"center"}>
                  No data found
                </Typography>
              )}
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                rowsPerPageOptions={ROWS_PER_PAGE}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          )}
        </TabPanel>
      </>
    </>
  );
}
