import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";

// custom imports
import PageHeader from "../../components/PageHeader";
import CustomTable from "../../components/Tables/CustomTable";
import FormDialog from "../../forms/FormDialog";
import FormInput from "../../forms/FormInput";
import CustomInput from "../../components/SecondaryForms/CustomInput";

// services
import URLS from "../../services/urlConstants";
import { get, put, post, del } from "../../services/apiMethods";

// data
import { gradingScaleKeys } from "../../data/TableData/gradingScale";
import FormSelect from "../../forms/FormSelect";
import CustomWarehouseFilter from "../../components/SecondaryForms/CustomWarehouseFilter";

const DAMAGED_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export default function GradingScale() {
  const [open, setOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearch, setShowSerach] = useState(true);

  const getData = async () => {
    setIsReady(false);
    try {
      const { data } = await get(URLS.gradingScale.list, {
        params: { search: { warehouse: selectedWarehouse } },
      });
      setData(data.result);
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "gradingScale",
          warehouse: selectedWarehouse,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedWarehouse) {
      getData();
      getFormDetails();
    } else {
      setIsReady(true);
    }
  }, [selectedWarehouse]);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        meta: formFields,
      };
      setLoading(true);

      if (dataToEdit) {
        const { data } = await put(
          URLS.gradingScale.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.gradingScale.create, payload);
      }
      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: dataToEdit?.name || "",
      description: dataToEdit?.description || "",
      isDamaged: dataToEdit?.isDamaged || false,
      warehouse: selectedWarehouse || "",
    },
    onSubmit: (values) => handleCreateOrUpdate(values),
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setReadOnly(false);
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleShowData = (data) => {
    setReadOnly(true);
    setDataToEdit(data);
    const fileds = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fileds?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.gradingScale.delete + "/" + id);
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageHeader
        collection="Grading Scale"
        onAddClick={openDataAddOrUpdateModal}
        title="Grading Scale"
        modelName="gradingScale"
        onFieldAdd={handleOnFieldAdd}
        module="Grading scale"
        addButtonDisabled={!selectedWarehouse}
      />

      {/* == warehouse filter components */}
      <CustomWarehouseFilter
        setSelectedWarehouse={setSelectedWarehouse}
        selectedWarehouse={selectedWarehouse}
        data={data}
        setSearch={setSearch}
        setSearchFilter={setSearchFilter}
        search={search}
        placeholder="Name"
        keys={["name"]}
        showSearch={showSearch}
      />

      <CustomTable
        actions={["edit", "delete", "view"]}
        bodyDataModal="grading scale"
        isDataReady={isReady}
        bodyData={search ? searchFilter : data}
        tableKeys={gradingScaleKeys}
        onEditClick={handleSetDataToEdit}
        onDeleteClick={handleDelete}
        onViewClick={handleShowData}
        module="Grading scale"
        formFields={formFields}
      />

      <FormDialog
        readOnly={readOnly}
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update grading scale" : "Add grading scale"}
        adding={loading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Scale name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="description"
              type="text"
              formik={formik}
              label="Description"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="isDamaged"
              formik={formik}
              label="Is Damaged"
              options={DAMAGED_OPTIONS}
            />
          </Grid>

          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
