import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import React from "react";
import FileSelect from "../../forms/FileSelect";
import URLS from "../../services/urlConstants";
import { get, post, put } from "../../services/apiMethods";
import { downloadExcel } from "../../utils/utilities";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

function ExpiryDateDialog({
  open = false,
  onClose = () => {},
  inboundId = "",
}) {
  const [uploadLoader, setUploadLoader] = React.useState(false);
  const [downloadLoader, setDownloadLoader] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [selectFile, setSelectFile] = React.useState([]);

  const handleGetExcelForExpiryDates = async () => {
    try {
      setDownloadLoader(true);
      const { data } = await get(URLS.items.getExcelForExpiryDates, {
        params: {
          inboundId: inboundId,
        },
        responseType: "blob",
      });
      downloadExcel(data, "Expiry_Dates");
      setDownloadLoader(false);
    } catch (error) {
      console.log(error);
      setDownloadLoader(false);
    }
  };

  const updateExpiryDates = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      selectFile.forEach((file) => formData.append("file", file));

      const { data } = await put(URLS.items.updateExpiryDates, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    onClose();
  };

  const handleChangeFiles = (e, index) => {
    const { files } = e.target;
    let fileList = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileList.push(file);
      }
      setSelectFile(fileList);
    } else {
      console.log("No files selected");
    }
  };

  const handleRemoveFile = (fileName, index) => {
    setSelectFile(selectFile.filter((file) => file.name != fileName));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
          component: "form",
        }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Update Expiry Date</DialogTitle>
        <Divider />

        <DialogContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            flexWrap="wrap"
          >
            <LoadingButton
              size="small"
              loading={downloadLoader}
              variant="contained"
              color="secondary"
              onClick={handleGetExcelForExpiryDates}
              startIcon={<DownloadRoundedIcon />}
              sx={{ width: { xs: "100%", sm: 200 } }}
            >
              Get Sample File
            </LoadingButton>

            <Box sx={{ flex: 1, minWidth: 250 }}>
              <FileSelect
                name={`uploadFile`}
                onChange={(e) => handleChangeFiles(e)}
                customOnChange={true}
                label="Select Updated File"
                selectedFiles={selectFile}
                onRemove={(fileName) => handleRemoveFile(fileName)}
              />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            size="small"
            loading={loading}
            variant="contained"
            color="primary"
            onClick={updateExpiryDates}
          >
            Update
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExpiryDateDialog;
