import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";

// custom imports
import themeData from "./data/themeData";
import ThemeModeContext from "./context/ThemeModeContext";
import Login from "./pages/Login";
import RootScreen from "./components/RootScreen";
import UserContext from "./context/UserContext";
import NavDrawer from "./components/NavDrawer";
import ForgotPassword from "./pages/ForgotPassword";
import { ToastContainer } from "react-toastify";
import PageNotFound from "./pages/PageNotFound";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import { get } from "./services/apiMethods";
import URLS from "./services/urlConstants";
import { PuffLoader } from "react-spinners";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState(null);
  const [isTabActive, setIsTabActive] = useState(true); // Track tab activity

  const getCurrentUser = async () => {
    try {
      const { data } = await get(URLS.auth.getCurrentUser);
      let user = data.result;
      setUser(user);
      if (user && user.role && user.role._id) {
        window.localStorage.setItem("role", JSON.stringify(user.role));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let isDark = window.localStorage.getItem("isDarkMode");
    setIsDarkMode(isDark === "true" ? true : false);
  }, [window.localStorage.getItem("isDarkMode")]);

  const restoreUser = async () => {
    try {
      let token = localStorage.getItem("access_token");
      if (token) {
        getCurrentUser();
        setIsReady(true);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.log(error);
    }
    setIsReady(true);
  };

  useEffect(() => {
    restoreUser();
  }, []);

  // Tab activity tracking
  useEffect(() => {
    const tabId = Date.now().toString();
    localStorage.setItem("activeTabId", tabId);

    const handleStorageEvent = (event) => {
      if (event.key === "activeTabId" && event.newValue !== tabId) {
        setIsTabActive(false); // Mark this tab as inactive
      }
    };

    window.addEventListener("storage", handleStorageEvent);

    return () => {
      if (localStorage.getItem("activeTabId") === tabId) {
        localStorage.removeItem("activeTabId");
      }
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  const theme = createTheme({
    palette: isDarkMode ? themeData.darkPalette : themeData.lightPalette,
    shape: {
      borderRadius: themeData.shapeProperties.borderRadius,
    },
    typography: {
      allVariants: {
        color: isDarkMode ? "white" : "black",
        fontSize: "14px",
      },
    },

    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 100px rgba(0, 0, 0, 0) inset",
                boxShadow: "0 0 0 100px rgba(0, 0, 0, 0) inset",
                backgroundColor: "transparent !important",
                WebkitTextFillColor: isDarkMode
                  ? "#fff !important"
                  : "#000 !important", // Ensure text color is set
                transition: "background-color 5000s ease-in-out 0s",
              },
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: isDarkMode ? "#4f4f4f" : "#e8eaed",
            },
          },
        },
      },
      MuiIcon: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          head: {
            color: "#fff",
          },
          root: {
            padding: "8px",
            height: "40px",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
          },
        },
      },

      MuiGrid: {
        styleOverrides: {
          root: {
            paddingTop: 0,
          },
        },
      },
    },
  });

  if (!isReady) return null;

  return (
    <ThemeModeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <UserContext.Provider value={{ user, setUser, getCurrentUser }}>
        <ThemeProvider theme={theme}>
          {!user ? (
            <RootScreen>
              <Routes>
                <Route
                  path="/"
                  element={<PublicRoute component={<Login />} />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                {/* <Route path="*" element={<PageNotFound />} /> */}
              </Routes>
            </RootScreen>
          ) : (
            <NavDrawer />
          )}
        </ThemeProvider>
        {/** Show inactive overlay if tab is not active */}
        {!isTabActive && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                textAlign: "center",
                maxWidth: "500px",
                padding: "20px",
                borderRadius: "12px",
                boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.3)",
                background: "linear-gradient(145deg, #202020, #2c2c2c)",
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto 20px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  style={{ width: "50px", height: "50px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h6m2.25-4.5v9m-9-9v9m-2.25 4.5h12a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0017.25 4.5H6.75A2.25 2.25 0 004.5 6.75v10.5A2.25 2.25 0 006.75 19.5z"
                  />
                </svg>
              </div>
              <h1
                style={{
                  fontSize: "24px",
                  margin: "10px 0",
                  fontWeight: "600",
                }}
              >
                Tab Inactive
              </h1>
              <p
                style={{
                  fontSize: "16px",
                  color: "#aaa",
                  marginBottom: "20px",
                }}
              >
                This session is no longer active. To avoid data loss or
                conflicts, please use the active tab. This tab will close
                automatically if necessary.
              </p>
            </div>
          </div>
        )}

        <ToastContainer autoClose={2000} />
      </UserContext.Provider>
    </ThemeModeContext.Provider>
  );
}

export default App;
