import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Grid,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  TablePagination,
} from "@mui/material";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";

// custom imports
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import ViewOrUpdateItemFiles from "../../components/ViewOrUpdateItemFiles";
import ViewOrUpdateInboundEntryFiles from "../../components/ViewOrUpdateInboundEntryFiles";
import { Search } from "@mui/icons-material";

// services
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import {
  hasAllValues,
  convertToISOString,
  ROWS_PER_PAGE,
} from "../../utils/utilities";
import { toast } from "react-toastify";
import { PuffLoader } from "react-spinners";
import ShowComponent from "../../components/ShowComponent";
import ExpiryDateDialog from "./ExpiryDateDialog";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

const MuiPaper = styled(Paper)(() => ({
  padding: "20px",
}));

export default function UpdateUsedItem({
  dataToEdit,
  onUpdate = () => {},
  setSelectValue = () => {},
  setEntryToEdit = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [addingEntry, setAddingEntry] = useState(false);
  const [inboundToEdit, setInboundToEdit] = useState(dataToEdit);
  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [itemFormFields, setItemFormFields] = useState(null);
  const [orderTypeData, setOrderTypeData] = useState([]);

  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [gradingScale, setGradingScale] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [ownerCodeData, setOwnerCodeData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [damageCodeData, setDamageCodeData] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openDateModal, setOpenDateModal] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const [itemFormFields, formDetails, entryData] = await Promise.all([
        get(URLS.forms.read, {
          params: {
            collectionName: "item",
            warehouse: dataToEdit?.warehouseCode?._id,
          },
        }),
        get(URLS.forms.read, {
          params: {
            collectionName: "inboundEntry",
            warehouse: dataToEdit?.warehouseCode?._id,
          },
        }),
        get(URLS.inboundEntry.read + "/" + dataToEdit?._id),
      ]);

      setItemFormFields(
        itemFormFields.data.result ? itemFormFields.data.result.fields : null
      );
      setFormFields(
        formDetails.data.result
          ? formDetails.data.result.fields.map((f) => ({
              ...f,
              value:
                dataToEdit.meta?.find((m) => m.inputLabel === f.inputLabel)
                  ?.value || "",
            }))
          : null
      );

      setInboundToEdit(entryData.data.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataToEdit) {
      getData();
    }
  }, [dataToEdit]);

  const handleSubmit = async (values) => {
    try {
      if (
        !hasAllValues(entryFormik.values, [
          "receivedDate",
          "images",
          "remarks",
          "ownerCategory",
        ])
      )
        return;
      if (formFields) {
        for (let field of formFields) {
          if (field.isRequired && !field.value) {
            return toast.error(
              `Please fill the required field : ${field.inputLabel}`
            );
          }
        }
      }

      setAddingEntry(true);
      const receivedDate = dayjs(new Date(values.receivedDate)).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      delete values.incomingDate;

      const payload = {
        ...values,
        meta: formFields || [],
        receivedDate: values.receivedDate ? receivedDate : null,
      };

      delete payload.incomingDate;
      const { data } = await put(
        URLS.inboundEntry.update + "/" + dataToEdit._id,
        payload
      );
      setSelectValue(1);
      onUpdate();
      entryFormik.resetForm();
    } catch (error) {
      console.log(error);
    }
    setAddingEntry(false);
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsReferenceCode: inboundToEdit.gmsReferenceCode,
      entryMenu: inboundToEdit.entryMenu,
      receivedDate: inboundToEdit
        ? inboundToEdit.receivedDate
          ? dayjs(inboundToEdit.receivedDate)
          : null
        : null,
      incomingDate: inboundToEdit
        ? inboundToEdit.incomingDate
          ? dayjs(inboundToEdit.incomingDate)
          : null
        : null,
      warehouseCode: inboundToEdit ? inboundToEdit.warehouseCode._id : "",
      warehouseName: inboundToEdit ? inboundToEdit.warehouseCode.name : "",

      receivedDocketNumber: inboundToEdit
        ? inboundToEdit.receivedDocketNumber
        : "",
      eWayBillNumber: inboundToEdit ? inboundToEdit.eWayBillNumber : "",
      incomingInvoiceNumber: inboundToEdit
        ? inboundToEdit.incomingInvoiceNumber
        : "",
      dcNumber: inboundToEdit.dcNumber,
      poNumber: inboundToEdit.poNumber,
      numberOfBoxes: inboundToEdit ? inboundToEdit.numberOfBoxes : "",
      sku: inboundToEdit ? inboundToEdit.sku : formData.length,
      ownerGroup: inboundToEdit ? inboundToEdit.ownerGroup._id : "",
      ownerCode: inboundToEdit ? inboundToEdit.ownerCode._id : "",
      ownerCategory: inboundToEdit ? inboundToEdit.ownerCategory : "",
      workflowCode: inboundToEdit ? inboundToEdit.workflowCode._id : "",
      remarks: inboundToEdit ? inboundToEdit.remarks : "",
    },
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
    validateOnBlur: false,
  });

  const getMasterData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const currentConditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const ownerDataPromise = get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const workflowDataPromise = get(URLS.workFlowCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const gradingScallePromise = get(URLS.gradingScale.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const packagingPromise = get(URLS.packaging.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const [
        stockTypeData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        gradingScaleData,
        packagingData,
        damageCodeData,
        ownerData,
        brandData,
        workflowData,
        activityCodeData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        gradingScallePromise,
        packagingPromise,
        damageCodePromise,
        ownerDataPromise,
        brandDataPromise,
        workflowDataPromise,
        activityCodePromise,
        orderTypeDataPromise,
      ]);

      setActivityCodes(
        activityCodeData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setWorkFlowData(
        workflowData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          ...l,
          label: l.name,
          value: l._id,
        }))
      );
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setGradingScale(
        gradingScaleData?.data?.result?.map((s) => ({
          ...s,
          label: `${s.name}-${s.description}`,
          value: s._id,
        }))
      );
      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );

      setDamageCodeData(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });
      setOwnerCodeData(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getMasterData();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      getOwnerCodes();
    }
  }, [entryFormik.values.ownerGroup]);

  // modified  items table
  useEffect(() => {
    const formData = [];

    if (inboundToEdit?.items?.length) {
      for (let item of inboundToEdit.items) {
        formData.push({
          _id: item._id,
          itemCategory: item.itemCategory,
          expiryDate: item.expiryDate,
          stockType: item.stockType,
          orderType: item.orderType,
          msn: item.msn?.toUpperCase(),
          subFamily: item.subFamily,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName,
          storageCode: item.storageCode,
          palletCode: item.palletCode,
          conditionCode: item.conditionCode,
          gradingScale: item.gradingScale,
          activityCode: item.activityCode,
          damageCode: item.damageCode,
          packaging: item.packaging,
          configurationOs: item.configurationOs,
          remarks: item.remarks,
          images: item.images,
          meta: itemFormFields?.map((f) => ({
            ...f,
            value:
              item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value ||
              "",
          })),
        });
      }
    }

    setFormData(formData);
    setSearchFilter(formData);
  }, [inboundToEdit, itemFormFields, formFields]);

  const handleChange = (e, id, repeat = false) => {
    let name, value;

    if (e && e.target) {
      // For normal inputs
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "expiryDate";
      value = e;
    }

    let index = formData.indexOf(formData.find((fd) => fd.id === id));

    const faultyId = "faulty";
    const usedId = "used";

    if (name === "gradingScale") {
      const isDamaged = gradingScale.find((g) => g._id === value)?.isDamaged;
      if (isDamaged) {
        setFormData(
          formData.map((fd, i) =>
            fd.id === id
              ? {
                  ...fd,
                  [name]: value,
                  itemCategory: faultyId,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              fd.id >= id
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    damageCode: "",
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              fd.id === id
                ? {
                    ...fd,
                    [name]: value,
                    itemCategory: usedId,
                    damageCode: "",
                  }
                : fd
            )
          );
        }
      }
    } else {
      const selectedIsDamaged = gradingScale.find(
        (g) => g._id === formData[index].gradingScale
      )?.isDamaged;

      if (selectedIsDamaged) {
        setFormData(
          formData.map((fd, i) =>
            fd.id === id
              ? {
                  ...fd,
                  [name]: value,
                }
              : fd
          )
        );
      } else {
        if (repeat) {
          setFormData(
            formData.map((fd, i) =>
              fd.id >= id
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        } else {
          setFormData(
            formData.map((fd, i) =>
              fd.id === id
                ? {
                    ...fd,
                    [name]: value,
                  }
                : fd
            )
          );
        }
      }
    }
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.msn.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter(formData);
    }
  };

  if (loading)
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "60px",
        }}
      >
        <PuffLoader size={40} color="#14532d" />
      </Box>
    );

  return (
    <>
      <MuiPaper component="form">
        <Grid rowSpacing={1} columnSpacing={2} container>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="gmsReferenceCode"
              disabled={true}
              label="Gms Reference Code"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormDatePicker
              formik={entryFormik}
              label="Received Date"
              name="receivedDate"
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              disabled={true}
              label="Warehouse code"
              name="warehouseCode"
              options={warehouseData.map((warehouse) => ({
                label: warehouse.code,
                value: warehouse._id,
              }))}
              required={true}
              onChange={(e) => {
                const selectedValue = e.target.value;
                const selectedWarehouse = warehouseData.find(
                  (warehouse) => warehouse._id === selectedValue
                );
                if (selectedWarehouse) {
                  entryFormik.setFieldValue(
                    "warehouseCode",
                    selectedWarehouse._id
                  );
                  entryFormik.setFieldValue(
                    "warehouseName",
                    selectedWarehouse.name
                  );
                }
              }}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="warehouseName"
              disabled={true}
              label="Warehouse name"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormDatePicker
              required={true}
              formik={entryFormik}
              label="Incoming Date"
              name="incomingDate"
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="receivedDocketNumber"
              label="Received docket number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="eWayBillNumber"
              label="E-Way Bill number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="incomingInvoiceNumber"
              label="Incoming invoice number"
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="dcNumber"
              label="DC number"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="poNumber"
              label="PO number"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="number"
              disabled={true}
              formik={entryFormik}
              name="numberOfBoxes"
              label="Number of boxes"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="number"
              formik={entryFormik}
              name="sku"
              disabled={true}
              label="SKU"
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              disabled={true}
              name="ownerGroup"
              label="Owner Group"
              options={ownerData}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              disabled={true}
              type="text"
              formik={entryFormik}
              name="ownerCode"
              label="Owner code"
              options={ownerCodeData}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="ownerCategory"
              label="Owner category"
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              type="text"
              formik={entryFormik}
              name="workflowCode"
              label="Workflow code"
              required={true}
              options={workFlowData}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormInput
              type="text"
              formik={entryFormik}
              name="remarks"
              label="Remarks"
            />
          </Grid>

          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6} md={4}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={f.inputLabel}
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}

          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            xs={12}
            sm={6}
            md={4}
            item
          >
            <ViewOrUpdateInboundEntryFiles
              inboundEntry={dataToEdit}
              onUpdate={onUpdate}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            mt: 1,
          }}
        >
          <Button
            onClick={() => {
              setEntryToEdit(null);
              setSelectValue(1);
            }}
            size="small"
            variant="outlined"
          >
            Back
          </Button>
          {/* <ShowComponent module="Inbound Process" action={"update"}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={(e) => setOpenDateModal(true)}
            >
              Update Expiry Date
            </Button>
          </ShowComponent> */}
          <ShowComponent module="Inbound Process" action={"update"}>
            <LoadingButton
              loading={addingEntry}
              size="small"
              variant="contained"
              onClick={entryFormik.handleSubmit}
            >
              Update Entry
            </LoadingButton>
          </ShowComponent>
        </Box>
      </MuiPaper>
      {/* ===== expiry date model */}
      <ExpiryDateDialog
        open={openDateModal}
        onClose={() => setOpenDateModal(false)}
        inboundId={inboundToEdit._id}
      />
      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",

          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs="auto">
          <TextField
            size="small"
            value={search}
            onChange={handleSearch}
            label="Search by MSN"
            placeholder="Enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer sx={{ zoom: 0.8 }} component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                S.No
              </TableCell>
              <StyledTableCell align="center">Item Category</StyledTableCell>

              <StyledTableCell align="center">Expiry date</StyledTableCell>

              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">Order Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>
              <StyledTableCell align="center">Grading Scale</StyledTableCell>
              <StyledTableCell align="center">Damage code</StyledTableCell>
              <TableCell align="center">Current activity code</TableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>
              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              {itemFormFields?.map((i, index) => (
                <StyledTableCell align="center" key={index}>
                  {i.inputLabel}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">Inbound Remarks</StyledTableCell>
              {/* <TableCell align="center">Image</TableCell> */}
              {/* <StyledTableCell align="center">Action</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {searchFilter
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, i) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    {page * rowsPerPage + i + 1}
                  </TableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.itemCategory?.toUpperCase()}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {fd?.expiryDate
                      ? new Date(fd.expiryDate).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })
                      : "NA"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={stockTypes}
                      name="stockType"
                      label="Stock Type"
                      value={fd.stockType}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={orderTypeData}
                      name="orderType"
                      label="Order Type"
                      value={fd.orderType}
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="msn"
                      value={fd.msn}
                      label="MSN"
                      required={true}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                      options={subFamilies}
                      name="subFamily"
                      label="Sub-Family Name"
                      value={fd.subFamily}
                      required={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="itemDescription"
                      value={fd.itemDescription}
                      label="Item Description"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="itemCode"
                      value={fd.itemCode}
                      label="Item Code"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="manufacturerPartNumber"
                      value={fd.manufacturerPartNumber}
                      label="Manufacturer Part Number"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={brandData}
                      name="brandName"
                      label="Brand Name"
                      value={fd.brandName}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={storageCodes}
                      name="storageCode"
                      label="Storage Code"
                      value={fd.storageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={palletCodes}
                      name="palletCode"
                      label="Pallet Code"
                      value={fd.palletCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={currentConditionCodes}
                      name="conditionCode"
                      label="Current Condition Code"
                      value={fd.conditionCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={gradingScale}
                      name="gradingScale"
                      label="Grading Scale"
                      value={fd.gradingScale}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={damageCodeData}
                      name="damageCode"
                      label="Damage Code"
                      value={fd.damageCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={activityCodes}
                      name="activityCode"
                      label="Activity Code"
                      value={fd.activityCode}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      disabled={true}
                      options={packaging}
                      name="packaging"
                      label="Packaging"
                      value={fd.packaging}
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="configurationOs"
                      value={fd.configurationOs}
                      label="Configuration OS"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>

                  {itemFormFields
                    ? fd.meta?.map((f, index) => (
                        <StyledTableCell align="center" key={index}>
                          <CustomInput
                            disabled={true}
                            onChange={(e) => handleCustomInputChangeItem(e, i)}
                            name={f.inputLabel}
                            value={f.value}
                            required={f.isRequired}
                            label={f.inputLabel}
                            type={f.inputType}
                          />
                        </StyledTableCell>
                      ))
                    : null}
                  <StyledTableCell align="center">
                    <CustomInput
                      disabled={true}
                      name="remarks"
                      value={fd.remarks}
                      label="Inbound Remarks"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  <ViewOrUpdateItemFiles item={fd} onUpdate={onUpdateItem} />
                </StyledTableCell> */}
                  {/* <TableCell align="center">
                  <Box
                    display="flex"
                    sx={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <LoadingButton
                      loading={addingSave[fd._id]}
                      size="small"
                      variant="contained"
                      onClick={() => handleUpdateItem(fd._id, fd)}
                      style={{ marginRight: "4px" }}
                    >
                      Save
                    </LoadingButton>
                    {itemCategory
                      .find((i) => i._id === fd.itemCategory)
                      ?.name?.toLowerCase() === "faulty" ? (
                      <LoadingButton
                        loading={addingSave[fd._id]}
                        size="small"
                        variant="contained"
                        sx={{ width: 200 }}
                        onClick={() =>
                          handleUpdateItem(fd._id, fd, "updateHistory")
                        }
                      >
                        Save and Update History
                      </LoadingButton>
                    ) : null}
                  </Box>
                </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {search.trim() && !searchFilter.length && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )}
      </TableContainer>

      <TablePagination
        component="div"
        count={searchFilter.length}
        page={page}
        rowsPerPageOptions={ROWS_PER_PAGE}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* {formData.length > 0 && !search.trim() && (
        <Typography variant="h6" my={2} textAlign={"center"}>
          No data found
        </Typography>
      )} */}
    </>
  );
}
