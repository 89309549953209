import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { Search } from "@mui/icons-material";
import { ROWS_PER_PAGE } from "../../utils/utilities";
import WarehouseContext from "../../context/WarehouseContext";
import { PuffLoader } from "react-spinners";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Close } from "@mui/icons-material";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function UpdateShippedStock() {
  const navigate = useNavigate();
  const location = useLocation();
  const shippedStockData = location.state && location.state.itemData;
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [itemFormFields, setItemFormFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemDescription, setItemDescription] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [damageCode, setDamageCode] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [gradingScale, setGradingScale] = useState([]);

  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpen = (images) => {
    setImages(images);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.list, {
        params: {
          collectionName: "item",
          warehouse: { $in: selectedWarehouse },
        },
      });
      setItemFormFields(data.result ? data.result : []);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });
      const itemDescriptionPromise = get(
        URLS.itemDescription.getItemDescription,
        {
          params: { search: { warehouse: { $in: selectedWarehouse } } },
        }
      );
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const currentConditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });

      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });

      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const packagingPromise = get(URLS.packaging.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const gradingScallePromise = get(URLS.gradingScale.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });

      const [
        stockTypeData,
        subFamilyData,
        itemDescriptionData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        brandData,
        orderTypeData,
        activityCodeData,
        damageCodeData,
        packagingData,
        gradingScaleData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        itemDescriptionPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        brandDataPromise,
        orderTypeDataPromise,
        activityCodePromise,
        damageCodePromise,
        packagingPromise,
        gradingScallePromise,
      ]);

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setItemDescription(
        itemDescriptionData.data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
      setActivityCodes(
        activityCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setDamageCode(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );

      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );
      setGradingScale(
        gradingScaleData?.data?.result?.map((s) => ({
          ...s,
          label: `${s.name}-${s.description}`,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getItemFormDetails();
  }, [selectedWarehouse]);

  const getItems = async () => {
    setLoading(true);
    try {
      const { data } = await get(URLS.items.getShippedItemDetails, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          subFamily: shippedStockData.subFamilyId,
        },
      });

      console.log(data.result, "////////////////////");

      if (data.result) {
        const newFormData = data.result?.map((item) => ({
          _id: item._id,
          itemCategory: item.itemCategory,
          warehouse: item.warehouse,
          expiryDate: item.expiryDate,
          stockType: item.stockType,
          orderType: item.orderType,
          msn: item.msn,
          subFamily: item.subFamily,
          activityCode: item.activityCode,
          damageCode: item.damageCode,
          gradingScale: item.gradingScale,
          packaging: item.packaging,
          freezeRemarks: item.freezeRemarks,
          freezed: item.freezed ? "Yes" : "No",
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName,
          storageCode: item.storageCode,
          palletCode: item.palletCode,
          conditionCode: item.conditionCode,
          configurationOs: item.configurationOs,
          inStock: item.inStock ? "Yes" : "No",
          inBundle: item.inBundle ? "Yes" : "No",
          remarks: item.remarks,
          images: item.images,
          meta: itemFormFields
            ?.find((ff) => ff.warehouse?._id === item.warehouse)
            ?.fields?.map((f) => ({
              ...f,
              value:
                item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                  ?.value || "",
            })),
        }));
        setFormData(newFormData);
        setSearchFilter(newFormData);
      } else {
        setFormData([]);
        setSearchFilter([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (shippedStockData) {
      getItems();
    }
  }, [itemFormFields]);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.msn.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter(formData);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={12} md={9} mb={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <TablePagination
              component="div"
              count={searchFilter?.length}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={3} mb={2}>
          <TextField
            size="small"
            value={search}
            fullWidth
            onChange={handleSearch}
            label="Search by MSN"
            placeholder="Enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <PuffLoader size={30} color="#14532d" />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell sx={{ minWidth: "80px" }} align="center">
                  S.No
                </TableCell>
                <StyledTableCell align="center">Item Category</StyledTableCell>
                <StyledTableCell align="center">Expiry Date</StyledTableCell>
                <StyledTableCell align="center">Stock Type</StyledTableCell>
                <StyledTableCell align="center">Order Type</StyledTableCell>
                <StyledTableCell align="center">MSN</StyledTableCell>
                <StyledTableCell align="center">
                  Sub-family name
                </StyledTableCell>
                <StyledTableCell align="center">
                  Item description
                </StyledTableCell>
                <StyledTableCell align="center">Item code</StyledTableCell>
                <StyledTableCell align="center">
                  Manufacturer part number
                </StyledTableCell>
                <StyledTableCell align="center">Brand name</StyledTableCell>
                <StyledTableCell align="center">Storage code</StyledTableCell>
                <StyledTableCell align="center">Pallet code</StyledTableCell>
                <StyledTableCell align="center">
                  Current condition code
                </StyledTableCell>
                <StyledTableCell align="center">Grading scale</StyledTableCell>
                <StyledTableCell align="center">
                  Current activity code
                </StyledTableCell>
                <StyledTableCell align="center">Damage code</StyledTableCell>

                <StyledTableCell align="center">Packaging</StyledTableCell>
                <StyledTableCell align="center">
                  Configuration OS
                </StyledTableCell>
                {/* 
                <StyledTableCell align="center">
                  Freezed Remarks
                </StyledTableCell>

                <StyledTableCell align="center">Is Freezed</StyledTableCell> */}

                {itemFormFields
                  ?.flatMap((f) => f.fields)
                  ?.map((i, index) => (
                    <StyledTableCell align="center" key={index}>
                      {i.inputLabel}
                    </StyledTableCell>
                  ))}
                <StyledTableCell align="center">
                  {" "}
                  Inbound Remarks
                </StyledTableCell>
                <TableCell align="center"> Images</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchFilter
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fd, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ minWidth: "80px" }} align="center">
                      {page * rowsPerPage + i + 1}
                    </TableCell>

                    <StyledTableCell align="center">
                      {fd.itemCategory?.toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "8px",
                            },
                            marginTop: "16px",
                            width: "100%",
                          }}
                          name="expiryDate"
                          variant="outlined"
                          size="small"
                          label="Expiry Date"
                          slotProps={{
                            textField: {
                              InputLabelProps: { fontSize: "90px" },
                            },
                          }}
                          views={["year", "month", "day"]}
                          format="DD-MM-YYYY"
                          value={fd.expiryDate ? dayjs(fd.expiryDate) : null}
                          disabled={true}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={stockTypes.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="stockType"
                        label="Stock Type"
                        value={fd.stockType}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={orderTypeData.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="orderType"
                        label="Order Type"
                        value={fd.orderType}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="msn"
                        value={fd.msn?.toUpperCase()}
                        label="MSN"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        disabled={true}
                        options={subFamilies.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="subFamily"
                        label="Sub-Family Name"
                        value={fd.subFamily}
                        required={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        name="itemDescription"
                        value={fd.itemDescription}
                        options={itemDescription.filter(
                          (s) => s.warehouse === fd.warehouse
                        )}
                        label="Item Description"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="itemCode"
                        value={fd.itemCode}
                        label="Item Code"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomInput
                        name="manufacturerPartNumber"
                        value={fd.manufacturerPartNumber}
                        label="Manufacturer Part Number"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={brandData.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="brandName"
                        label="Brand Name"
                        value={fd.brandName}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={storageCodes.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="storageCode"
                        label="Storage Code"
                        value={fd.storageCode}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={palletCodes.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="palletCode"
                        label="Pallet Code"
                        value={fd.palletCode}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={currentConditionCodes.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="conditionCode"
                        label="Current Condition Code"
                        value={fd.conditionCode}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomSelect
                        options={gradingScale.filter(
                          (s) => s.warehouse?._id === fd.warehouse
                        )}
                        name="gradingScale"
                        label="Grading Scale"
                        value={fd.gradingScale}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={activityCodes.filter(
                          (s) => s.warehouse?._id == fd.warehouse
                        )}
                        name="activityCode"
                        label="Activity Code"
                        value={fd.activityCode}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomSelect
                        options={damageCode.filter(
                          (s) => s.warehouse?._id == fd.warehouse
                        )}
                        name="damageCode"
                        label="Damage Code"
                        value={fd.damageCode}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomSelect
                        options={packaging.filter(
                          (s) => s.warehouse?._id == fd.warehouse
                        )}
                        name="packaging"
                        label="Packaging"
                        value={fd.packaging}
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomInput
                        name="configurationOs"
                        value={fd.configurationOs}
                        label="Configuration OS"
                        disabled={true}
                      />
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      <CustomInput
                        name="freezeRemarks"
                        value={fd.freezeRemarks}
                        label="Freeze Remarks"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <CustomInput
                        name="freezed"
                        value={fd.freezed}
                        label="Is Freezed"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell> */}

                    {itemFormFields.flatMap((f, index) =>
                      f.warehouse?._id === fd.warehouse ? (
                        fd.meta?.map((f, index) => (
                          <StyledTableCell align="center" key={index}>
                            <CustomInput
                              // onChange={(e) => handleCustomInputChangeItem(e, i)}
                              name={f.inputLabel}
                              value={f.value}
                              required={f.isRequired}
                              label={f.inputLabel}
                              type={f.inputType}
                            />
                          </StyledTableCell>
                        ))
                      ) : (
                        <StyledTableCell align="center">NA</StyledTableCell>
                      )
                    )}

                    <StyledTableCell align="center">
                      <CustomInput
                        name="remarks"
                        value={fd.remarks}
                        label="Inbound Remarks"
                        required={true}
                        disabled={true}
                      />
                    </StyledTableCell>

                    <TableCell align="center">
                      <Tooltip title="View">
                        <IconButton
                          size="small"
                          onClick={() => handleOpen(fd?.images)}
                        >
                          <RemoveRedEyeRoundedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {search.trim() && !searchFilter.length && !loading && (
            <Typography variant="h6" my={2} textAlign={"center"}>
              No data found
            </Typography>
          )}
        </TableContainer>
      )}

      <CustomDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontSize: "15px",
          }}
        >
          View Images
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Divider sx={{ mb: 2 }} />

        <DialogContent>
          <Grid container spacing={1}>
            {images?.length === 0 ? (
              <Grid item xs={12}>
                <Typography align="center">
                  No data available at the moment
                </Typography>
              </Grid>
            ) : (
              images?.map((image, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px",
                      borderRadius: "5px",
                      marginTop: "5px",
                      background: "#5fa5f661",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(image, "_blank")}
                  >
                    <Typography>{`File ${index + 1}`}</Typography>
                    <Stack direction="row" spacing={2}>
                      <IconButton onClick={() => window.open(image, "_blank")}>
                        <RemoveRedEyeRoundedIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>

          <Box sx={{ textAlign: "right", mt: 2 }}>
            {" "}
            <Button
              size="small"
              onClick={handleClose}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </CustomDialog>
    </>
  );
}
