import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Paper,
  Grid,
  TextField,
  InputAdornment,
  Box,
  Button,
  TablePagination,
} from "@mui/material";

// custom imports
import CustomSelect from "../SecondaryForms/CustomSelect";
import CustomInput from "../SecondaryForms/CustomInput";
import FileSelect from "../../forms/FileSelect";
import { Delete, Search } from "@mui/icons-material";

// services
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// utilities
import {
  generateRandomNumber,
  hasAllValues,
  ROWS_PER_PAGE,
} from "../../utils/utilities";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import WarehouseContext from "../../context/WarehouseContext";
import { PuffLoader } from "react-spinners";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "150px",
}));

export default function UpdateBundledStock() {
  const { selectedWarehouse, selectedOwnerCode } = useContext(WarehouseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const bundleType = location.state && location.state.itemData;
  const [bundles, setBundles] = useState([]);
  const [updating, setUpdating] = useState("");

  const [headerKeys, setHeaderKeys] = useState([]);

  const [formData, setFormData] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const storageCodePromise = get(URLS.storageCode.list);
      const palletCodeCodePromise = get(URLS.palletCode.list);
      const [stockTypeData, storageCodeData, palletCodeData] =
        await Promise.all([
          stockPromise,
          storageCodePromise,
          palletCodeCodePromise,
        ]);
      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );

      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // added new function
  const handleRemoveFile = (fileName, index) => {
    let files = formData[index].images.filter((f) => f.name !== fileName);
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, images: files } : fd))
    );
  };

  const handleChangeFiles = (e, index) => {
    const { files } = e.target;

    let fileList = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        fileList.push(file);
      }

      setFormData(
        formData.map((fd, i) =>
          i === index ? { ...fd, images: fileList } : fd
        )
      );
    } else {
      console.log("No files selected");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getAllBundles = async () => {
    try {
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: { bundleType, inStock: true },
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      setBundles(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBundles();
  }, [selectedWarehouse, selectedOwnerCode]);

  useEffect(() => {
    if (bundles) {
      let firstItem = bundles[0];
      let HEADER_KEYS = [];
      if (firstItem) {
        for (let item of firstItem.items) {
          let newItem = [
            "Stock Type",
            item.subFamily.name,
            "Present Storage Code",
            "Present Pallet Code",
          ];

          HEADER_KEYS = [...HEADER_KEYS, ...newItem];
        }
      }

      setHeaderKeys(HEADER_KEYS);
    }
  }, [bundles]);

  useEffect(() => {
    let newForm = [];
    for (let bundle of bundles) {
      let form = {};
      form["_id"] = bundle._id;
      form["bundleReferenceNumber"] = bundle.bundleReferenceNumber;
      form["items"] = [];
      form["itemIds"] = [];
      for (let item of bundle.items) {
        form.itemIds.push(item._id);
        let VALUES = [
          item.stockType.name,
          item.msn,
          item.storageCode.code,
          item.palletCode.code,
        ];
        form.items = [...form.items, ...VALUES];
      }
      form["newStorageCode"] = bundle.storageCode._id;
      form["newPalletCode"] = bundle.palletCode._id;
      form["newStockType"] = bundle.stockType._id;
      form["remarks"] = bundle.remarks;
      form["images"] = bundle.images;

      newForm.push(form);
    }

    setFormData(newForm);
  }, [bundles]);

  const handleChange = (e, index, repeat) => {
    const { name, value } = e.target;
    if (repeat) {
      setFormData(
        formData.map((fd, i) => (i >= index ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
      );
    }
  };

  const handleRemoveForm = (bundleReferenceNumber) =>
    setFormData(
      formData.filter((f) => f.bundleReferenceNumber !== bundleReferenceNumber)
    );

  const handleUpdate = async (fd) => {
    const payload = {
      newStorageCode: fd.newStorageCode,
      newPalletCode: fd.newPalletCode,
      newStockType: fd.newStockType,
      remarks: fd.remarks,
      items: fd.itemIds,
    };

    setUpdating(fd._id);
    try {
      const { data } = await put(URLS.bundle.update + "/" + fd._id, payload);
      getAllBundles();
      setUpdating("");
    } catch (error) {
      console.log(error);
      setUpdating("");
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        // my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Grid item xs={12} md={9} mb={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <TablePagination
              component="div"
              count={formData.length}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
        {/* <Grid item xs="auto">
          <TextField
            size="small"
            // value={search}
            fullWidth
            // onChange={handleSearch}
            label="Search by MSN"
            placeholder="Enter msn"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" type="submit">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}
      </Grid>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <PuffLoader size={30} color="#14532d" />
        </Box>
      ) : null}
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{ backgroundColor: (theme) => theme.palette.primary.main }}
          >
            <TableRow>
              <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                S. No.
              </StyledTableCell>

              {headerKeys.map((item, i) => (
                <StyledTableCell align="center" key={i}>
                  {item}
                </StyledTableCell>
              ))}
              <StyledTableCell align="center">
                Bundle Reference Number
              </StyledTableCell>
              <StyledTableCell align="center">New Storage code</StyledTableCell>
              <StyledTableCell align="center">New pallet code</StyledTableCell>
              <StyledTableCell align="center">
                New stock type for bundle
              </StyledTableCell>
              <StyledTableCell align="center">Remarks</StyledTableCell>
              {/* <StyledTableCell sx={{ minWidth: "200px" }} align="center">
                Image
              </StyledTableCell> */}

              <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData?.map((fd, index) => (
              <TableRow>
                <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  {index + 1}
                </StyledTableCell>
                {fd.items.map((v, i) => (
                  <StyledTableCell align="center" key={i}>
                    {v}
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center">
                  {fd.bundleReferenceNumber}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    label="New Storage code"
                    required={true}
                    name="newStorageCode"
                    options={storageCodes}
                    value={fd.newStorageCode || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    label="New Pallet code"
                    required={true}
                    name="newPalletCode"
                    options={palletCodes}
                    value={fd.newPalletCode || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    label="New Stock type"
                    required={true}
                    name="newStockType"
                    options={stockTypes}
                    value={fd.newStockType || ""}
                    onChange={(e) => handleChange(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomInput
                    name="remarks"
                    value={fd.remarks}
                    label="Inbound Remarks"
                    onChange={(e) => handleChange(e, index)}
                  />
                </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <FileSelect
                    name={`images${index}`}
                    onChange={(e) => handleChangeFiles(e, index)}
                    customOnChange={true}
                    selectedFiles={fd.images}
                    onRemove={(fileName) => handleRemoveFile(fileName, index)}
                  />
                </StyledTableCell> */}
                <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {/* <IconButton
                      size="small"
                      color="error"
                      onClick={(e) =>
                        handleRemoveForm(fd.bundleReferenceNumber)
                      }
                    >
                      <Delete fontSize="small" />
                    </IconButton> */}
                    <LoadingButton
                      loading={fd._id === updating ? true : false}
                      variant="contained"
                      size="small"
                      onClick={(e) => handleUpdate(fd)}
                    >
                      Update
                    </LoadingButton>
                  </Box>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
