import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import CustomWarehouseFilter from "../../components/SecondaryForms/CustomWarehouseFilter";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import { packagingTableKeys } from "../../data/TableData/packaging";
import CustomTable from "../../components/Tables/CustomTable";
import { separateAtCapital } from "../../utils/utilities";
import ShowComponent from "../../components/ShowComponent";
import { LoadingButton } from "@mui/lab";
import { Search } from "@mui/icons-material";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),

  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,

  padding: theme.spacing(2),
  zIndex: 1000,
}));

export default function ItemHistoryConfig() {
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [data, setData] = useState([]);
  const [itemKeys, setItemKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [showSearch, setShowSerach] = useState(true);

  const getItemKeys = async () => {
    try {
      const { data } = await get(URLS.itemHistoryConfig.getData, {
        params: {
          warehouse: selectedWarehouse,
        },
      });
      // console.log(data.result, "ressses", selectedWarehouse);

      setItemKeys(data.result.keys);
      setData(data.result.itemHistoryConfig);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedWarehouse) {
      getItemKeys();
    }
  }, [selectedWarehouse]);

  const handleItemHistoryUpdate = async () => {
    setLoading(true);
    try {
      let payload = {
        warehouse: selectedWarehouse,
        itemKeys: itemKeys.filter((i) => i.isChecked)?.map((i) => i.key),
      };
      const { data: res } = await put(
        URLS.itemHistoryConfig.update + "/" + data._id,
        payload
      );
      getItemKeys();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleToggle = (key) => {
    const updatedItemKeys = itemKeys.map((i) =>
      i.key === key ? { ...i, isChecked: !i.isChecked } : i
    );
    setItemKeys(updatedItemKeys);
    if (searchFilter.length) {
      setSearchFilter(
        searchFilter.map((i) =>
          i.key === key ? { ...i, isChecked: !i.isChecked } : i
        )
      );
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography component="h1" variant="h5" sx={{ fontWeight: "bold" }}>
          Item History Config
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>
      <Box
        sx={{
          background: "#8af1af5c",
          padding: 2,
          borderRadius: 2,
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: 14, color: "text.primary" }}>
          <strong>The item document</strong> contains many fields, and it is
          important to confirm which fields require a{" "}
          <strong>history record</strong> to be created when they are updated.
          Tracking changes for specific fields will help maintain a clear audit
          trail without unnecessary overhead. Please review and confirm which
          fields from the list below should have their changes recorded in the{" "}
          <strong>history</strong>.
        </Typography>
      </Box>{" "}
      <CustomWarehouseFilter
        setSelectedWarehouse={setSelectedWarehouse}
        selectedWarehouse={selectedWarehouse}
        data={itemKeys}
        setSearch={setSearch}
        setSearchFilter={setSearchFilter}
        search={search}
        placeholder="here..."
        keys={["label"]}
        showSearch={showSearch}
      />
      {search && !searchFilter.length && (
        <Typography sx={{ mt: 4, textAlign: "center" }}>
          No results for "{search}"
        </Typography>
      )}
      <Grid
        container
        sx={{
          maxHeight: "50vh",
          overflowY: "auto",
          scrollbarWidth: "none",
          marginBottom: "60px",
        }}
      >
        {(search ? searchFilter : itemKeys).map((value, index) => {
          const labelId = `checkbox-list-label-${value.key}`;
          return (
            <Grid item xs={12} sm={6} md={4} xl={3} key={value.key + index}>
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => handleToggle(value.key)}
                >
                  <Checkbox
                    edge="start"
                    checked={value.isChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                  <ListItemText id={labelId} primary={value.label || ""} />
                </ListItemButton>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
      {itemKeys.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12}>
            <StyledBox
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(32,33,32,1)"
                    : theme.palette.grey[100],
              }}
            >
              <ShowComponent module={"Item History Config"} action={"update"}>
                <LoadingButton
                  onClick={handleItemHistoryUpdate}
                  loading={loading}
                  variant="contained"
                  size="small"
                >
                  Update
                </LoadingButton>
              </ShowComponent>
            </StyledBox>
          </Grid>
        </Grid>
      )}
    </>
  );
}
