import React from "react";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import { Box } from "@mui/material";
import AddTab from "../components/DashBoard/AddTab";

export default function Dashboard() {
  return (
    <Box>
      <DashBoardPageHeaderPage title="Welcome to GMS... " />
      <AddTab />
    </Box>
  );
}
