import React from "react";
import { TextField, useTheme } from "@mui/material";

export default function FormInput({
  name,
  label = "default label",
  formik,
  required = false,
  disabled = false,
  multiline = false,
  rows = 3,
  placeholder = "",
  containerStyle = {},
  inputProps = {},
  multipleContactNumbers = false,
  ...rest
}) {
  const theme = useTheme();

  const handleChange = (e) => {
    if (name === "contactNumber" && !multipleContactNumbers) {
      const value = e.target.value;
      if (value.length <= 10) {
        formik.handleChange(e);
      }
    } else {
      formik.handleChange(e);
    }
  };

  return (
    <TextField
      required={required}
      id={name}
      name={name}
      label={label}
      multiline={multiline}
      rows={multiline ? rows : 1}
      disabled={disabled}
      placeholder={placeholder || `Enter ${label}`}
      fullWidth
      value={formik.values[name] || ""}
      onChange={handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      sx={{
        mt: 2,
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        multiple: true,
        style: {
          borderWidth: 1,
          height: multiline ? "auto" : "42px",
          borderRadius: theme.shape.borderRadius,
        },
        ...inputProps,
      }}
      {...rest}
    />
  );
}
