import {
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useContext } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { get } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import { downloadExcel } from "../../utils/utilities";
import { Link, useNavigate } from "react-router-dom";
import ShowComponent from "../ShowComponent";
import WarehouseContext from "../../context/WarehouseContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function sumValues(data) {
  let sum = 0;

  for (const category of data) {
    category.subFamilies?.forEach((item) => {
      sum += item.count;
    });
  }

  return sum;
}

export default function UsedStock({ data }) {
  const { selectedOwnerCode, selectedWarehouse, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [downloadingReport, setDownloadingReport] = React.useState([]);
  const [downloadingAll, setDownloadingAll] = React.useState(false);

  const navigate = useNavigate();
  const handleViewClick = async (id, k) => {
    try {
      navigate(`/edit/used/stock/${id}`, { state: { itemData: k } });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadReport = async (subFamily) => {
    if (subFamily) {
      setDownloadingReport([...downloadingReport, subFamily]);
    } else {
      setDownloadingAll(true);
    }
    try {
      let filter = {
        category: "used",
      };
      if (subFamily) {
        filter["subFamily"] = subFamily;
      }
      const { data } = await get(URLS.items.downloadReport, {
        params: {
          ...filter,
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
        responseType: "blob",
      });
      downloadExcel(data, "USED_STOCK.xlsx");
      if (subFamily) {
        setDownloadingReport(downloadingReport.filter((s) => s === subFamily));
      } else {
        setDownloadingAll(false);
      }
    } catch (error) {
      console.log(error);
      if (subFamily) {
        setDownloadingReport(downloadingReport.filter((s) => s === subFamily));
      } else {
        setDownloadingAll(false);
      }
    }
  };
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <StyledTableCell>Used Stock Inventory</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableHead>
          <TableBody>
            {data.map((d, index) => (
              <TableRow key={d.category}>
                <StyledTableCell
                  sx={{
                    borderRight: "1px solid lightgray",
                    fontWeight: "bold",
                  }}
                  align="center"
                >
                  {d.category}
                </StyledTableCell>
                <StyledTableCell>
                  <TableContainer>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.primary.dark
                              : theme.palette.primary.light,
                        }}
                      >
                        <StyledTableCell align="center">
                          Description
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </TableHead>
                      <TableBody>
                        {d.subFamilies?.map((k, i) => (
                          <StyledTableRow key={k.subFamilyId}>
                            <StyledTableCell
                              sx={{ border: "none" }}
                              align="center"
                            >
                              {selectedWarehouse?.length !== 1
                                ? `${k.subFamilyName} - (${k.warehouseCode})`
                                : `${k.subFamilyName}`}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ border: "none", fontWeight: "bold" }}
                              align="center"
                            >
                              {k.count}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ border: "none" }}
                              align="center"
                            >
                              <ShowComponent action={"view"} module="Inventory">
                                <IconButton
                                  onClick={() =>
                                    handleDownloadReport(k.subFamilyId)
                                  }
                                  disabled={
                                    !k.count ||
                                    downloadingReport.includes(k.subFamilyId)
                                  }
                                >
                                  {downloadingReport.includes(k.subFamilyId) ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <DownloadRoundedIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </ShowComponent>
                              <ShowComponent action={"view"} module="Inventory">
                                <Tooltip title="view">
                                  <IconButton
                                    disabled={!k.count}
                                    onClick={() =>
                                      handleViewClick(k.subFamilyId, k)
                                    }
                                  >
                                    <RemoveRedEyeRoundedIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </ShowComponent>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Total used stock
              </StyledTableCell>
              <StyledTableCell
                sx={{ fontWeight: "bold", paddingRight: 2 }}
                align="right"
              >
                {sumValues(data)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell sx={{ fontWeight: "bold" }}>
                Download total used stock
              </StyledTableCell>
              <StyledTableCell sx={{ fontWeight: "bold" }} align="right">
                <ShowComponent action={"view"} module="Inventory">
                  <IconButton
                    onClick={() => handleDownloadReport()}
                    disabled={!sumValues(data) || downloadingAll}
                  >
                    {downloadingAll ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DownloadRoundedIcon fontSize="small" />
                    )}
                  </IconButton>
                </ShowComponent>
              </StyledTableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
