export const updateStatusKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Is Default Status",
    key: "isDefault",
  },
];
