import React, { useContext, useEffect, useState, useRef } from "react";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import URLS from "../../services/urlConstants";
import WarehouseContext from "../../context/WarehouseContext";
import { del, get, post, put } from "../../services/apiMethods";
import PageHeader from "../../components/PageHeader";
import { useFormik } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormInput from "../../forms/FormInput";
import FormDialog from "../../forms/FormDialog";
import FormSelect from "../../forms/FormSelect";
import { dcTableKeys } from "../../data/TableData/dc";
import CustomTable from "../../components/Tables/CustomTable";
import { Close } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import logo from "../../assets/images/companylogo.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import FormDatePicker from "../../forms/FormDatePicker";
import CustomSearch from "../../components/Tables/CustomSearch";
import { getDateRange } from "../../utils/utilities";
import dayjs from "dayjs";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const SectionBox = styled(Box)({
  "@media print": {
    margin: "0px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    pageBreakInside: "avoid",
    breakInside: "avoid",
    zoom: 0.85,
    // If you want to ensure headings and text are in black
    // "& .MuiTypography-root": {
    //   color: "#000",
    // },
    // "& .MuiTableCell-root": {
    //   color: "#000",
    // },
  },
});

const SubBox = styled(Box)({
  margin: "15px",
  border: "2px solid black",
  boxSizing: "border-box",
});

const SectionTitle = styled(Typography)({
  fontWeight: "bold",
  borderBottom: "2px solid #000",
  // borderRight: "2px solid #000",
  padding: "8px",
});

const TableCellStyled = styled(TableCell)({
  fontWeight: "bold",
  border: "2px solid black",
});

const TotalRow = styled(TableRow)({
  textAlign: "right",
  fontWeight: "bold",
  border: "2px solid black",
});

const SignatureBox = styled(Box)({
  paddingBottom: "40px",
});

export default function CreateDC() {
  const {
    allWarehouses,
    selectedOwnerCode,
    selectedWarehouse,
    selectedOwnerGroup,
  } = useContext(WarehouseContext);
  const componentRef = useRef();
  const [pickLists, setPickLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [ownerGroups, setOwnerGroups] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [openViewModal, setViewOpenModal] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [pufferLoading, setPufferLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const handlePrint = useReactToPrint({ contentRef: componentRef });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const openModal = () => setOpen(true);

  // directories

  const [directories, setDirectories] = useState([]);
  const [pinCodes, setPinCodes] = useState([]);
  const [names, setNames] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [contactNumbers, setContactNumbers] = useState([]);

  useEffect(() => {
    setWarehouses(
      allWarehouses?.map((s) => ({
        ...s,
        label: s.code,
        value: s._id,
      })) || []
    );
    if (allWarehouses?.length === 1) {
      formik.setFieldValue("warehouse", allWarehouses[0]?._id);
    }
  }, [allWarehouses]);

  const getOwnerGroup = async () => {
    try {
      let filter = {};
      if (formik.values.warehouse) {
        filter = { warehouse: formik.values.warehouse };
      }
      const { data } = await get(URLS.ownerGroup.list, {
        params: filter,
      });
      let newGroups = data.result.map((r) => ({
        ...r,
        label: r.name,
        value: r._id,
      }));
      setOwnerGroups(newGroups);
      formik.setFieldValue("ownerGroup", "");
      if (newGroups.length === 1) {
        formik.setFieldValue("ownerGroup", newGroups[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      let filter = {};
      if (formik.values.ownerGroup) {
        filter = { ownerGroup: formik.values.ownerGroup };
      }
      const { data } = await get(URLS.ownerCode.list, {
        params: filter,
      });
      let newCodes = data.result.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      }));
      setOwnerCodes(newCodes);
      formik.setFieldValue("ownerCode", "");

      if (newCodes?.length === 1) {
        formik.setFieldValue("ownerCode", newCodes[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDataToEdit(null);
    formik.resetForm();
  };

  const handleCloseViewModal = () => {
    setViewOpenModal(false);
  };

  const getAllPickList = async () => {
    try {
      const { data } = await get(URLS.pickList.getAllPickListWithoutDc, {
        params: {
          warehouseCode: formik.values.warehouse,
          ownerGroup: formik.values.ownerGroup,
          ownerCode: formik.values.ownerCode,
        },
      });

      setPickLists(
        data.result.map((d) => ({
          label: d.gmsPickListCode,
          value: d._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateAndUpdate = async (values) => {
    if (
      !values.pickList ||
      !values.name ||
      !values.address ||
      !values.contactNumber ||
      !values.pinCode
    )
      return toast.error("Please fill all fields!");
    setLoading(true);

    try {
      const payload = {
        pickList: values.pickList,
        reasonForShipment: values.reasonForShipment,
        itemDetails: itemDetails,
        shipTo: {
          name: values.name,
          address: values.address,
          contactNumber: values.contactNumber,
          pinCode: values.pinCode,
        },
      };

      const { data } = await post(URLS.dc.create, payload);
      getData();

      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      warehouse: "",
      ownerGroup: "",
      ownerCode: "",
      pickList: "",
      name: "",
      address: "",
      pinCode: "",
      contactNumber: "",
      reasonForShipment: "",
    },
    onSubmit: handleCreateAndUpdate,
    enableReinitialize: true,
  });

  const getDirectories = async () => {
    try {
      const { data } = await get(URLS.directory.list, {
        params: {
          ownerCode: formik.values.ownerCode,
        },
      });
      let dirs = data.result?.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      }));
      setDirectories(dirs);
      setPinCodes(dirs.flatMap((d) => d?.addresses.map((add) => add.pinCode)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formik.setFieldValue("name", "");
    setNames(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.pinCode === formik.values.pinCode)
          ?.map((add) => add.name)
      )
    );
  }, [formik.values.pinCode, directories]);

  useEffect(() => {
    formik.setFieldValue("address", "");

    setAddresses(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.name === formik.values.name)
          ?.map((add) => add.address)
      )
    );
  }, [formik.values.name, directories]);

  useEffect(() => {
    formik.setFieldValue("contactNumber", "");

    setContactNumbers(
      directories.flatMap((d) =>
        d?.addresses
          ?.filter((add) => add.address === formik.values.address)
          ?.map((add) => add.contactNumber)
      )
    );
  }, [formik.values.address, directories]);

  useEffect(() => {
    if (formik.values.ownerCode) {
      getDirectories();
    } else {
      setDirectories([]);
      setPinCodes([]);
    }
  }, [formik.values.ownerCode]);

  React.useEffect(() => {
    if (formik.values.warehouse) {
      getOwnerGroup();
    } else {
      formik.setFieldValue("ownerGroup", "");
      setOwnerGroups([]);
    }
  }, [formik.values.warehouse]);

  React.useEffect(() => {
    if (formik.values.ownerGroup) {
      getOwnerCodes();
    } else {
      formik.setFieldValue("ownerCode", "");
      setOwnerCodes([]);
    }
  }, [formik.values.ownerGroup]);

  useEffect(() => {
    if (
      formik.values.warehouse &&
      formik.values.ownerGroup &&
      formik.values.ownerCode
    ) {
      getAllPickList();
    }
  }, [
    formik.values.warehouse,
    formik.values.ownerGroup,
    formik.values.ownerCode,
  ]);

  const getItemDetails = async () => {
    try {
      setPufferLoading(true);
      const { data } = await get(URLS.dc.getItemDetails, {
        params: { pickList: formik.values.pickList },
      });
      setItemDetails(data.result);
      setPufferLoading(false);
      // console.log(data.result, "item Details");
    } catch (error) {
      setPufferLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (formik.values.pickList) {
      getItemDetails();
    }
  }, [formik.values.pickList]);

  const handleDelete = async (id) => {
    try {
      const res = await del(URLS.dc.delete + "/" + id);
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowData = (data) => {
    setDataToEdit(data);
    setViewOpenModal(true);
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;
    let newItemDetails = itemDetails.map((f, i) =>
      f.currentItemCategory === itemDetails[index]?.currentItemCategory &&
      f.description === itemDetails[index]?.description
        ? { ...f, [name]: value, amount: value }
        : f
    );

    setItemDetails(newItemDetails);
  };
  useEffect(() => {
    getData();
  }, [selectedWarehouse, selectedOwnerCode, selectedOwnerGroup]);

  const getData = async () => {
    setFetchingData(true);
    try {
      const { data } = await get(URLS.dc.list, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
      });

      let res = data.result
        .map((d) => ({
          ...d,
          createDate: d.createdAt,
          pickListCode: d.pickList.gmsPickListCode,
        }))
        .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

      setData(res);
      setFilteredData(res);

      setFetchingData(false);
    } catch (error) {
      setFetchingData(false);
      console.error("Error fetching data:", error);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      dcNumber: "",
      fromDate: null,
      toDate: null,
    },
  });

  const handleFind = () => {
    const { endOfDay, startOfDay } = getDateRange(
      entryFormik.values.fromDate,
      entryFormik.values.toDate
    );

    let newList = data.filter(
      (p) =>
        dayjs(new Date(p.createDate)).diff(new Date(startOfDay), "s") > 0 &&
        dayjs(new Date(p.createDate)).diff(new Date(endOfDay), "s") < 0
    );
    setFilteredData(newList);
  };

  const resetFilter = () => {
    setSearch("");
    setFilteredData(data);
    entryFormik.resetForm();
  };

  useEffect(() => {
    if (search?.trim()?.length) {
      setFilteredData(
        data.filter((d) =>
          d.dcNo?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [search]);
  return (
    <>
      <DashBoardPageHeaderPage title="Create DC (Delivery Challan)" />

      <PageHeader
        onAddClick={openModal}
        title=""
        modelName="Create-Dc"
        module="Create-Dc"
        showAddField={false}
      />

      {fetchingData ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <Grid rowSpacing={1} columnSpacing={2} container mb={2}>
            <Grid xs={12} sm={6} md={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="From Date"
                name="fromDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <FormDatePicker
                formik={entryFormik}
                label="To Date"
                name="toDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={3} item>
              <Button
                size="small"
                disabled={
                  !entryFormik.values.fromDate || !entryFormik.values.toDate
                }
                onClick={handleFind}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Find
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={resetFilter}
                sx={{ mt: 2, ml: 1 }}
              >
                Clear Filter
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            mb={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xs={12} md={6} lg={3}>
              {/* <CustomSearch
                data={data}
                setSearch={setSearch}
                setSearchFilter={setSearchFilter}
                search={search}
                placeholder="DC Number"
                keys={["dcNo"]}
              /> */}

              <CustomInput
                label="Search DC"
                name="search-dc"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>

          <CustomTable
            actions={["delete", "view"]}
            bodyDataModal="dc"
            bodyData={filteredData}
            tableKeys={dcTableKeys}
            onDeleteClick={handleDelete}
            onViewClick={handleShowData}
            module="Create-Dc"
          />
        </>
      )}

      <CustomDialog
        fullScreen={fullScreen}
        open={openViewModal}
        onClose={handleCloseViewModal}
      >
        <DialogTitle
          sx={{
            fontSize: "15px",
            ml: "-10px",

            color: (theme) =>
              theme.palette.mode === "dark" ? "#fff" : "#33669B",
          }}
        >
          View Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseViewModal}
          sx={{
            position: "absolute",
            right: 5,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
        <Divider />
        <SectionBox sx={{ zoom: 0.8 }} ref={componentRef}>
          <SubBox>
            <Grid container>
              <Grid
                item
                xs={7}
                sx={{
                  borderBottom: "2px solid #000",
                  backgroundColor: "#33669B",
                }}
              ></Grid>
              <Grid item xs={5}>
                <Typography
                  fontWeight="bold"
                  py={1}
                  sx={{
                    fontSize: "17px",
                    backgroundColor: "#33669B",
                    borderBottom: "2px solid #000",
                    textAlign: "center",
                    color: "#fff",
                    "@media print": {
                      color: "#fff",
                    },
                  }}
                >
                  Original For Recipient
                </Typography>
              </Grid>
              <Grid item xs={7} sx={{ borderRight: "2px solid #000" }}>
                <Typography
                  variant="body1"
                  p={0.5}
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  <b style={{ fontSize: "16px" }}>
                    {" "}
                    Computacenter India Private Limited
                  </b>
                  <br />
                  CoWrks India Private Limited
                  <br />
                  CoWrks Residency Road No: 135/1
                  <br />
                  Purva Premiere, Ward No: 76
                  <br />
                  Bangalore, Karnataka
                  <br />
                  560025
                </Typography>

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  p={0.5}
                  mt={2}
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  CIN: U74900KA2013FTC069471
                  <br />
                  GSTIN: 29AAFCC2892L1Z4
                  <br />
                  PAN: AAFCC2892L
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={logo}
                    alt="Company Logo"
                    style={{
                      width: "150px",
                      objectFit: "contain",
                      padding: "10px 0px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 2,
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",
                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                        variant="h6"
                        fontWeight="bold"
                        fontSize={"18px"}
                      >
                        Delivery Challan
                      </Typography>

                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",

                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                      >
                        DC No: {dataToEdit?.dcNo}
                      </Typography>
                      <Typography
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark" ? "#fff" : "#33669B",
                          "@media print": {
                            color: "#33669B",
                          },
                        }}
                      >
                        Date:{" "}
                        {new Date(dataToEdit?.createdAt).toLocaleString(
                          "en-IN",
                          { timeZone: "Asia/Kolkata" }
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box>
              <Box sx={{ borderBottom: "2px solid black" }} />
              <Typography
                p={0.5}
                sx={{
                  borderBottom: "2px solid #000",
                  borderRight: "2px solid #000",
                  maxWidth: "200px",
                  fontWeight: "bold",
                  backgroundColor: "#33669B",
                  color: "white",
                }}
              >
                Dispatch From:
              </Typography>
              <Typography
                p={0.5}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                <b> {dataToEdit?.dispatchFrom?.name}</b>
                <br />
                {dataToEdit?.dispatchFrom?.address}

                <br />
                {/* <b>GSTIN:{dataToEdit?.dispatchFrom?.gstIn || "NA"}</b> */}
              </Typography>
            </Box>

            <Box
              sx={{
                borderBottom: "2px solid #000",
              }}
            >
              <Box sx={{ borderBottom: "2px solid black", marginTop: 2 }} />
              <Typography
                p={0.5}
                sx={{
                  borderBottom: "2px solid #000",
                  borderRight: "2px solid #000",
                  maxWidth: "200px",
                  fontWeight: "bold",
                  backgroundColor: "#33669B",
                  color: "white",
                  "@media print": {
                    color: "#fff",
                  },
                }}
              >
                Ship To:
              </Typography>
              <Typography
                p={0.5}
                mb={2}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                <b> {dataToEdit?.shipTo?.name}</b>
                <br />
                {dataToEdit?.shipTo?.address}, Pincode:
                {dataToEdit?.shipTo?.pinCode}
                <br />
                {dataToEdit?.shipTo?.contactNumber},
                <br />
                {/* <b>GSTIN:{dataToEdit?.shipTo?.gstIn || "NA"}</b> */}
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  p={0.5}
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  <b>Reason For Shipment: </b> {dataToEdit?.reasonForShipment}
                </Typography>
              </Grid>
            </Grid>

            <TableContainer sx={{ px: 0.2 }}>
              <Table sx={{ borderCollapse: "collapse" }}>
                <TableHead
                  sx={{
                    backgroundColor: "#33669B",
                    color: "black",
                  }}
                >
                  <TableRow>
                    <TableCellStyled align="center">
                      Description
                    </TableCellStyled>
                    <TableCellStyled align="center">HSN</TableCellStyled>
                    <TableCellStyled align="center">UOM</TableCellStyled>
                    <TableCellStyled align="center">Qty.</TableCellStyled>
                    <TableCellStyled align="center">
                      Unit Rate (INR)
                    </TableCellStyled>
                    <TableCellStyled align="center">
                      Amount (INR)
                    </TableCellStyled>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToEdit?.itemDetails?.map((row, index) => (
                    <TableRow key={row._id}>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.description || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.hsn || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.uom || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.quantity || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.unitRate || "N/A"}
                      </TableCellStyled>
                      <TableCellStyled
                        align="center"
                        sx={{
                          "@media print": {
                            color: "#000",
                          },
                        }}
                      >
                        {row?.amount || "N/A"}
                      </TableCellStyled>
                    </TableRow>
                  ))}
                </TableBody>
                <TotalRow>
                  <TableCellStyled
                    colSpan={5}
                    sx={{
                      "@media print": {
                        color: "#000",
                      },
                    }}
                  >
                    Total (INR):
                  </TableCellStyled>
                  <TableCellStyled
                    align="center"
                    sx={{
                      backgroundColor: "#33669B",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    ₹ {new Intl.NumberFormat("en-IN").format(dataToEdit?.total)}
                  </TableCellStyled>
                </TotalRow>
              </Table>
            </TableContainer>

            <Grid container>
              <Grid item xs={12}>
                <SectionTitle
                  sx={{
                    "@media print": {
                      color: "#000",
                    },
                  }}
                >
                  Total In Words: {dataToEdit?.totalInWords}
                </SectionTitle>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  p={0.5}
                  sx={{
                    borderBottom: "2px solid #000",
                    borderRight: "2px solid #000",
                    fontWeight: "bold",
                    backgroundColor: "#33669B",
                    color: "white",
                  }}
                >
                  Serial Number(s):
                </Typography>
              </Grid>

              <Grid
                sx={{
                  wordWrap: "wrap",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                item
                xs={12}
                p={0.5}
              >
                {dataToEdit?.msns?.map((d) => (
                  <Typography
                    key={d}
                    sx={{
                      "@media print": {
                        color: "#000",
                      },
                    }}
                  >
                    {d},{" "}
                  </Typography>
                ))}
              </Grid>
            </Grid>

            <Typography
              sx={{
                borderBottom: "2px solid #000",
                padding: "8px",
                "@media print": {
                  color: "#000",
                },
              }}
            >
              {" "}
              <b> Note: Stock Types </b>
              {"  "} - {"  "}
              {dataToEdit?.stockTypes?.join(", ")}
            </Typography>

            <SignatureBox>
              <Typography
                p={0.5}
                sx={{
                  "@media print": {
                    color: "#000",
                  },
                }}
              >
                Authorised Signatory
              </Typography>
              <Typography></Typography>
            </SignatureBox>
          </SubBox>
        </SectionBox>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mr: 1, mb: 1 }}>
          {" "}
          <Button
            variant="contained"
            size="small"
            sx={{ maxWidth: "200px" }}
            onClick={handlePrint}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Box>
      </CustomDialog>

      <FormDialog
        open={open}
        adding={loading}
        onClose={handleClose}
        submitButtonTitle={"Add"}
        formik={formik}
        formTitle={"Add DC"}
        maxWidth={700}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              label="Warehouse"
              formik={formik}
              name="warehouse"
              options={warehouses}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="ownerGroup"
              formik={formik}
              label="Owner Group"
              options={ownerGroups.filter(
                (o) => o.warehouse?._id === formik.values.warehouse
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="ownerCode"
              formik={formik}
              label="Owner Code"
              options={ownerCodes.filter(
                (o) => o.ownerGroup?._id === formik.values.ownerGroup
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              label="PickList"
              formik={formik}
              name="pickList"
              options={pickLists}
            />
          </Grid>

          {formik.values.pickList && (
            <>
              {pufferLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <PuffLoader size={30} color="#14532d" />
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: 750,
                      pl: 2,
                      display: "flex",
                      justifyContent: "center",
                      overflowX: "auto",
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? theme.palette.primary.dark
                                : theme.palette.primary.light,
                          }}
                        >
                          <TableRow>
                            <TableCell align="center">S.No</TableCell>

                            <TableCell align="center">Item Category</TableCell>
                            <TableCell align="center">MSN</TableCell>
                            <TableCell align="center">Description</TableCell>

                            <TableCell align="center">HSN</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                            <TableCell align="center">Unit Rate</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {itemDetails.map((fd, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{index + 1}</TableCell>

                              <TableCell align="center">
                                {fd.currentItemCategory}
                              </TableCell>
                              <TableCell align="center">
                                {fd.msn?.toUpperCase()}
                              </TableCell>
                              <TableCell align="center">
                                {fd.description}
                              </TableCell>
                              <TableCell align="center">{fd.hsn}</TableCell>
                              <TableCell align="center">
                                {fd.quantity}
                              </TableCell>
                              <TableCell align="center">
                                <CustomInput
                                  onChange={(e) => handleChange(e, index)}
                                  name="unitRate"
                                  label="Unit Rate"
                                  value={fd.unitRate}
                                  required={true}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Typography mt={0.5} textAlign={"left"} fontWeight={"500"}>
              Ship To:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "1px solid lightgray",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-pinCode"
                    size="small"
                    options={pinCodes}
                    value={formik.values.pinCode}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("pinCode", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("pinCode", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Pincode"
                        name="pinCode"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-name"
                    size="small"
                    options={names}
                    value={formik.values.name}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("name", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("name", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Ship to Name"
                        name="name"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-address"
                    size="small"
                    options={addresses}
                    value={formik.values.address}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("address", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue("address", newInputValue || "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Ship to Address"
                        name="address"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    freeSolo
                    id="free-solo-demo-contactnumber"
                    size="small"
                    options={contactNumbers}
                    value={formik.values.contactNumber}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("contactNumber", newValue || "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        formik.setFieldValue(
                          "contactNumber",
                          newInputValue || ""
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Contact Number"
                        name="contactNumber"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormInput
                    label="Reason For Shipment"
                    formik={formik}
                    name="reasonForShipment"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
}
