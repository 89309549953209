export const addressKeys = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Contact Number",
    key: "contactNumber",
  },
  {
    name: "Pincode",
    key: "pinCode",
  },
  {
    name: "Address",
    key: "address",
  },
];
