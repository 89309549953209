export const directoryKeys = [
  {
    name: "Onwer Code",
    key: "ownerCodeName",
  },
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Total Addresses",
    key: "totalAddresses",
  },
];
