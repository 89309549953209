import React from "react";
import image from "../assets/images/deleteicon.png";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export default function DeleteModal({ open, handleClose, loading, onSubmit }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ textAlign: "center", margin: "10px auto" }}>
          <img src={image} width={50} height={50} />
        </Box>

        <Typography
          textAlign="center"
          sx={{ fontSize: "18px", fontWeight: 700 }}
        >
          Delete Confirmation
        </Typography>
        <Typography sx={{ mt: 2, textAlign: "center" }}>
          Proceeding with the deletion of this data may result in information
          inconsistency across other documents and is irreversible. Are you
          certain you wish to delete this item?
        </Typography>
        <Box
          my={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            onClick={onSubmit}
            loading={loading}
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
