import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
  Paper,
  Grid,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import { get } from "../../services/apiMethods";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFormik } from "formik";
import dayjs from "dayjs";
import FormDatePicker from "../../forms/FormDatePicker";
import FormDialogDashboard from "../../forms/FormDailogDashboard";
import WarehouseContext from "../../context/WarehouseContext";
import UserContext from "../../context/UserContext";

const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  padding: "15px 10px",
  marginBottom: "20px",
}));

export default function DashBoardPageHeaderPage({ title = "" }) {
  const { user } = useContext(UserContext);
  const {
    selectedWarehouse,
    setSelectedWarehouse,
    allWarehouses,
    allOwnerCodes,
    selectedOwnerCode,
    setSelectedOwnerCode,
    allOwnerGroups,
    selectedOwnerGroup,
    setSelectedOwnerGroup,
  } = useContext(WarehouseContext);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (
  //     !selectedWarehouse?.length ||
  //     !selectedOwnerCode?.length ||
  //     !selectedOwnerGroup?.length
  //   ) {
  //     alert("Please select a warehouse, owner group and owner code");
  //   }
  // }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (allWarehouses.length == 1) {
      setSelectedWarehouse([allWarehouses[0]?._id]);
    }
  }, [allWarehouses]);

  useEffect(() => {
    if (allOwnerGroups.length == 1) {
      setSelectedOwnerGroup([allOwnerGroups[0]?._id]);
    }
  }, [allOwnerGroups]);

  useEffect(() => {
    if (allOwnerCodes.length == 1) {
      setSelectedOwnerCode([allOwnerCodes[0]?._id]);
    }
  }, [allOwnerCodes]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      formik.resetForm();
    }, 200);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "warehouseName") {
      setSelectedWarehouse(value);
    } else if (name === "onwerGroup") {
      setSelectedOwnerGroup(value);
    } else {
      setSelectedOwnerCode(value);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await get();
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      fromDate: dayjs(new Date()),
      toDate: dayjs(new Date()),
    },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Wrapper
        sx={{
          borderRadius: 2,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "rgba(255,255,255,0.3)"
              : "whitesmoke",
        }}
        elevation={0}
      >
        <Typography component="h1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
          <>
            <FormControl fullWidth size="small" sx={{ minWidth: "200px" }}>
              <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
              <Select
                labelId="warehouse-select-label"
                value={selectedWarehouse} // This should be an array for multiple selection
                name="warehouseName"
                size="small"
                disabled={allWarehouses?.length === 1}
                onChange={handleChange}
                multiple
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        allWarehouses.find(
                          (warehouse) => warehouse._id === value
                        )?.code
                    )
                    .join(", ")
                }
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} fontSize="medium" />
                )}
                label="Warehouse"
              >
                {allWarehouses.map((w) => (
                  <MenuItem key={w._id} value={w._id}>
                    <ListItemIcon>
                      <Checkbox
                        size="small"
                        checked={selectedWarehouse.indexOf(w._id) > -1}
                      />
                    </ListItemIcon>
                    {/* {`${w.name} - ${w.code}`} */}

                    {w.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" sx={{ minWidth: "200px" }}>
              <InputLabel id="demo-simple-select-label">
                Customer Group
              </InputLabel>
              <Select
                value={selectedOwnerGroup}
                name="onwerGroup"
                size="small"
                disabled={allOwnerGroups?.length === 1}
                onChange={handleChange}
                multiple={true}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        allOwnerGroups.find((group) => group._id === value)
                          ?.name
                    )
                    .join(", ")
                }
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} fontSize="medium" />
                )}
                label="Customer Group"
              >
                {allOwnerGroups?.map((w, i) => (
                  <MenuItem key={w._id} value={w._id}>
                    <ListItemIcon>
                      <Checkbox
                        size="small"
                        checked={selectedOwnerGroup.indexOf(w._id) > -1}
                      />
                    </ListItemIcon>
                    {w.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" sx={{ minWidth: "200px" }}>
              <InputLabel id="demo-simple-select-label">Customer</InputLabel>
              <Select
                value={selectedOwnerCode}
                name="customerName"
                size="small"
                disabled={allOwnerCodes?.length === 1}
                onChange={handleChange}
                multiple={true}
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        allOwnerCodes.find((code) => code._id === value)?.name
                    )
                    .join(", ")
                }
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} fontSize="medium" />
                )}
                label="Customer"
              >
                {allOwnerCodes?.map((w, i) => (
                  <MenuItem key={w._id} value={w._id}>
                    <ListItemIcon>
                      <Checkbox
                        size="small"
                        checked={selectedOwnerCode.indexOf(w._id) > -1}
                      />
                    </ListItemIcon>
                    {w.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </Stack>

        <FormDialogDashboard
          open={open}
          formik={formik}
          formTitle="Filter date"
          onClose={handleClose}
          submitButtonTitle="Apply"
        >
          <Grid rowSpacing={1} columnSpacing={2} container>
            <Grid xs={12} sm={6} md={6} item>
              <FormDatePicker
                formik={formik}
                label="From Date"
                name="fromDate"
              />
            </Grid>
            <Grid xs={12} sm={6} md={6} item>
              <FormDatePicker formik={formik} label="To Date" name="toDate" />
            </Grid>
          </Grid>
        </FormDialogDashboard>
      </Wrapper>
    </>
  );
}
