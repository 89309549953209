import { Box, Grid, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CustomSelect from "./SecondaryForms/CustomSelect";
import { get, post } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import { Download } from "@mui/icons-material";
import { downloadExcel } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";
import { PuffLoader } from "react-spinners";
import CustomSelectVirtualized from "../components/SecondaryForms/CustomSelectVirtualized";
import { toast } from "react-toastify";

export default function ItemHistoryReport() {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [downloading, setDownloading] = useState(false);
  const [pufferLoading, setPufferLoading] = useState(false);
  const [msnList, setMsnList] = useState([]);
  const [allMsns, setAllMsns] = useState([]);

  const getAllItemHistory = async () => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setPufferLoading(true);
      const { data } = await get(URLS.items.getItemsForHistory, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
      });

      let initialValues = data.result.map((d) => ({
        label: d.msn,
        value: d._id,
      }));

      setAllMsns(initialValues);
      setPufferLoading(false);
    } catch (error) {
      console.log(error);
      setPufferLoading(false);
    }
  };

  const handleMsnChange = (e) => {
    const { value } = e.target;
    setMsnList(value);
  };

  useEffect(() => {
    getAllItemHistory();
  }, [selectedOwnerCode, selectedWarehouse]);

  const handleDownloadHistory = async () => {
    try {
      if (!msnList.length) return;

      setDownloading(true);
      const { data } = await post(
        URLS.itemHistory.downloadHistory,
        { itemIds: msnList },
        {
          responseType: "blob",
        }
      );
      setDownloading(false);

      downloadExcel(data, `Item-history.xlsx`);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  return (
    <>
      {pufferLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <Stack
          component="form"
          direction="row"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={3}>
            {" "}
            <CustomSelectVirtualized
              onChange={handleMsnChange}
              label="Select MSN"
              value={msnList}
              required={true}
              multiple={true}
              options={allMsns}
            />
          </Grid>
          {msnList.length ? (
            <Grid item xs={12} md={3} alignSelf={"center"}>
              <LoadingButton
                fullWidth
                sx={{ maxWidth: "150px" }}
                loading={downloading}
                size="small"
                onClick={handleDownloadHistory}
                variant="contained"
                startIcon={<Download fontSize="small" />}
              >
                History
              </LoadingButton>
            </Grid>
          ) : null}
        </Stack>
      )}
    </>
  );
}
