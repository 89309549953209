import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  styled,
  useTheme,
  CardHeader,
  CardContent,
  Card,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  ButtonGroup,
  Container,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import FormDialog from "../../forms/FormDialog";
import { useFormik } from "formik";
import FormSelect from "../../forms/FormSelect";
import FormInput from "../../forms/FormInput";
import {
  PROPERTIES_MAPPING,
  GROUP_BY_MAPPINGS,
  GROUP_DATA_MAPPING,
} from "../../data/dashboardFilterData";
import { get, del, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WarehouseContext from "../../context/WarehouseContext";
import DeleteModal from "../../forms/DeleteModal";
import AddIcon from "@mui/icons-material/Add";
import { PuffLoader } from "react-spinners";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { debounce } from "lodash";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#A28EFF",
  "#F95F62",
  "#FEB144",
  "#4BC0C8",
  "#FFD700",
  "#FF69B4",
  "#CD5C5C",
  "#40E0D0",
  "#4682B4",
  "#9370DB",
  "#FF7F50",
  "#2E8B57",
  "#7FFFD4",
  "#B22222",
  "#DA70D6",
  "#FA8072",
  "#6495ED",
  "#FF6347",
  "#8B4513",
  "#6A5ACD",
  "#48D1CC",
  "#FF4500",
  "#00FA9A",
  "#1E90FF",
  "#F4A460",
  "#228B22",
  "#FFDAB9",
  "#9932CC",
  "#8FBC8F",
  "#E9967A",
  "#BDB76B",
  "#708090",
  "#FF1493",
  "#8A2BE2",
  "#00BFFF",
];

const CHART_TYPE_OPTIONS = [
  { label: "Pie Chart", value: "pie" },
  { label: "Bar Chart", value: "bar" },
];

export default function AddTab({ title }) {
  const theme = useTheme();
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [propertiesData, setPropertiesData] = useState([]);
  const [groupOption, setGroupOption] = useState([]);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [dashboardData, setDashboardData] = useState([]);
  const [deleting, setDeleteing] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteOpenModal, setDeleteOpenModal] = useState(null);
  const [pufferLoading, setPufferLoading] = useState(true);

  let current_user = window.localStorage.getItem("current_inventory_user");
  let current_user_data = JSON.parse(current_user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataToEdit(null);
    formik.resetForm();
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteOpenModal(true);
    setDeleteItem(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteOpenModal(false);
    setDeleteItem(null);
  };

  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
      };
      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.employee.updateDashboardFilter + "/" + dataToEdit._id,
          payload
        );

        window.localStorage.setItem(
          "current_inventory_user",
          JSON.stringify(data.result)
        );
      } else {
        const { data } = await post(
          URLS.employee.createDashboardFilter,
          payload
        );
        window.localStorage.setItem(
          "current_inventory_user",
          JSON.stringify(data.result)
        );
      }
      getData();
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      lookUpCollection: dataToEdit?.lookUpCollection || "",
      tabName: dataToEdit?.tabName || "",
      groupBy: dataToEdit?.groupBy || "",
      subGroupBy: dataToEdit?.subGroupBy || "",
      groupByChartType: dataToEdit?.groupByChartType || "",
      subGroupByChartType: dataToEdit?.subGroupByChartType || "",
      // showFields: dataToEdit?.showFields || [],
      orderSequence: dataToEdit?.orderSequence ? dataToEdit?.orderSequence : 1,
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.lookUpCollection) {
      setPropertiesData(
        PROPERTIES_MAPPING[formik.values.lookUpCollection] || []
      );
      setGroupOption(GROUP_BY_MAPPINGS[formik.values.lookUpCollection] || []);
    }
  }, [formik.values.lookUpCollection]);

  const handleEdit = (data) => {
    setDataToEdit(data);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      setDeleteing(true);
      const { data } = await del(
        URLS.employee.deleteDashboardFilter + "/" + deleteItem
      );

      window.localStorage.setItem(
        "current_inventory_user",
        JSON.stringify(data.result)
      );
      setDeleteing(false);
      handleCloseDeleteModal();
    } catch (error) {
      setDeleteing(false);
      console.error(error);
    }
  };

  // const getData = async () => {

  //   setPufferLoading(true);
  //   try {
  //     const { data } = await get(URLS.items.getGroupedItems, {
  //       params: {
  //         warehouses: selectedWarehouse,
  //         ownerGroups: selectedOwnerGroup,
  //         ownerCodes: selectedOwnerCode,
  //       },
  //     });

  //     setDashboardData(data.result);
  //     setPufferLoading(false);
  //   } catch (error) {
  //     setPufferLoading(false);
  //     console.log(error);
  //   }
  // };

  const getData = useCallback(
    debounce(async (warehouses, ownerGroups, ownerCodes) => {
      setPufferLoading(true);
      try {
        const { data } = await get(URLS.items.getGroupedItems, {
          params: {
            warehouses,
            ownerGroups,
            ownerCodes,
          },
        });
        setDashboardData(data.result);
      } catch (error) {
        console.error(error);
      } finally {
        setPufferLoading(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (
      selectedWarehouse?.length ||
      selectedOwnerGroup?.length ||
      selectedOwnerCode?.length
    ) {
      getData(selectedWarehouse, selectedOwnerGroup, selectedOwnerCode);
    }
  }, [selectedWarehouse, selectedOwnerGroup, selectedOwnerCode, getData]);

  if (!selectedWarehouse?.length)
    return (
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            mt: 20,
            padding: 5,
            borderRadius: 5,
            background:
              theme.palette.mode === "dark" ? "rgba(0,0,0,0.3)" : "whitesmoke",
          }}
        >
          Please select a warehouse to show data!
        </Typography>
      </Container>
    );

  return (
    <>
      {pufferLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              justifyContent: "flex-end",
              width: "100%",
              mb: 2,
            }}
          >
            {/* <ButtonGroup>
              {" "}
              <Button
                size="small"
                variant={chartType === "pie" ? "contained" : "outlined"}
                onClick={() => setChartType("pie")}
              >
                Pie Chart
              </Button>
              <Button
                size="small"
                variant={chartType === "bar" ? "contained" : "outlined"}
                onClick={() => setChartType("bar")}
              >
                Bar Chart
              </Button>
            </ButtonGroup> */}
            <Button
              startIcon={<AddIcon fontSize="small" />}
              size="small"
              onClick={handleOpen}
              variant="contained"
            >
              Add Tab
            </Button>
          </Box>

          <Grid container spacing={2}>
            {current_user_data.dashboardFilters
              .sort((a, b) => Number(a.orderSequence) - Number(b.orderSequence))
              .map((data, index) => (
                <Grid item xs={12} key={index}>
                  <Card
                    elevation={2}
                    sx={{
                      borderRadius: 3,
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? "rgba(255,255,255,0.08)"
                          : theme.palette.background.paper,
                      "&:hover": {
                        boxShadow: 2,
                      },
                    }}
                  >
                    <CardHeader
                      title={
                        <Typography
                          variant="h5"
                          fontWeight="bold"
                          sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "#fff"
                                : theme.palette.primary.main,
                          }}
                        >
                          {data.tabName.toUpperCase()}
                        </Typography>
                      }
                      action={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(data)}
                              size="small"
                              sx={{
                                color: (theme) => theme.palette.primary.main,
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => handleOpenDeleteModal(data._id)}
                              sx={{
                                color: (theme) => theme.palette.error.main,
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      }
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.grey[900]
                            : theme.palette.grey[100],
                        borderBottom: `1px solid ${
                          theme.palette.mode === "dark"
                            ? theme.palette.grey[800]
                            : theme.palette.grey[300]
                        }`,
                      }}
                    />
                    <Divider />
                    {dashboardData
                      .filter((s) => s.tabId === data._id)
                      .map((d) => (
                        <CardContent
                          key={d.tabId}
                          sx={{
                            padding: 2,
                            backgroundColor: (theme) =>
                              theme.palette.mode === "dark"
                                ? "rgba(255,255,255,0.08)"
                                : theme.palette.background.paper,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mb: 1,
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                                item
                                sm={6}
                                xs={12}
                                md={3}
                              >
                                <Typography
                                  fontWeight={"bold"}
                                  textAlign={"center"}
                                  pb={1}
                                >
                                  {d?.tabDetails?.groupBy
                                    ?.toString()
                                    ?.toUpperCase()}
                                </Typography>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "300px",
                                  }}
                                >
                                  <ResponsiveContainer
                                    width="100%"
                                    height="100%"
                                  >
                                    {data?.groupByChartType === "pie" ? (
                                      <PieChart>
                                        <Pie
                                          data={d.data.map((item) => ({
                                            name: Object.values(item._id || {})
                                              .join(", ")
                                              .toUpperCase(),
                                            value: item.totalCount,
                                          }))}
                                          cx="50%"
                                          cy="50%"
                                          labelLine={false}
                                          outerRadius={80}
                                          fill="#8884d8"
                                          dataKey="value"
                                        >
                                          {d.data.map((entry, index) => (
                                            <Cell
                                              key={`cell-${index}`}
                                              fill={
                                                COLORS[index % COLORS.length]
                                              }
                                            />
                                          ))}
                                        </Pie>
                                        <RechartsTooltip />
                                        <Legend
                                          layout="horizontal"
                                          verticalAlign="bottom"
                                          align="center"
                                          wrapperStyle={{
                                            minHeight: "50px",
                                            maxHeight: "70px",
                                            overflowY: "auto",
                                          }}
                                        />
                                      </PieChart>
                                    ) : (
                                      <BarChart
                                        data={d.data.map((item) => ({
                                          name: Object.values(item._id || {})
                                            .join(", ")
                                            .toUpperCase(),
                                          value: item.totalCount,
                                        }))}
                                        margin={{ left: -15 }}
                                      >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Bar dataKey="value" fill="#8884d8">
                                          {d.data.map((entry, index) => (
                                            <Cell
                                              key={`cell-${index}`}
                                              fill={
                                                COLORS[index % COLORS.length]
                                              }
                                            />
                                          ))}
                                        </Bar>
                                        {/* <XAxis
                                          dataKey="name"
                                          tickFormatter={(name) =>
                                            name.length > 10
                                              ? `${name.substring(0, 10)}...`
                                              : name
                                          }
                                        /> */}
                                        <YAxis />

                                        <RechartsTooltip
                                          content={({ active, payload }) => {
                                            if (
                                              active &&
                                              payload &&
                                              payload.length
                                            ) {
                                              const { name, value } =
                                                payload[0].payload;
                                              return (
                                                <div
                                                  className="custom-tooltip"
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    border: "1px solid #ccc",

                                                    padding: "10px",
                                                    boxShadow:
                                                      "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                    fontSize: "14px",
                                                    color: "#333",
                                                  }}
                                                >
                                                  <p
                                                    style={{ margin: 0 }}
                                                  >{`${name}: ${value}`}</p>
                                                </div>
                                              );
                                            }
                                            return null;
                                          }}
                                        />
                                        <Legend
                                          layout="horizontal"
                                          verticalAlign="bottom"
                                          align="center"
                                          wrapperStyle={{
                                            minHeight: "50px",
                                            maxHeight: "70px",
                                            overflowY: "auto",
                                            paddingLeft: "30px",
                                            paddingTop: "30px",
                                          }}
                                          payload={d.data.map(
                                            (item, index) => ({
                                              id: `legend-${index}`,
                                              value: Object.values(
                                                item._id || {}
                                              )
                                                .join(", ")
                                                .toUpperCase(),
                                              type: "square",
                                              color:
                                                COLORS[index % COLORS.length],
                                            })
                                          )}
                                        />
                                      </BarChart>
                                    )}
                                  </ResponsiveContainer>
                                </Box>
                              </Grid>

                              {(d.groupedDoc !== undefined &&
                                d.groupedDoc[0]?._id === "false") ||
                              d.groupedDoc[0]?._id == null ? null : (
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                  }}
                                  item
                                  xs={12}
                                  md={3}
                                  sm={6}
                                >
                                  <Typography
                                    fontWeight={"bold"}
                                    textAlign={"center"}
                                    pb={1}
                                  >
                                    {d?.tabDetails?.subGroupBy
                                      ?.toString()
                                      ?.toUpperCase()}
                                  </Typography>
                                  <Box
                                    sx={{
                                      width: "100%",
                                      height: "300px",
                                    }}
                                  >
                                    <ResponsiveContainer
                                      width="100%"
                                      height="100%"
                                    >
                                      {data?.subGroupByChartType === "pie" ? (
                                        <PieChart>
                                          <Pie
                                            data={d.groupedDoc.map((item) => ({
                                              name: item._id
                                                ?.toString()
                                                ?.toUpperCase(),
                                              value: item.totalSubGroupCount,
                                            }))}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                          >
                                            {d.groupedDoc.map(
                                              (entry, index) => (
                                                <Cell
                                                  key={`cell-${index}`}
                                                  fill={
                                                    COLORS[
                                                      index % COLORS.length
                                                    ]
                                                  }
                                                />
                                              )
                                            )}
                                          </Pie>
                                          <RechartsTooltip />
                                          <Legend
                                            layout="horizontal"
                                            verticalAlign="bottom"
                                            align="center"
                                            wrapperStyle={{
                                              minHeight: "50px",
                                              maxHeight: "70px",
                                              overflowY: "auto",
                                            }}
                                          />
                                        </PieChart>
                                      ) : (
                                        <BarChart
                                          data={d.groupedDoc.map((item) => ({
                                            name: item._id
                                              ?.toString()
                                              ?.toUpperCase(),
                                            value: item.totalSubGroupCount,
                                          }))}
                                          margin={{ left: -15 }}
                                        >
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <Bar dataKey="value" fill="#8884d8">
                                            {d.groupedDoc.map(
                                              (entry, index) => (
                                                <Cell
                                                  key={`cell-${index}`}
                                                  fill={
                                                    COLORS[
                                                      index % COLORS.length
                                                    ]
                                                  }
                                                />
                                              )
                                            )}
                                          </Bar>
                                          {/* <XAxis
                                            dataKey="name"
                                            tickFormatter={(name) =>
                                              name.length > 10
                                                ? `${name.substring(0, 10)}...`
                                                : name
                                            }
                                          /> */}
                                          <YAxis />

                                          <RechartsTooltip
                                            content={({ active, payload }) => {
                                              if (
                                                active &&
                                                payload &&
                                                payload.length
                                              ) {
                                                const { name, value } =
                                                  payload[0].payload;
                                                return (
                                                  <div
                                                    className="custom-tooltip"
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      border: "1px solid #ccc",

                                                      padding: "10px",
                                                      boxShadow:
                                                        "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                      fontSize: "14px",
                                                      color: "#333",
                                                    }}
                                                  >
                                                    <p
                                                      style={{ margin: 0 }}
                                                    >{`${name}: ${value}`}</p>
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }}
                                          />
                                          <Legend
                                            layout="horizontal"
                                            verticalAlign="bottom"
                                            align="center"
                                            wrapperStyle={{
                                              minHeight: "50px",
                                              maxHeight: "70px",
                                              overflowY: "auto",
                                              paddingLeft: "30px",
                                              paddingTop: "30px",
                                            }}
                                            payload={d.groupedDoc.map(
                                              (item, index) => ({
                                                id: `legend-${index}`,
                                                value: item._id
                                                  ?.toString()
                                                  ?.toUpperCase(),

                                                type: "square",
                                                color:
                                                  COLORS[index % COLORS.length],
                                              })
                                            )}
                                          />
                                        </BarChart>
                                      )}
                                    </ResponsiveContainer>
                                  </Box>
                                </Grid>
                              )}

                              {/* Table */}
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                item
                                xs={12}
                                md={
                                  (d.groupedDoc !== undefined &&
                                    d.groupedDoc[0]?._id === "false") ||
                                  d.groupedDoc[0]?._id == null
                                    ? 9
                                    : 6
                                }
                                sm={12}
                              >
                                <TableContainer
                                  component={Paper}
                                  elevation={2}
                                  sx={{
                                    borderRadius: 2,
                                    overflowY: "auto",
                                    maxHeight: 500,
                                  }}
                                >
                                  <Table>
                                    <TableHead>
                                      <TableRow
                                        sx={{
                                          backgroundColor: (theme) =>
                                            theme.palette.mode === "dark"
                                              ? theme.palette.primary.dark
                                              : theme.palette.primary.light,
                                        }}
                                      >
                                        <StyledTableCell
                                          align="center"
                                          sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Group By (
                                          {d.tabDetails.groupBy
                                            .charAt(0)
                                            .toUpperCase() +
                                            d.tabDetails.groupBy
                                              .slice(1)
                                              .toLowerCase()}
                                          )
                                        </StyledTableCell>
                                        {d.tabDetails.subGroupBy ? (
                                          <StyledTableCell
                                            align="center"
                                            sx={{
                                              color: "white",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Sub Group By (
                                            {d.tabDetails.subGroupBy
                                              .charAt(0)
                                              .toUpperCase() +
                                              d.tabDetails.subGroupBy
                                                .slice(1)
                                                .toLowerCase()}
                                            )
                                          </StyledTableCell>
                                        ) : null}
                                        <StyledTableCell
                                          align="center"
                                          sx={{
                                            color: "white",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Count
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {d.data.map((row, rowIndex) =>
                                        row.groupedItems.map(
                                          (group, groupIndex) => (
                                            <TableRow
                                              key={`${rowIndex}-${groupIndex}`}
                                            >
                                              {groupIndex === 0 && (
                                                <StyledTableCell
                                                  align="center"
                                                  rowSpan={
                                                    row.groupedItems.length
                                                  }
                                                  sx={{
                                                    fontWeight: "bold",
                                                    borderRight:
                                                      "1px solid lightgray",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <Typography
                                                    fontWeight={"bold"}
                                                  >
                                                    {Object.values(
                                                      row._id || {}
                                                    )
                                                      .join(", ")
                                                      .toUpperCase()}{" "}
                                                    ({row.totalCount})
                                                  </Typography>
                                                </StyledTableCell>
                                              )}
                                              {d.tabDetails.subGroupBy ? (
                                                <StyledTableCell
                                                  align="center"
                                                  sx={{}}
                                                >
                                                  {typeof group.subGroupBy ===
                                                  "undefined"
                                                    ? "-"
                                                    : group?.subGroupBy
                                                        ?.toString()
                                                        ?.toUpperCase()}
                                                </StyledTableCell>
                                              ) : null}

                                              <StyledTableCell
                                                align="center"
                                                sx={{}}
                                              >
                                                {group.count}
                                              </StyledTableCell>
                                            </TableRow>
                                          )
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      ))}
                  </Card>
                </Grid>
              ))}
          </Grid>

          <FormDialog
            open={open}
            formik={formik}
            formTitle={
              dataToEdit ? "Update Dashboard Filter" : "Add Dashboard Filter"
            }
            onClose={handleClose}
            submitButtonTitle={dataToEdit ? "Update" : "Add"}
            adding={loading}
          >
            <Grid rowSpacing={1} columnSpacing={2} container>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  options={GROUP_DATA_MAPPING}
                  formik={formik}
                  name="lookUpCollection"
                  label="Look Up Collection"
                  required={true}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInput
                  name="tabName"
                  type="text"
                  required={true}
                  formik={formik}
                  label="Tab Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="groupBy"
                  type="text"
                  required={true}
                  options={groupOption}
                  formik={formik}
                  label="Group By"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="groupByChartType"
                  required={true}
                  options={CHART_TYPE_OPTIONS}
                  formik={formik}
                  label="Group By Chart Type"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="subGroupBy"
                  type="text"
                  formik={formik}
                  options={groupOption.filter(
                    (s) => s.value !== formik.values.groupBy
                  )}
                  label="Sub Group By"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="subGroupByChartType"
                  formik={formik}
                  options={CHART_TYPE_OPTIONS}
                  label="Sub Group By Chart Type"
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <FormSelect
                  options={propertiesData}
                  formik={formik}
                  name="showFields"
                  label="Show Fields"
                  multiple={true}
                  required={true}
                />
              </Grid> */}
              <Grid xs={12} sm={6} md={6} item>
                <FormInput
                  type="number"
                  formik={formik}
                  name="orderSequence"
                  label="Order Sequence"
                  required={true}
                />
              </Grid>
            </Grid>
          </FormDialog>

          <DeleteModal
            open={deleteOpenModal}
            handleClose={handleCloseDeleteModal}
            onSubmit={handleDelete}
            loading={deleting}
          />
        </>
      )}
    </>
  );
}
