import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import ThemeModeContext from "../../context/ThemeModeContext";
import React, {
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";

import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

function CustomSelect({
  value,
  name = "",
  label = "",
  options = [],
  onChange = () => {},
  disabled = false,
  required = false,
  multiple = false,
}) {
  const [blurred, setBlurred] = useState(false);

  var inputRef = useRef(undefined);
  const { isDarkMode } = useContext(ThemeModeContext);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText, options, blurred]
  );

  useEffect(() => {
    setSearchText("");
  }, [blurred]);

  const labelColor =
    disabled && !isDarkMode
      ? "gray"
      : !disabled && isDarkMode
      ? "white"
      : !disabled && !isDarkMode
      ? "black"
      : disabled && isDarkMode
      ? "gray"
      : "inherit";

  return (
    <FormControl
      margin="normal"
      required={required}
      variant="outlined"
      size="small"
      fullWidth
      sx={{ top: "4px" }}
    >
      <InputLabel sx={{ color: labelColor }} shrink={true} id={label}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        notched
        name={name}
        multiple={multiple}
        labelId={label}
        value={value !== undefined ? value : multiple ? [] : ""}
        label={label}
        onChange={onChange}
        onAnimationEnd={() => inputRef?.current?.focus()}
        MenuProps={{
          autoFocus: false,
          MenuListProps: { style: { maxHeight: 200 } },
        }}
        onBlur={() => setBlurred(!blurred)}
        renderValue={(selected) => {
          if (selected.includes("all")) {
            return "All";
          }
          const selectedOptions = options.filter((option) =>
            selected.includes(option.value)
          );
          return selectedOptions.map((option) => option.label).join(", ");
        }}
      >
        <ListSubheader>
          <TextField
            onBlur={() => {
              // Only reset search text if no option is being clicked
              setTimeout(() => setBlurred(true), 100);
            }}
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((s, i) => (
          <MenuItem key={`${s.value + i}`} value={s.value}>
            <ListItemIcon>
              <Checkbox
                size="small"
                checked={
                  multiple
                    ? Array.isArray(value) && value.includes(s.value)
                    : value === s.value
                }
              />
            </ListItemIcon>
            {s.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(CustomSelect);
