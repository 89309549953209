export const itemDescriptionTableKeys = [
  {
    name: "HSN",
    key: "hsn",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Price New",
    key: "priceNew",
  },
  {
    name: "Price Used",
    key: "priceUsed",
  },
];
