import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormSelect from "../../forms/FormSelect";
import FormInput from "../../forms/FormInput";
import { useFormik } from "formik";
import URLS from "../../services/urlConstants";
import { get } from "../../services/apiMethods";
import BundleNewUsedAndCombination from "./BundleNewUsedAndCombination";
import ManualBundling from "./ManualBundling";
import { getRandomSubset, hasAllValues } from "../../utils/utilities";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { Close, Remove } from "@mui/icons-material";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import CustomSelectVirtualized from "../../components/SecondaryForms/CustomSelectVirtualized";
import { PuffLoader } from "react-spinners";

const createUniqueBundles = (items, combinationData) => {
  console.log(combinationData, "combinationData");
  let bundles = [];

  let remainingItems = [...items];

  for (let item of remainingItems) {
    let bundle = [];

    for (let combo of combinationData) {
      let requiredItems = remainingItems.filter(
        (i) => i.subFamily._id == combo.subFamily._id
      );
      if (requiredItems.length && requiredItems.length >= combo.count) {
        bundle = [...bundle, ...requiredItems.slice(0, combo.count)];
        remainingItems = remainingItems.filter(
          (i) => ![...bundle.map((i) => i._id)].includes(i._id)
        );
      }
    }

    if (
      bundle.length ==
      combinationData.reduce((final, current) => final + current.count, 0)
    ) {
      bundles.push(bundle);
    }
  }

  return bundles;
};

const categoryType = [
  { label: "New Only", value: "new" },
  {
    label: "Used Only",
    value: "used",
  },
  {
    label: "Combination",
    value: "combination",
  },
  {
    label: "Manual",
    value: "manual",
  },
];

export default function Add() {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
    allOwnerCodes,
    allOwnerGroups,
  } = useContext(WarehouseContext);
  const theme = useTheme();
  const [fetchingData, setFetchingData] = useState();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [bundleType, setBundleType] = useState([]);
  const [items, setItems] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [ownerGroups, setOwnerGroups] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [selectedMsn, setSelectedMsn] = useState([]);
  const [manualItems, setManualItems] = useState([]);
  const [openItemSelectDialog, setOpenItemSelectDialog] = useState(false);
  const [stockType, setStockType] = useState([]);

  const [inStockItems, setInStockItems] = useState([]);

  const handleCloseItemSelect = () => {
    setManualItems([]);
    setOpenItemSelectDialog(false);
  };

  const combineItems = (values) => {
    if (!hasAllValues(values)) return;
    let filteredBundleData = [];

    let itemsToConside = items.filter(
      (i) => i.stockType?._id === searchFormik.values.stockTypeId
    );

    if (
      itemsToConside.length &&
      ["new", "used", "combination"].includes(values.category)
    ) {
      if (values.category) {
        if (values.category === "new") {
          filteredBundleData = itemsToConside.filter(
            (i) => i.itemCategory === "new"
          );
        } else if (values.category === "used") {
          filteredBundleData = itemsToConside.filter(
            (i) => i.itemCategory === "used"
          );
        } else if (values.category === "combination") {
          filteredBundleData = [...itemsToConside];
        } else if (values.category === "manual") {
          filteredBundleData = [];
        }
      }

      if (values.bundleCode) {
        let bundleInfo = bundleType.find((b) => b._id == values.bundleCode);

        let subFamiliesId = bundleInfo.bundleData.map((b) => b.subFamily._id);

        filteredBundleData = [
          ...filteredBundleData.filter((i) =>
            subFamiliesId.find((s) => s === i.subFamily._id)
          ),
        ];

        const finalBundleData = createUniqueBundles(
          filteredBundleData,
          bundleInfo.bundleData,
          formik
        );

        let bundleData = bundleInfo.bundleData;
        let subFamilyCountNeeded = {};
        for (let i = 0; i < bundleData.length; i++) {
          subFamilyCountNeeded[bundleData[i].subFamily._id] =
            bundleData[i].count * values.quantity;
        }

        const itemsInNeed = [];

        for (let key of Object.keys(subFamilyCountNeeded)) {
          let totalItemsAvailable = filteredBundleData.filter(
            (d) => d.subFamily?._id == key
          )?.length;

          if (totalItemsAvailable < subFamilyCountNeeded[key]) {
            let newItemInNeed = {
              name: bundleData.find((d) => d.subFamily?._id === key)?.subFamily,
              needed: subFamilyCountNeeded[key] - totalItemsAvailable,
            };
            itemsInNeed.push(newItemInNeed);
          }
        }

        if (itemsInNeed.length > 0) {
          toast.info(
            `Not enough items to create bundles for the selected items. Please add ${itemsInNeed
              .map((i) => i.needed + " " + i.name.name)
              .join(", ")}`
          );
          return;
        }

        const finalList = getRandomSubset(finalBundleData, values.quantity);
        if (!finalList.length)
          return toast.info(
            "Not enough items to create bundles for the selected bundle code!"
          );

        if (values.quantity > finalBundleData.length) {
          toast.info(
            `Maximum of ${finalBundleData.length} bundles is available to create!`
          );
        }

        setBundleData(getRandomSubset(finalBundleData, values.quantity));
      }
    } else {
      toast.info("No items to create bundle");
    }
  };

  const formik = useFormik({
    initialValues: {
      category: "",
      quantity: "",
      bundleCode: "",
    },
    onSubmit: combineItems,
  });

  const getAllItem = async (values) => {
    try {
      if (!values.warehouseId || !values.ownerCodeId || !values.ownerGroupId)
        return toast.error(
          "Please select warehouse, owner group and owner code!"
        );
      setFetchingData(true);
      const { data } = await get(URLS.items.getItemsForBundling, {
        params: {
          ...values,
        },
      });

      let allItems = data.result.flatItems;
      let allSubfamilies = data.result.allSubfamilies;
      let stockData = data.result.allItems;

      setItems(allItems);
      setInStockItems(
        allItems.map((i) => ({
          ...i,
          label: `${i.msn} -- ${i.stockType.name} -- ${i.subFamily.name}`,
          value: i.msn,
        }))
      );

      let usedItemsData =
        stockData.find((i) => i.itemCategory === "used")?.subFamilies || [];
      let newItemsData =
        stockData.find((i) => i.itemCategory === "new")?.subFamilies || [];

      let mappedData = [];
      let row1 = ["Assets & Categories", ...allSubfamilies.map((s) => s.name)];
      let row2 = [
        "New",
        ...allSubfamilies.map(
          (s) =>
            newItemsData.find((i) => i.subFamilyName === s.name)?.itemCount || 0
        ),
      ];
      let row3 = [
        "Used",
        ...allSubfamilies.map(
          (s) =>
            usedItemsData.find((i) => i.subFamilyName === s.name)?.itemCount ||
            0
        ),
      ];
      let row4 = [
        "Total",
        ...allSubfamilies.map(
          (s) =>
            allItems.filter((i) => i.subFamily.name === s.name)?.length || 0
        ),
      ];

      mappedData = [row1, row2, row3, row4];

      setItemData(mappedData);
      setFetchingData(false);
      formik.resetForm();
    } catch (error) {
      console.log(error);
      setFetchingData(false);
    }
  };

  const searchFormik = useFormik({
    initialValues: {
      warehouseId: "",
      ownerGroupId: "",
      stockTypeId: "",
      ownerCodeId: "",
    },
    onSubmit: getAllItem,
  });

  const getOwnerGroups = async () => {
    try {
      let filter = {};
      if (searchFormik.values.warehouseId) {
        filter = { warehouse: searchFormik.values.warehouseId };
      }
      const { data } = await get(URLS.ownerGroup.list, {
        params: filter,
      });
      let newGroups = data.result.map((r) => ({
        ...r,
        label: r.name,
        value: r._id,
      }));
      searchFormik.setFieldValue("ownerGroupId", "");
      setOwnerGroups(newGroups);
      if (data.result.length === 1) {
        searchFormik.setFieldValue("ownerGroupId", newGroups[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (searchFormik.values.warehouseId) {
      getOwnerGroups();
    } else {
      searchFormik.setFieldValue("ownerGroupId", "");
      setOwnerGroups([]);
    }
  }, [searchFormik.values.warehouseId]);

  const getOwnerCodes = async () => {
    try {
      let filter = {};
      if (searchFormik.values.ownerGroupId) {
        filter = { ownerGroup: searchFormik.values.ownerGroupId };
      }
      const { data } = await get(URLS.ownerCode.list, {
        params: filter,
      });
      let newCodes = data.result.map((d) => ({
        ...d,
        label: d.name,
        value: d._id,
      }));
      setOwnerCodes(newCodes);
      searchFormik.setFieldValue("ownerCodeId", "");
      if (data.result.length === 1) {
        searchFormik.setFieldValue("ownerCodeId", newCodes[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (searchFormik.values.ownerGroupId) {
      getOwnerCodes();
    } else {
      searchFormik.setFieldValue("ownerCodeId", "");
      setOwnerCodes([]);
    }
  }, [searchFormik.values.ownerGroupId]);

  useEffect(() => {
    setWarehouses(
      allWarehouses?.map((s) => ({
        ...s,
        label: s.code,
        value: s._id,
      })) || []
    );
    if (allWarehouses.length === 1) {
      searchFormik.setFieldValue("warehouseId", allWarehouses[0]?._id);
    }
  }, [allWarehouses]);

  const handleShowBundleInfoClick = () => {
    let bundleInfo = bundleType.find((b) => b._id == formik.values.bundleCode);

    let itemsInMultiple = bundleInfo?.bundleData?.reduce(
      (final, current) => final + current.count,
      0
    );

    let bundleData = bundleInfo?.bundleData || [];

    const itemsAvailable = {};

    for (let item of manualItems) {
      itemsAvailable[item.subFamily._id] = itemsAvailable[item.subFamily._id]
        ? itemsAvailable[item.subFamily._id] + 1
        : 1;
    }

    let itemInNeed = {};

    for (let i = 0; i < bundleData.length; i++) {
      let bundleDetails = bundleData[i];
      let subFamilyId = bundleDetails?.subFamily?._id;
      itemInNeed[subFamilyId] =
        itemsAvailable[subFamilyId] / bundleDetails?.count < 0
          ? 1
          : Math.ceil(itemsAvailable[subFamilyId] / bundleDetails?.count);
    }

    let quantityNeeded = 0;
    for (let key of Object.keys(itemInNeed)) {
      if (quantityNeeded < itemInNeed[key]) {
        quantityNeeded = itemInNeed[key];
      }
    }

    let subFamilyCountNeeded = {};
    for (let i = 0; i < bundleData.length; i++) {
      subFamilyCountNeeded[bundleData[i].subFamily._id] =
        bundleData[i].count * quantityNeeded;
    }

    const itemsInNeed = [];

    for (let key of Object.keys(subFamilyCountNeeded)) {
      let totalItemsAvailable = manualItems.filter(
        (d) => d.subFamily?._id == key
      )?.length;

      if (totalItemsAvailable < subFamilyCountNeeded[key]) {
        let newItemInNeed = {
          name: bundleData.find((d) => d.subFamily?._id === key)?.subFamily,
          needed: subFamilyCountNeeded[key] - totalItemsAvailable,
        };
        itemsInNeed.push(newItemInNeed);
      }
    }

    if (itemsInNeed.length > 0) {
      toast.info(
        `Not enough items to create bundles for the selected items. Please add ${itemsInNeed
          .map((i) => i.needed + " " + i.name.name)
          .join(", ")}`
      );
      return;
    }

    if (manualItems.length % itemsInMultiple !== 0) {
      return toast.info("Not enough items to create required bundle!");
    }
    const bundles = createUniqueBundles(manualItems, bundleInfo.bundleData);
    if (!bundles.length)
      return toast.info(
        "Not enough items to create bundles for the selected bundle code!"
      );
    setBundleData(bundles);
    setOpenItemSelectDialog(false);
  };

  useEffect(() => {
    if (bundleData.length) {
      setBundleData([]);
    }
  }, [formik.values, selectedWarehouse]);

  const getBundleType = async () => {
    try {
      const { data } = await get(URLS.bundleType.list, {
        params: {
          warehouse: searchFormik.values.warehouseId,
        },
      });

      setBundleType(
        data.result.map((b) => ({
          ...b,
          label: `${b.code} (${b.description})`,
          value: b._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getStockType = async () => {
    try {
      const { data } = await get(URLS.stockType.list, {
        params: {
          search: {
            warehouse: searchFormik.values.warehouseId,
          },
        },
      });

      setStockType(
        data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      searchFormik.setFieldValue("stockTypeId", "");

      if (data.result?.length === 1) {
        searchFormik.setFieldValue("stockTypeId", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchFormik.values.warehouseId) {
      getBundleType();
      getStockType();
    }
  }, [searchFormik.values.warehouseId]);

  const handleOnBundleCreate = () => {
    formik.resetForm();
    setManualItems([]);
    setSelectedMsn([]);
    searchFormik.handleSubmit();
  };

  const handleChangeSelectItem = (e) => {
    setSelectedMsn(e.target.value);
    let itemsWithGivenMSN = items.filter((i) => e.target.value.includes(i.msn));
    setManualItems(itemsWithGivenMSN);
  };

  const handleManualItemRemove = (id) => {
    setSelectedMsn(
      selectedMsn.filter((m) => m !== manualItems.find((i) => i._id == id).msn)
    );
    setManualItems(manualItems.filter((i) => i._id !== id));
  };
  useEffect(() => {
    formik.resetForm();
    setBundleData([]);
    setItemData([]);
  }, [searchFormik.values]);

  console.log(ownerCodes, "owerncodes");
  console.log(ownerGroups, "ownwerGrouos");
  console.log(
    searchFormik.values,
    "================================================================"
  );

  return (
    <>
      <DashBoardPageHeaderPage title="Add Bundle" />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={2.5} sm={6}>
          <FormSelect
            required={true}
            name="warehouseId"
            formik={searchFormik}
            label="Select Warehouse"
            options={warehouses}
          />
        </Grid>

        <Grid item xs={12} md={2.5} sm={6}>
          <FormSelect
            required={true}
            name="ownerGroupId"
            formik={searchFormik}
            label="Select Owner Group"
            options={ownerGroups}
          />
        </Grid>

        <Grid item xs={12} md={2.5} sm={6}>
          <FormSelect
            required={true}
            name="ownerCodeId"
            formik={searchFormik}
            label="Select Owner Code"
            options={ownerCodes}
          />
        </Grid>
        <Grid item xs={12} md={2.5} sm={6}>
          <FormSelect
            required={true}
            name="stockTypeId"
            formik={searchFormik}
            label="Select Stock Type"
            options={stockType}
          />
        </Grid>
        <Grid
          sx={{ display: "flex", alignItems: "center" }}
          item
          xs={12}
          md={2}
        >
          <LoadingButton
            size="small"
            variant="contained"
            loading={fetchingData}
            onClick={searchFormik.handleSubmit}
          >
            Find
          </LoadingButton>
        </Grid>
      </Grid>
      {fetchingData ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          {
            <Table>
              <TableBody>
                {itemData.map((value, index) => (
                  <TableRow key={index}>
                    {value.map((v, i) => (
                      <TableCell
                        key={i}
                        sx={{
                          width: i === 0 ? "6%" : "10%",
                          color: i === 0 ? "#fff" : "inherit",
                          backgroundColor:
                            i === 0
                              ? (theme) => theme.palette.primary.main
                              : "inherit",
                        }}
                        align={i === 0 ? "center" : "center"}
                      >
                        {v}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>
      )}

      {!itemData.length ? null : (
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={12} md={6} lg={3}>
            <FormSelect
              required={true}
              name="category"
              formik={formik}
              label="Select Category"
              options={categoryType}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <FormSelect
              required={true}
              name="bundleCode"
              formik={formik}
              label="Select Bundle Type"
              options={bundleType}
            />
          </Grid>
          {formik.values.category !== "manual" && (
            <Grid item xs={12} md={6} lg={3}>
              <FormInput
                required={true}
                name="quantity"
                formik={formik}
                label="Number of bundles"
              />
            </Grid>
          )}

          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item
            xs={12}
            md={6}
            lg={3}
          >
            <Button
              variant="contained"
              size="small"
              onClick={(e) =>
                formik.values.category === "manual"
                  ? !hasAllValues(formik.values, "quantity")
                    ? null
                    : setOpenItemSelectDialog(true)
                  : formik.handleSubmit(e)
              }
            >
              {formik.values.category === "manual" ? "Select Items" : "Find"}
            </Button>
          </Grid>
        </Grid>
      )}

      {bundleData.length ? (
        <BundleNewUsedAndCombination
          key={selectedWarehouse}
          onBundleCreate={handleOnBundleCreate}
          bundleType={formik.values.bundleCode}
          bundles={bundleData}
          warehouseId={searchFormik.values.warehouseId}
          stockType={stockType}
          ownerCodeId={searchFormik.values.ownerCodeId}
          ownerGroupId={searchFormik.values.ownerGroupId}
        />
      ) : null}

      <Dialog
        fullScreen={fullScreen}
        open={openItemSelectDialog}
        onClose={handleCloseItemSelect}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: 650,
          },
        }}
      >
        <DialogTitle>Add Items to create bundles</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            padding: "10px",
          }}
        >
          <Box sx={{ mb: "20px" }}>
            <CustomSelectVirtualized
              name="selectItem"
              label="MSN"
              multiple={true}
              value={selectedMsn}
              required={true}
              options={inStockItems.filter((i) =>
                bundleType
                  .find((b) => b._id === formik.values.bundleCode)
                  ?.bundleData.map((b) => b.subFamily._id)
                  .includes(i.subFamily._id)
              )}
              onChange={handleChangeSelectItem}
            />
          </Box>
          {!manualItems.length
            ? null
            : manualItems.map((i, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                  }}
                  key={i._id}
                >
                  <Typography>
                    {i.msn} -- {i.stockType.name} -- {i.subFamily.name}
                  </Typography>{" "}
                  <IconButton
                    size="small"
                    onClick={(e) => handleManualItemRemove(i._id)}
                  >
                    <Close fontSize="small" />
                  </IconButton>
                </Box>
              ))}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={handleCloseItemSelect}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleShowBundleInfoClick}
          >
            Show bundle info
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
