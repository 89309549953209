export const palletCodeTableKeys = [
  {
    name: "Code",
    key: "code",
  },
  {
    name: "Description",
    key: "description",
  },
  // {
  //   name: "Warehouse Name",
  //   path: "warehouse",
  //   key: "name",
  // },
  // {
  //   name: "Warehouse Code",
  //   path: "warehouse",
  //   key: "code",
  // },
];
